import axios from "axios";
import * as All from '../config';


const API_URL = All.API_URL;

/**
 * 
 * @param File PDF document
 * @returns string — unique name
 */
function postPdfFile(document) {
    const pdfFormData = new FormData();
    pdfFormData.append("pdf", document);

    return axios
        .post(`${API_URL}attachedPdf`, pdfFormData)
        .then(response => {
            console.log("response", response.status);
            return response.data;
        })
        .catch((error) => { console.log("postPdfFile error", error); });
}

/**
 * Sends the complete form of the new attached document (via api-platform)
 * @param Object formData 
 */
function postPdfInfo(formData) {
    axios
        .post(`${API_URL}attached_pdfs`, formData)
        .then(response => { console.log(response.status); })
        .catch((error) => { console.error("postPdfInfo error", error); });
}

/**
 * delete pdf
 */
const deletePdf = async (id, uniqueName) => {
        try {
            await axios
            .post(`${API_URL}delete_pdf`, {'name' : uniqueName})
            .then(response => { 
                (async function () {
                    await deletePdfDb(id);
                })();
            })
        } catch (error) {
            console.error(error)
        }
}

const deletePdfDb = async(id) => {
    try {
        await axios
        .delete(`${API_URL}attached_pdfs/` + id)
    } catch (error) {
        console.error(error)
    }
}

export default {
    postPdfFile,
    postPdfInfo,
    deletePdf
}