import React, { useState,  useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable, { MTableToolbar } from 'material-table';


import ManageableListsSousTab from './manageablelistsSousTab';

import { ThemeContext } from '../contexts/ThemeContext';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginTop: '25vh',
    },
    toolbarDiv: {
        color: props => props.color.labelNavbarBackgroundColor,
    },
    toolbar: {
        backgroundColor: props => props.color.white,
    },
    page: {
        marginLeft: '30px',
        marginRight: '30px',
    },
}));

const ManageableLists = () => {
    const theme = useContext(ThemeContext);
    const classes = useStyles(theme);

    const [displaySecondTable, setDisplaySecondTable] = useState("hidden");

    const [selectedRow, setSelectedRow] = useState([]);
    const [state, setState] = useState({
        columns: [
            { title: 'Listes administrables', field: 'name' },
        ],
        data: [
            { name: "Type d'unité", id: "type_unites" },
            { name: "Type de catégorisation", id: "type_flags" },
            { name: "Thème", id: "categorie_indicateurs" },
            { name: "Sous-thème", id: "sous_categorie_indicateurs" },
            { name: "Responsable", id: "responsables" },
        ]
    });

    const scroll = () => {
        const section = document.querySelector('#anchorSousTab');
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const handleRowClick = async (event, rowData) => {
        event.preventDefault();
        setSelectedRow(rowData);

        if (displaySecondTable === "hidden") {
            setDisplaySecondTable('visible');
            scroll();
        } else if (displaySecondTable === "visible") {
            scroll();
        } else {
            setDisplaySecondTable('hidden');
        }
    }

    return (
        <>
            <div className={classes.page}>
                <MaterialTable
                    title={"Gestion des listes administrables".toUpperCase()}
                    columns={state.columns}
                    data={state.data}
                    onRowClick={(event, rowData) => handleRowClick(event, rowData)}
                    components={{
                        Toolbar: props => (
                            <div className={classes.toolbarDiv}>
                                <MTableToolbar {...props} className={classes.toolbar} />
                            </div>
                        ),
                    }}
                    style={{
                        color: theme.color.dataColor,
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: "{from} à {to} sur {count}",
                            labelRowsSelect: "résultats par page",
                            labelRowsPerPage: "résultats par page",
                            firstAriaLabel: "Première page",
                            firstTooltip: "Première page",
                            previousAriaLabel: "Page précédente",
                            previousTooltip: "Page précédente",
                            nextAriaLabel: "Page suivante",
                            nextTooltip: "Page suivante",
                            lastAriaLabel: "Dernière page",
                            lastTooltip: "Dernière page"
                        },
                        toolbar: {
                            addRemoveColumns: "Ajouter ou supprimer des colonnes",
                            nRowsSelected: "{0} ligne(s) sélectionnée(s)",
                            showColumnsTitle: "Afficher les colonnes",
                            showColumnsAriaLabel: "Afficher les colonnes",
                            exportTitle: "Exporter",
                            exportAriaLabel: "Exporter",
                            exportName: "Exporter en CSV",
                            searchTooltip: "Recherche",
                            searchPlaceholder: "Recherche"
                        },
                        header: {
                            actions: ''
                        },
                        body: {
                            emptyDataSourceMessage:
                                "Désolé, aucun enregistrement correspondant n'a été trouvé",
                            addTooltip: "Ajouter une ligne ",
                            deleteTooltip: "Supprimer",
                            editTooltip: "Modifier",
                            filterRow: {
                                filterTooltip: "Filtrer"
                            },
                            editRow: {
                                deleteText: "Êtes-vous sûr de vouloir supprimer cet élément ? Vous allez perdre TOUTES les données associées",
                                cancelTooltip: "Annuler",
                                saveTooltip: "Sauvegarder"
                            }
                        },
                    }}
                    options={{

                        rowStyle: {
                            backgroundColor: theme.color.white,
                        },
                        headerStyle: {
                            backgroundColor: theme.color.labelNavbarBackgroundColor,
                            color: theme.color.labelNavbarColor,
                            textAlign: 'center',
                        },
                        paging: false, // disable pagination !
                        tableLayout: "fixed", //columns dimensions
                        addRowPosition: 'first', //add row on top
                    }}
                />

                <div id="anchorSousTab">{'\u00A0'}</div>
                {/* second table */}
                <div style={{ visibility: displaySecondTable }} >
                    <ManageableListsSousTab selectedRow={selectedRow} />
                </div>
            </div>
        </>
    )
}

export default ManageableLists;