import React, {useState} from 'react';
import axios from "axios";
import {SENDEMAIL} from "../config.js";


import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
//image
import imageCo from '../images/home.jpg';
//alert
import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: "url(" + imageCo + ")",
        backgroundRepeat: 'no-repeat',
        backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#1bb9d9',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor : "#3f51b5"
    },
}));


export default function OublieMdp  ({history}) {
    const classes = useStyles();

    const [email, setEmail] = useState('');
    
    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    }

    const alertMessage = () => {
        Swal.fire({
            icon: 'info',
            title: 'Confirmation',
            text: 'Vous recevrez d\' ici quelques minutes un mail de réinitialisation',
            confirmButtonText: `Retour à la page de connexion`,
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              allowOutsideClick : false,
              
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                history.replace('/connexion')
            }
          });

    }
  const handleSubmitEmail = (event) => {
    event.preventDefault();
    axios.post(SENDEMAIL, { email: email })
      .then((response) => {
        setEmail('');
        alertMessage();
      })

  }

    return(
        <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" style={{color:'#666666'}}>
              Demande de réinitialisation du mot de passe
            </Typography>
            <form className={classes.form} onSubmit={handleSubmitEmail}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username" 
                label="Adresse email"
                name="username" 
                autoComplete="email"
                autoFocus
                type="email"
                value={email}
                onChange={handleChangeEmail}

              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Valider
              </Button>
              <Grid container>
                <Grid item>
                  <Link href="/#/accueil" variant="body2">
                    {"Retourner à la page de connexion"}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Grid>
      </Grid>
    )
}

