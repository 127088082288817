import React, { useContext, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Button,
    Typography,
    TextField,
    Checkbox,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Select,
    InputLabel,
    MenuItem,
    Tooltip,
    TextareaAutosize,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// contexts
import { ThemeContext } from '../contexts/ThemeContext';
import AuthContext from '../contexts/AuthContext';

// services
import themeService from '../services/ThemeApi';
import AuthAPI from '../services/AuthAPI';

//jwt token
import jwtDecode from "jwt-decode";

import { DESIGNER } from "../config";

import './accueil.css';


const useStyles = makeStyles(theme => ({
    root: {
        height: 'calc(100vh - 112px)',
    },
    image: {
        // backgroundImage: props => props.image.connectionImage,
        backgroundImage: props => props.image.homeImage === "" ? props.image.connectionImage : props.image.homeImage,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    height: {
        height: '100%',
    },
    headerDivContainer: {
        width: '100%',
        height: '100%',
        display: "flex",
    },
    headerDiv: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: "20px",
    },
    header: {
        margin: 0,
        textAlign: "center",
        // following as custom variables
        color: props => props.color.white,
        fontSize: "3em",
    },
    textContainer: {
        display: "flex",
        flexDirection: "column",
    },
    description: {
        fontSize: "2em",
        textAlign: "justify",
    },
    relative: {
        position: "relative",
    },
    wysiwygBtn: {
        position: "absolute",
        top: "-2em",
        right: "0",
        zIndex: theme.zIndex.drawer + 2,
        marginRight: "1em",
    },
    wysiwygIcon: {
        fill: props => props.color.menuNavbarColor,
    },
    wysiwygForm: {
        width: "30%",
        height: 'calc(100vh - 116px)',
        position: "absolute",
        top: "4px",
        right: "2px",
        backgroundColor: "rgba(255, 255, 255, 0.3)",
        padding: "1em 2em",
        display: "none",
        overflowY: "scroll",
        transition: "background-color 700ms ease-in-out",
        "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.8)",
        }
    },
    typo: {
        marginTop: "0.5em",
    },
    flexy: {
        display: "flex",
        alignItems: "center",
    },
    halfEm: {
        marginRight: "0.5em",
    },
    opacityInput: {
        marginRight: "0.5em",
        padding: "10.5px 14px",
        background: "none",
        borderRadius: "4px",
        outline: "1px solid #9d9d9d",
        border: "2px solid transparent",
        width: "100%",
        height: "40px",
        lineHeight: "1.1876em",
        fontSize: "1rem",
        "&:focus": {
            borderRadius: "4px",
            border: "2px solid #3f50b5",
            outline: "0",
        },
    },
    mb1: {
        marginBottom: "1em",
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: props => props.color.connectionButtonBackgroundColor,
        color: props => props.color.connectionButtonColor,
        "&:hover": {
            backgroundColor: props => props.color.connectionButtonBackgroundColorHover
        }
    },
}));


const Accueil = () => {
    const history = useHistory();
    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

    const theme = useContext(ThemeContext);
    const classes = useStyles(theme);

    const [isOpen, setIsOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(theme.wysiwyg.isVisible);
    const wysiwygFormRef = useRef(null);

    const [wysiwygState, setWysiwygState] = useState({
        title: theme.wysiwyg.title,
        fontSize: theme.wysiwyg.fontSize,
        fontFamily: theme.wysiwyg.fontFamily,
        bgColor: theme.wysiwyg.bgColor,
        bgOpacity: theme.wysiwyg.bgOpacity,
        textColor: theme.wysiwyg.textColor,
        positionX: theme.wysiwyg.positionX,
        positionY: theme.wysiwyg.positionY,
        paddingY: theme.wysiwyg.paddingY,
        paddingX: theme.wysiwyg.paddingX,
        marginY: theme.wysiwyg.marginY,
        marginX: theme.wysiwyg.marginX,
        description: theme.wysiwyg.description,
        descriptionColor: theme.wysiwyg.descriptionColor,
        fontSizeDescription: theme.wysiwyg.fontSizeDescription,
    });

    const [rgbaBgColor, setRgbaBgColor] = useState();
    const hexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

    function hexToRgb(hex) {
        var result = hexRegex.exec(hex);
        return {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        };
    }

    useEffect(() => {
        if (wysiwygState.bgColor.match(hexRegex)) {
            let rgb = hexToRgb(wysiwygState.bgColor);
            let rgba = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${wysiwygState.bgOpacity}`;
            setRgbaBgColor(rgba);
        }
    }, [wysiwygState.bgColor, wysiwygState.bgOpacity]);


    const handleWysiwygOpen = () => {
        setIsOpen(!isOpen);
        wysiwygFormRef.current.style.display = "block";
    };

    const handleWysiwygClose = () => {
        setIsOpen(!isOpen);
        wysiwygFormRef.current.style.display = "none";
    };

    /**
     * Feeds the corresponding key of the state named field to the text input name
     * @param {*} currentTarget event target 
     */
    const handleChange = ({ currentTarget }) => {
        const { name, value } = currentTarget;
        if (name === undefined) {
            const dataValue = currentTarget.getAttribute('data-value');
            setWysiwygState({ ...wysiwygState, fontFamily: dataValue });
        } else {
            setWysiwygState({ ...wysiwygState, [name]: value });
        }
    };

    /**
     * @returns the view of the button to open the wysiwyg window
     */
    const displayMenuIcon = () => {
        if ("isDefault" in theme) {
            return (
                <Tooltip title="Ajoutez d'abord un thème dans Administration/personnalisez le site">
                    <Button className={classes.wysiwygBtn} style={{ top: "-2.5em", cursor: "not-allowed" }}>
                        {/* <MenuIcon className={classes.wysiwygIcon} /> */}
                        <ExpandMoreIcon fontSize="large" className={classes.wysiwygIcon} />
                    </Button>
                </Tooltip>
            );
        }
        return (
            <Tooltip title="Changez le titre">
                <Button className={classes.wysiwygBtn} onClick={handleWysiwygOpen} style={{ top: "-2.5em" }}>
                    {/* <MenuIcon className={classes.wysiwygIcon} /> */}
                    <ExpandMoreIcon fontSize="large" className={classes.wysiwygIcon} />
                </Button>
            </Tooltip>
        );
    };

    /**
     * @returns the view of the button to close the wysiwyg window
     */
    const displayCloseIcon = () => {
        return (
            <Button className={classes.wysiwygBtn} onClick={handleWysiwygClose}>
                <CloseIcon className={classes.wysiwygIcon} />
            </Button>
        );
    };

    /**
     * Submits the form
     * @param event
     */
    const handleSubmit = (event) => {
        event.preventDefault();

        try {
            const formObj = {};

            formObj['isVisible'] = isVisible;

            for (const [key, value] of Object.entries(wysiwygState)) {
                formObj[key] = value.toString();
            }

            themeService.putWysiwygForm(formObj);

            AuthAPI.logout();
            setIsAuthenticated(false);
            history.push("/connexion");

        } catch (error) {
            console.log(error);
        }
    };

    /**
     * function to decode the token, allowing access to the user's role (and more)
     * @returns decoded token
     */
    const getDecodedToken = () => {
        const tokenBrut = window.localStorage.getItem("authToken");
        if (tokenBrut) {
            const decodedToken = jwtDecode(tokenBrut);
            return decodedToken;
        }
    }


    return (
        <>
            <div className={classes.relative}>
                {getDecodedToken().roles[0] === DESIGNER && (
                    <>
                        {isOpen ? displayCloseIcon() : displayMenuIcon()}
                        <div className={classes.wysiwygForm} ref={wysiwygFormRef}>
                            <form onSubmit={handleSubmit}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Typography component="h4" className={classes.typo}>
                                                Afficher le titre
                                            </Typography>
                                            <FormLabel>
                                                <Checkbox
                                                    label="affichage du titre"
                                                    color="primary"
                                                    checked={isVisible}
                                                    onClick={() => setIsVisible(!isVisible)}
                                                />
                                            </FormLabel>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} className={classes.mb1}>
                                        <Typography component="h4">
                                            Titre
                                        </Typography>
                                        <TextField
                                            style={{ width: "100%" }}
                                            type="text"
                                            name="title"
                                            variant="outlined"
                                            margin="none"
                                            size="small"
                                            value={wysiwygState.title}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={6} className={classes.mb1}>
                                            <InputLabel id="font-select-label">Police</InputLabel>
                                            <Select
                                                labelId="font-select-label"
                                                id="fontFamily"
                                                value={wysiwygState.fontFamily}
                                                onChange={handleChange}
                                            >
                                                <MenuItem value="Century Gothic">Century Gothic</MenuItem>
                                                <MenuItem value="Arial Black">Arial Black</MenuItem>
                                                <MenuItem value="sans-serif">sans-serif</MenuItem>
                                                <MenuItem value="Bodoni Moda">Bodoni Moda</MenuItem>
                                                <MenuItem value="Montagu Slab">Montagu Slab</MenuItem>
                                                <MenuItem value="Clarendon">Clarendon</MenuItem>
                                                <MenuItem value="Original Surfer">Original Surfer</MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid item xs={6} className={classes.mb1}>
                                            <Typography component="h4">
                                                Taille de la police du titre (em)
                                            </Typography>
                                            <TextField
                                                type="number"
                                                name="fontSize"
                                                variant="outlined"
                                                margin="none"
                                                size="small"
                                                value={wysiwygState.fontSize}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} className={classes.mb1}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Typography component="h4">
                                                    La couleur de fond
                                                </Typography>
                                                <div className={classes.flexy} style={{ marginRight: "1em" }}>
                                                    <TextField
                                                        className={classes.halfEm}
                                                        type="text"
                                                        name="bgColor"
                                                        variant="outlined"
                                                        margin="none"
                                                        size="small"
                                                        value={wysiwygState.bgColor}
                                                        onChange={handleChange}
                                                    />
                                                    <input
                                                        type="color"
                                                        name="bgColor"
                                                        onChange={handleChange}
                                                        value={wysiwygState.bgColor}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography component="h4">
                                                    La couleur de titre
                                                </Typography>
                                                <div className={classes.flexy}>
                                                    <TextField
                                                        className={classes.halfEm}
                                                        type="text"
                                                        name="textColor"
                                                        variant="outlined"
                                                        margin="none"
                                                        size="small"
                                                        value={wysiwygState.textColor}
                                                        onChange={handleChange}
                                                    />
                                                    <input type="color" name="textColor" onChange={handleChange} value={wysiwygState.textColor} />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} className={classes.mb1}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Typography component="h4">
                                                    L'opacité de la couleur de fond
                                                </Typography>
                                                <input
                                                    className={classes.opacityInput}
                                                    type="number"
                                                    name="bgOpacity"
                                                    min="0"
                                                    max="1"
                                                    step="0.1"
                                                    value={wysiwygState.bgOpacity}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component="h4">
                                            Axe X
                                        </Typography>
                                        <FormLabel>
                                            <RadioGroup name="positionX" value={wysiwygState.positionX} onChange={handleChange} style={{ flexDirection: "row" }}>
                                                <FormControlLabel value="flex-start" control={<Radio />} label="Gauche" />
                                                <FormControlLabel value="center" control={<Radio />} label="Centre" />
                                                <FormControlLabel value="flex-end" control={<Radio />} label="Droite" />
                                            </RadioGroup>
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component="h4">
                                            Axe Y
                                        </Typography>
                                        <FormLabel>
                                            <RadioGroup name="positionY" value={wysiwygState.positionY} onChange={handleChange} style={{ flexDirection: "row" }}>
                                                <FormControlLabel value="flex-start" control={<Radio />} label="Haut" />
                                                <FormControlLabel value="center" control={<Radio />} label="Centre" />
                                                <FormControlLabel value="flex-end" control={<Radio />} label="Bas" />
                                            </RadioGroup>
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component="h4">
                                            Marge intérieure haut/bas
                                        </Typography>
                                        <FormLabel>
                                            <RadioGroup name="paddingY" value={wysiwygState.paddingY} onChange={handleChange} style={{ flexDirection: "row" }}>
                                                <FormControlLabel value="1" control={<Radio />} label="Petit" />
                                                <FormControlLabel value="2" control={<Radio />} label="Moyen" />
                                                <FormControlLabel value="3" control={<Radio />} label="Large" />
                                            </RadioGroup>
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component="h4">
                                            Marge intérieure droite/gauche
                                        </Typography>
                                        <FormLabel>
                                            <RadioGroup name="paddingX" value={wysiwygState.paddingX} onChange={handleChange} style={{ flexDirection: "row" }}>
                                                <FormControlLabel value="10" control={<Radio />} label="Petit" />
                                                <FormControlLabel value="14" control={<Radio />} label="Moyen" />
                                                <FormControlLabel value="18" control={<Radio />} label="Large" />
                                            </RadioGroup>
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component="h4">
                                            Marge extérieure haut/bas
                                        </Typography>
                                        <FormLabel>
                                            <RadioGroup name="marginY" value={wysiwygState.marginY} onChange={handleChange} style={{ flexDirection: "row" }}>
                                                <FormControlLabel value="2" control={<Radio />} label="Petit" />
                                                <FormControlLabel value="4" control={<Radio />} label="Moyen" />
                                                <FormControlLabel value="6" control={<Radio />} label="Large" />
                                            </RadioGroup>
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component="h4">
                                            Marge extérieure droite/gauche
                                        </Typography>
                                        <FormLabel>
                                            <RadioGroup name="marginX" value={wysiwygState.marginX} onChange={handleChange} style={{ flexDirection: "row" }}>
                                                <FormControlLabel value="4" control={<Radio />} label="Petit" />
                                                <FormControlLabel value="10" control={<Radio />} label="Moyen" />
                                                <FormControlLabel value="16" control={<Radio />} label="Large" />
                                            </RadioGroup>
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component="h4">
                                            Paragraphe
                                        </Typography>
                                        <TextareaAutosize
                                            onChange={handleChange}
                                            name="description"
                                            placeholder="description"
                                            minrows={6}
                                            style={{ width: "100%" }}
                                            defaultValue={wysiwygState.description}
                                        >
                                        </TextareaAutosize>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography component="h4">
                                            La couleur de la description
                                        </Typography>
                                        <div className={classes.flexy}>
                                            <TextField
                                                className={classes.halfEm}
                                                type="text"
                                                name="descriptionColor"
                                                variant="outlined"
                                                margin="none"
                                                size="small"
                                                value={wysiwygState.descriptionColor}
                                                onChange={handleChange}
                                            />
                                            <input type="color" name="descriptionColor" onChange={handleChange} value={wysiwygState.descriptionColor} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component="h4">
                                            Taille de la police de la description (px)
                                        </Typography>
                                        <TextField
                                            type="number"
                                            name="fontSizeDescription"
                                            variant="outlined"
                                            margin="none"
                                            size="small"
                                            value={wysiwygState.fontSizeDescription}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    {/* IMAGE */}
                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            className={classes.submit}
                                        >
                                            Envoyer
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    </>
                )}
            </div>
            <Grid container component="main" className={classes.root}>
                <Grid item xs={false} sm={12} md={12} className={classes.image}>
                    {isVisible && (
                        <Grid container className={classes.height}>
                            <Grid
                                item
                                xs={12}
                                className={classes.height}
                                style={{ padding: `${wysiwygState.marginY}em ${wysiwygState.marginX}em` }}
                            >
                                <div
                                    className={classes.headerDivContainer}
                                    style={{ justifyContent: wysiwygState.positionX, alignItems: wysiwygState.positionY }}
                                >
                                    <div
                                        className={classes.headerDiv}
                                        style={{ padding: `${wysiwygState.paddingY}em ${wysiwygState.paddingX}em`, backgroundColor: rgbaBgColor || wysiwygState.bgColor }}
                                    >
                                        <div className={classes.textContainer}>
                                            <h1
                                                className={classes.header}
                                                style={{ fontSize: `${wysiwygState.fontSize}em`, fontFamily: wysiwygState.fontFamily, color: wysiwygState.textColor }}
                                            >
                                                {wysiwygState.title}
                                            </h1>
                                            {
                                                wysiwygState.description !== undefined ? (
                                                    <p className={classes.description} style={{ fontSize: `${wysiwygState.fontSizeDescription}px`, fontFamily: wysiwygState.fontFamily, color: wysiwygState.descriptionColor }}>{wysiwygState.description}</p>
                                                ) : (
                                                    ""
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </>
    );
}

export default Accueil;