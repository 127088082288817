import { createContext } from 'react';

// images
import imageCo from '../images/home.jpg';
import logo from '../images/Logo Espelia.svg';
import imgHome from '../images/espelia2.png';
import pbi from '../images/pbi2.png';


export const themes = {
    default: {
        // BOOLEAN HANDLES THE DISPLAY OF THE THEME (App.JS) AND WETHER TO POST OR PUT (customSiteForm.js)
        isDefault: true,
        isFlagActivated: true,
        color: {
            white: "#FFFFFF",
            black: "#000000",
            menuNavbarBackgroundColor: "#006388",
            menuNavbarColor: "#FFFFFF",
            labelNavbarBackgroundColor: "#569CB2",
            labelNavbarColor: "#FFFFFF",
            pageHeaderColor: "#FFB300", // page header and "Ajouter une ligne" button
            connectionLogo: "#F50057",
            connectionButtonBackgroundColor: "#3F51B5",
            connectionButtonColor: "#FFFFFF",
            connectionButtonBackgroundColorHover: "#002884",
            dataColor: "#666666",
        },
        // Thèmes
        label: {
            indicator: {
                // Dropdown labels
                theme: "Thème",
                subTheme: "Sous-thème",
                supervisor: "Référent",
                // Table column definition
                mainTable: {
                    subTopic: "Sous-thème d'indicateur",
                    indicatorLabel: "Libellé de l'indicateur",
                    subLabel: "sous libellé",
                    updateRate: "Fréquence de mise à jour",
                    unit: "Unité",
                    freeComment: "Commentaire libre",
                    target: "Objectif",
                    //optional
                    indicatorMainTableOptionalText1: "premier libellé de texte (optionnel)",
                    indicatorMainTableOptionalText2: "second libellé de texte (optionnel)",
                    indicatorMainTableOptionalText3: "troisième libellé de texte (optionnel)",
                    indicatorMainTableOptionalFloat1: "premier libellé de nombre (optionnel)",
                    indicatorMainTableOptionalFloat2: "second libellé de nombre (optionnel)",
                    indicatorMainTableOptionalFloat3: "troisième libellé de nombre (optionnel)",
                },
                subTable: {
                    update: "Date de mise à jour",
                    unit: "Unité",
                    value: "Valeur",
                    category: "Catégorie",
                    dataVizComment: "Commentaire à afficher sur la DataViz",
                    hiddenComment: "Commentaire caché (non reporté en dataviz)",
                    //optional
                    indicatorSubTableOptionalText1: "premier libellé de texte (optionnel)",
                    indicatorSubTableOptionalText2: "second libellé de texte (optionnel)",
                    indicatorSubTableOptionalText3: "troisième libellé de texte (optionnel)",
                    indicatorSubTableOptionalFloat1: "premier libellé de nombre (optionnel)",
                    indicatorSubTableOptionalFloat2: "second libellé de nombre (optionnel)",
                    indicatorSubTableOptionalFloat3: "troisième libellé de nombre (optionnel)",
                },
            },
            target: {
                // Dropdown labels
                theme: "Thème",
                subTheme: "Sous-thème",
                // Table column definition
                mainTable: {
                    indicator: "Rappel de l'indicateur concerné",
                    label: "Libellé de l'objectif",
                    updateRate: "Fréquence de mise à jour",
                    unit: "Unité",
                    freeComment: "Commentaire libre",
                },
                subTable: {
                    update: "Date de mise à jour",
                    unit: "Unité",
                    value: "Valeur",
                    dataVizComment: "Commentaire à afficher sur la DataViz",
                    hiddenComment: "Commentaire caché (non reporté en dataviz)",
                },
            }
        },
        image: {
            connectionImage: `url(${imageCo})`,
            homeImage: `url(${imgHome})`,
            mainLogo: logo,
            secondLogo: "",
            urlMainLogo: "/#/accueil",
            urlSndLogo: "/#/administration",
        },
        powerBI: {
            image: pbi,
            url: "//powerbi.microsoft.com/fr-fr/",
        },
        wysiwyg: {
            title: "TEXT FIELD",
            fontSize: "3",
            fontFamily: "Century Gothic",
            bgColor: "#FFB300",
            bgOpacity: 1,
            textColor: "#006388",
            positionX: "center",
            positionY: "flex-start",
            paddingY: "2",
            paddingX: "14",
            marginY: "4",
            marginX: "6",
            isVisible: true,
            description: "Here comes the description ...",
            descriptionColor: "#000000"
        }
    }
}

export const ThemeContext = createContext(themes.default);