import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

// material-ui
import {
    TextField,
    Button,
    Grid,
    Paper,
    Typography,
    darken,
    Checkbox,
    FormLabel,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    IconButton,
    Badge,
    Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { RiUploadCloud2Line } from 'react-icons/ri';
import AddCircleRounded from '@material-ui/icons/AddCircleRounded';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Cancel from '@material-ui/icons/Cancel';

// contexts
import { ThemeContext } from '../../contexts/ThemeContext';
import AuthContext from '../../contexts/AuthContext';

// services
import themeService from '../../services/ThemeApi';
import AuthAPI from '../../services/AuthAPI';


// preparing the style with material-ui makeStyles() method
const formstyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    itemSpacing: {
        margin: theme.spacing(2, 3),
    },
    paper: {
        padding: theme.spacing(2),
    },
    mt: {
        "&:not(:first-child)": {
            marginTop: "2em",
        }
    },
    header3: {
        color: props => props.color.pageHeaderColor,
        textIndent: "1em",
    },
    header4: {
        color: "#666666",
    },
    flexy: {
        display: "flex",
        alignItems: "center",
    },
    m2: {
        marginRight: "1em",
    },
    labelInput: {
        marginRight: "1em",
        width: "100%",
    },
    pb2: {
        paddingBottom: "1em",
    },
    uploadBtn: {
        backgroundColor: props => props.color.labelNavbarBackgroundColor,
        color: props => props.color.labelNavbarColor,
        marginRight: "1em",
        "&:hover": {
            backgroundColor: props => darken(props.color.labelNavbarBackgroundColor, 0.1),
        }
    },
    errorImage: {
        marginTop: "0.5em",
        color: theme.palette.error.dark,
    },
    scaleUp: {
        marginRight: "1em",
        transform: "scale(2)",
        fill: props => props.color.labelNavbarColor,
    },
    img: {
        width: "100px",
        height: "100px",
        objectFit: "contain",
        cursor: 'default',
    },
    noCursor: {
        maxHeight: "100px",
        cursor: "default",
        '&:hover': {
            backgroundColor: "transparent",
        },
    },
    badge: {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
        cursor: "pointer",
    },
    addRow: {
        display: "flex",
    },
    addDiv: {
        margin: "16px 1em 8px 0",
    },
    addbtn: {
        color: props => props.color.pageHeaderColor,
        fontSize: "0.9em",
        height: "100%",
    },
    add: {
        fill: props => props.color.pageHeaderColor,
    },
    disabledAdd: {
        fill: "#bebebe",
    },
    cancelDiv: {
        margin: "16px 1em 8px 0",
        display: "flex",
        flexDirection: "column",
    },
    alignSelfGrid: {
        alignSelf: "center",
    },
    cancelbtn: {
        color: "#eb4034",
    },
    cancel: {
        fill: "#eb4034",
    },
    inputPurge: {
        width: "100%",
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: props => props.color.connectionButtonBackgroundColor,
        color: props => props.color.connectionButtonColor,
        "&:hover": {
            backgroundColor: props => props.color.connectionButtonBackgroundColorHover
        }
    },
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}
));


/**
 * Variables declaration (route, context, state).
 * 
 * Methods declaration (handling field changes and submit).
 *  
 * @returns the view of the form
 */
const CustomSiteForm = () => {
    const history = useHistory();
    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

    const theme = useContext(ThemeContext);

    const classes = formstyles(theme);

    const bgImgRef = useRef();
    const homeImgRef = useRef();
    const mainLogoRef = useRef();
    const sndLogoRef = useRef();

    const COLORS = [
        "menuNavbarBackgroundColor",
        "menuNavbarColor",
        "labelNavbarBackgroundColor",
        "labelNavbarColor",
        "black",
        "white",
        "connectionLogo",
        "connectionButtonBackgroundColor",
        "connectionButtonColor",
        "connectionButtonBackgroundColorHover",
        "dataColor",
        "pageHeaderColor"
    ];

    const [errorColor, setErrorColor] = useState({
        menuNavbarBackgroundColor: false,
        menuNavbarColor: false,
        labelNavbarBackgroundColor: false,
        labelNavbarColor: false,
        black: false,
        white: false,
        connectionLogo: false,
        connectionButtonBackgroundColor: false,
        connectionButtonColor: false,
        connectionButtonBackgroundColorHover: false,
        dataColor: false,
        pageHeaderColor: false,
    })

    const [field, setField] = useState({
        // colors
        menuNavbarBackgroundColor: theme.color.menuNavbarBackgroundColor,
        menuNavbarColor: theme.color.menuNavbarColor,
        labelNavbarBackgroundColor: theme.color.labelNavbarBackgroundColor,
        labelNavbarColor: theme.color.labelNavbarColor,
        black: theme.color.black,
        white: theme.color.white,
        connectionLogo: theme.color.connectionLogo,
        connectionButtonBackgroundColor: theme.color.connectionButtonBackgroundColor,
        connectionButtonColor: theme.color.connectionButtonColor,
        connectionButtonBackgroundColorHover: theme.color.connectionButtonBackgroundColorHover,
        dataColor: theme.color.dataColor,
        pageHeaderColor: theme.color.pageHeaderColor,
        // labels
        indicatorTheme: theme.label.indicator.theme,
        indicatorSubTheme: theme.label.indicator.subTheme,
        indicatorSupervisor: theme.label.indicator.supervisor,

        indicatorMainTableSubTopic: theme.label.indicator.mainTable.subTopic,
        indicatorMainTableSubLabel: theme.label.indicator.mainTable.subLabel,
        indicatorMainTableIndicatorLabel: theme.label.indicator.mainTable.indicatorLabel,
        indicatorMainTableUnit: theme.label.indicator.mainTable.unit,
        indicatorMainTableUpdateRate: theme.label.indicator.mainTable.updateRate,
        indicatorMainTableFreeComment: theme.label.indicator.mainTable.freeComment,
        indicatorMainTableTarget: theme.label.indicator.mainTable.target,
        //optional
        indicatorMainTableOptionalText1: theme.label.indicator.mainTable.indicatorMainTableOptionalText1,
        indicatorMainTableOptionalText2: theme.label.indicator.mainTable.indicatorMainTableOptionalText2,
        indicatorMainTableOptionalText3: theme.label.indicator.mainTable.indicatorMainTableOptionalText3,
        indicatorMainTableOptionalFloat1: theme.label.indicator.mainTable.indicatorMainTableOptionalFloat1,
        indicatorMainTableOptionalFloat2: theme.label.indicator.mainTable.indicatorMainTableOptionalFloat2,
        indicatorMainTableOptionalFloat3: theme.label.indicator.mainTable.indicatorMainTableOptionalFloat3,

        indicatorSubTableUpdate: theme.label.indicator.subTable.update,
        indicatorSubTableUnit: theme.label.indicator.subTable.unit,
        indicatorSubTableValue: theme.label.indicator.subTable.value,
        indicatorSubTableCategory: theme.label.indicator.subTable.category,
        indicatorSubTabledataVizComment: theme.label.indicator.subTable.dataVizComment,
        indicatorSubTableHiddenComment: theme.label.indicator.subTable.hiddenComment,
        //optional
        indicatorSubTableOptionalText1: theme.label.indicator.subTable.indicatorSubTableOptionalText1,
        indicatorSubTableOptionalText2: theme.label.indicator.subTable.indicatorSubTableOptionalText2,
        indicatorSubTableOptionalText3: theme.label.indicator.subTable.indicatorSubTableOptionalText3,
        indicatorSubTableOptionalFloat1: theme.label.indicator.subTable.indicatorSubTableOptionalFloat1,
        indicatorSubTableOptionalFloat2: theme.label.indicator.subTable.indicatorSubTableOptionalFloat2,
        indicatorSubTableOptionalFloat3: theme.label.indicator.subTable.indicatorSubTableOptionalFloat3,
        // targets
        targetTheme: theme.label.target.theme,
        targetSubTheme: theme.label.target.subTheme,

        targetMainTableIndicator: theme.label.target.mainTable.indicator,
        targetMainTableLabel: theme.label.target.mainTable.label,
        targetMainTableUpdateRate: theme.label.target.mainTable.updateRate,
        targetMainTableUnit: theme.label.target.mainTable.unit,
        targetMainTableFreeComment: theme.label.target.mainTable.freeComment,

        targetSubTableUpdate: theme.label.target.subTable.update,
        targetSubTableUnit: theme.label.target.subTable.unit,
        targetSubTableValue: theme.label.target.subTable.value,
        targetSubTableDataVizComment: theme.label.target.subTable.dataVizComment,
        targetSubTableHiddenComment: theme.label.target.subTable.hiddenComment,
        // url
        powerBI: theme.powerBI.url,
        urlMainLogo: theme.image.urlMainLogo,
        urlSndLogo: theme.image.urlSndLogo,
    });

    const [imgState, setImgState] = useState({
        bgImgRef: "",
        homeImgRef: "",
        mainLogoRef: "",
        sndLogoRef: "",
    });

    const [isFlagActivated, setIsFlagActivated] = useState(theme.isFlagActivated);

    const [error, setError] = useState(false);

    const [counter, setCounter] = useState({
        tableMainTextCounter: {
            indexes: [0, 0, 0],
            size: 0,
        },
        tableMainFloatCounter: {
            indexes: [0, 0, 0],
            size: 0,
        },
        tableSubTextCounter: {
            indexes: [0, 0, 0],
            size: 0,
        },
        tableSubFloatCounter: {
            indexes: [0, 0, 0],
            size: 0,
        },
    });

    function getNewId(indexes) {
        return indexes.findIndex(e => e === 0) + 1;
    }

    function getSize(indexes) {
        return indexes.filter(e => e > 0).length;
    }

    const ADD = "ADD", TYPE = "TYPE", LABEL = "LABEL";
    const MAIN = "Main", SUB = "Sub";
    let mainColumnsSize = 6;
    let subColumnsSize = 6;

    const initialExtraMainColumns = Array.apply(null, Array(mainColumnsSize)).map((e, i) => {
        if (i === 0) {
            return {
                triState: ADD,
                isHidden: false,
                radioValue: "",
                id: 0,
                isPurgeable: false,
            }
        }
        return {
            triState: ADD,
            isHidden: true,
            radioValue: "",
            id: 0,
            isPurgeable: false,
        }
    });

    const [extraMainColumns, setExtraMainColumns] = useState(initialExtraMainColumns);

    const initialExtraSubColumns = Array.apply(null, Array(subColumnsSize)).map((e, i) => {
        if (i === 0) {
            return {
                triState: ADD,
                isHidden: false,
                radioValue: "",
                id: 0,
                isPurgeable: false,
            }
        }
        return {
            triState: ADD,
            isHidden: true,
            radioValue: "",
            id: 0,
            isPurgeable: false,
        }
    });

    const [extraSubColumns, setExtraSubColumns] = useState(initialExtraSubColumns);

    const [toggleRerender, setToggleRerender] = useState(false);


    /**
     * Filters through existing column's label to enable displaying them
     * Reminder : 6 columns of type text (3) or float (3) for each indicator's tables (main and sub) (12 total)
     */
    useEffect(() => {
        // reinitializes for deletions of extra columns
        setExtraMainColumns(initialExtraMainColumns);
        setExtraSubColumns(initialExtraSubColumns);

        const regexGlobal = /^indicator(Main|Sub)TableOptional(Text|Float)[1-3]$/gm;
        const regexMain = /^indicatorMainTableOptional(Text|Float)[1-3]$/gm;
        const regexSub = /^indicatorSubTableOptional(Text|Float)[1-3]$/gm;
        const regexText = /^indicator(Main|Sub)TableOptionalText[1-3]$/gm;
        const regexFloat = /^indicator(Main|Sub)TableOptionalFloat[1-3]$/gm;

        let idxMainText = 0;
        let idxMainFloat = 0;
        let idxSubText = 0;
        let idxSubFloat = 0;

        let mainTextIndexes = [0, 0, 0];
        let mainFloatIndexes = [0, 0, 0];
        let subTextIndexes = [0, 0, 0];
        let subFloatIndexes = [0, 0, 0];


        for (const [key, value] of Object.entries(field)) {
            // only the 12 columns AND only if they have an existing label
            if (key.match(regexGlobal) && value !== null) {
                // indicatorMainTableOptional**
                if (key.match(regexMain)) {
                    const idxMain = idxMainText + idxMainFloat;
                    const TABLE = "type_indicateurs";
                    // indicatorMainTableOptionalText*
                    if (key.match(regexText)) {
                        themeService
                            .getTotalItem(TABLE, key)
                            .then(response => {
                                setExtraMainColumns(prevState => prevState.map((e, i) => {
                                    if (i === idxMain + 1) e.isHidden = false;
                                    if (i === idxMain) {
                                        if (response === 0) e.isPurgeable = true;
                                        e.radioValue = "Text";
                                        e.id = parseInt(key.slice(-1));
                                        e.triState = LABEL;
                                        e.isHidden = false;
                                    }
                                    return e;
                                }));
                            });

                        mainTextIndexes = mainTextIndexes.map((e, i) => {
                            if (i === parseInt(key.slice(-1)) - 1 && e === 0) {
                                return i + 1;
                            }
                            return e;
                        });

                        setCounter(prevState => ({
                            ...prevState,
                            tableMainTextCounter: {
                                indexes: mainTextIndexes,
                                size: getSize(mainTextIndexes),
                            }
                        }));
                        idxMainText++;
                    }
                    // indicatorMainTableOptionalFloat*
                    if (key.match(regexFloat)) {
                        themeService
                            .getTotalItem(TABLE, key)
                            .then(response => {
                                setExtraMainColumns(prevState => prevState.map((e, i) => {
                                    if (i === idxMain + 1) e.isHidden = false;
                                    if (i === idxMain) {
                                        if (response === 0) e.isPurgeable = true;
                                        e.radioValue = "Float";
                                        e.id = parseInt(key.slice(-1));
                                        e.triState = LABEL;
                                        e.isHidden = false;
                                    }
                                    return e;
                                }));
                            });

                        mainFloatIndexes = mainFloatIndexes.map((e, i) => {
                            if (i === parseInt(key.slice(-1)) - 1 && e === 0) return i + 1;
                            return e;
                        })
                        setCounter(prevState => ({
                            ...prevState,
                            tableMainFloatCounter: {
                                indexes: mainFloatIndexes,
                                size: getSize(mainFloatIndexes),
                            }
                        }));
                        idxMainFloat++;
                    }
                }

                // indicatorSubTableOptional**
                if (key.match(regexSub)) {
                    const idxSub = idxSubText + idxSubFloat;
                    const TABLE = "indicateurs";
                    // indicatorSubTableOptionalText*
                    if (key.match(regexText)) {
                        themeService
                            .getTotalItem(TABLE, key)
                            .then(response => {
                                setExtraSubColumns(prevState => prevState.map((e, i) => {
                                    if (i === idxSub + 1) e.isHidden = false;
                                    if (i === idxSub) {
                                        if (response === 0) e.isPurgeable = true;
                                        e.radioValue = "Text";
                                        e.id = parseInt(key.slice(-1));
                                        e.triState = LABEL;
                                        e.isHidden = false;
                                    }
                                    return e;
                                }));
                            });

                        subTextIndexes = subTextIndexes.map((e, i) => {
                            if (i === parseInt(key.slice(-1)) - 1 && e === 0) return i + 1;
                            return e;
                        })
                        setCounter(prevState => ({
                            ...prevState,
                            tableSubTextCounter: {
                                indexes: subTextIndexes,
                                size: getSize(subTextIndexes),
                            }
                        }));
                        idxSubText++
                    }
                    // indicatorSubTableOptionalFloat*
                    if (key.match(regexFloat)) {
                        themeService
                            .getTotalItem(TABLE, key)
                            .then(response => {
                                setExtraSubColumns(prevState => prevState.map((e, i) => {
                                    if (i === idxSub + 1) e.isHidden = false;
                                    if (i === idxSub) {
                                        if (response === 0) e.isPurgeable = true;
                                        e.radioValue = "Float";
                                        e.id = parseInt(key.slice(-1));
                                        e.triState = LABEL;
                                        e.isHidden = false;
                                    }
                                    return e;
                                }));
                            });

                        subFloatIndexes = subFloatIndexes.map((e, i) => {
                            if (i === parseInt(key.slice(-1)) - 1 && e === 0) return i + 1;
                            return e;
                        })
                        setCounter(prevState => ({
                            ...prevState,
                            tableSubFloatCounter: {
                                indexes: subFloatIndexes,
                                size: getSize(subFloatIndexes),
                            }
                        }));
                        idxSubFloat++;
                    }
                }
            }
        }
    }, [toggleRerender]);



    /**
     * Displays the mechanic of adding a column (buttons, choices, input ...)
     * @param array columnObjects : the array of column objects
     * @param string table : Main or Sub
     * @returns The view : Adding a new column
     */
    const displayExtraColumn = (columnObjects, table) => {
        return columnObjects.map((e, idx) => {
            if (!e.isHidden) {
                return e.triState === ADD ? (
                    <Grid item xs={3} className={classes.addRow} key={idx}>
                        <Grid container>
                            <div className={classes.addDiv}>
                                <Button
                                    className={classes.addbtn}
                                    variant="outlined"
                                    onClick={() => handleAddColumn(idx, table)}
                                >
                                    <AddCircleRounded className={classes.add} />
                                    Ajoutez une nouvelle colonne
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                ) : e.triState === TYPE ? (
                    <Grid item xs={6} className={classes.addRow} key={idx}>
                        <Grid container>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">
                                    Type
                                </FormLabel>
                                <RadioGroup aria-label="type" name="type" value={e.radioValue} onChange={(event) => handleChooseType(idx, event, table)}>
                                    {counter[`table${table}TextCounter`].size < 3 ? (
                                        <FormControlLabel value="Text" control={<Radio />} label="Text" />
                                    ) : (
                                        <FormControlLabel disabled value="Text" control={<Radio />} label="Text" />
                                    )}
                                    {counter[`table${table}FloatCounter`].size < 3 ? (
                                        <FormControlLabel value="Float" control={<Radio />} label="Float" />
                                    ) : (
                                        <FormControlLabel disabled value="Float" control={<Radio />} label="Float" />
                                    )}
                                </RadioGroup>
                            </FormControl>
                            <div className={classes.cancelDiv}>
                                {e.radioValue === "" ? (
                                    <Button
                                        disabled
                                        className={classes.addbtn}
                                        variant="outlined"
                                        onClick={() => handleSubmitType(idx, table)}
                                    >
                                        <AddCircleRounded className={classes.disabledAdd} />
                                        Validez le choix du type
                                    </Button>
                                ) : (
                                    <Button
                                        className={classes.addbtn}
                                        variant="outlined"
                                        onClick={() => handleSubmitType(idx, table)}
                                    >
                                        <AddCircleRounded className={classes.add} />
                                        Validez le choix du type
                                    </Button>
                                )}
                                <Button
                                    className={classes.cancelbtn}
                                    variant="outlined"
                                    onClick={() => handleCancel(idx, table)}
                                >
                                    <Cancel className={classes.cancel} />
                                    Annuler
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item xs={3} className={classes.addRow} key={idx}>

                        <Grid container>
                            {e.isPurgeable ? (
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={9}>
                                            <TextField
                                                className={classes.inputPurge}
                                                required
                                                variant="outlined"
                                                margin="normal"
                                                label={`Libellé de la colonne ${e.radioValue} ${e.id}`}
                                                name={`indicator${table}TableOptional${e.radioValue}${e.id}`}
                                                type="text"
                                                value={field[`indicator${table}TableOptional${e.radioValue}${e.id}`]}
                                                onChange={handleChange}
                                                error={error}
                                            />
                                        </Grid>
                                        <Grid item xs={3} className={classes.alignSelfGrid}>
                                            <Button
                                                className={classes.cancelbtn}
                                                variant="outlined"
                                                onClick={() => handleDeleteColumn(idx, e.id, table, e.radioValue)}
                                            >
                                                <DeleteForeverIcon className={classes.cancel} />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ) : (
                                <TextField
                                    className={classes.labelInput}
                                    required
                                    variant="outlined"
                                    margin="normal"
                                    label={`Libellé de la colonne ${e.radioValue} ${e.id}`}
                                    name={`indicator${table}TableOptional${e.radioValue}${e.id}`}
                                    type="text"
                                    value={field[`indicator${table}TableOptional${e.radioValue}${e.id}`]}
                                    onChange={handleChange}
                                    error={error}
                                />
                            )}
                        </Grid>
                    </Grid>
                )

            }
        })
    }

    /**
     * Sets field.indicator*TableOptional** to null
     * 
     * and sets extra*Columns.isHidden to true, .triState to ADD, .radioValue to "", .id to 0 and isPurgeable to false
     * 
     * Deletes a column (reinitializes the extra*Columns object, sets field.indicator*TableOptional** to null, decrementes counter and deletes the label in db)
     * @param integer idx : current column
     * @param string table Main or Sub
     */
    const handleDeleteColumn = (columnId, currentIdx, table, radioValue) => {
        setField({ ...field, [`indicator${table}TableOptional${radioValue}${currentIdx}`]: null });

        const newIndexes = counter[`table${table}${radioValue}Counter`].indexes.map((e, i) => {
            if (e === eval(`extra${table}Columns`)[columnId].id) return 0;
            return e;
        });
        setCounter(prevState => ({
            ...prevState,
            [`table${table}${radioValue}Counter`]: {
                indexes: newIndexes,
                size: getSize(newIndexes),
            }
        }));

        // change counter to 0 at index

        eval(`setExtra${table}Columns`)(prevState => prevState.map((e, i) => {
            if (i === columnId) {
                e.triState = ADD;
                e.isHidden = true;
                e.radioValue = "";
                e.id = 0;
                e.isPurgeable = false;
            }
            return e;
        }));

        /*
        patch then rerender (need a boolean state)
        */
        // SubText3
        themeService.patchTheme({
            [`indicator${table}TableOptional${radioValue}${currentIdx}`]: null,
        })
            .then(() => {
                setToggleRerender(!toggleRerender);
            });
    };

    /**
     * onClick displays the types available for an extra column
     * 
     * There can only be 3 columns of type text and 3 of type float thus a counter is keeping track
     * @param integer idx : current column
     * @param string table Main or Sub
     */
    const handleAddColumn = (idx, table) => {
        eval(`setExtra${table}Columns`)(prevState => prevState.map((e, i) => {
            if (i === idx) e.triState = TYPE;
            return e;
        }));
    };

    /**
     * Saves the radio input value in the current column object
     * @param integer idx : current column
     * @param event event
     * @param string table Main or Sub
     */
    const handleChooseType = (idx, event, table) => {
        eval(`setExtra${table}Columns`)(prevState => prevState.map((e, i) => {
            if (i === idx) {
                e.radioValue = event.target.value;
            }
            return e;
        }));
    };

    /**
     * Hides the radio button and shows the "Add an extra column" button
     * @param integer idx : current column
     * @param string table Main or Sub
     */
    const handleCancel = (idx, table) => {
        eval(`setExtra${table}Columns`)(prevState => prevState.map((e, i) => {
            if (i === idx) {
                e.triState = ADD;
                e.radioValue = "";
            }
            return e;
        }));
    };

    /**
     * Hides type's choice and displays input text (Enter your column's label)
     * 
     * Updates the column's counter
     * @param integer idx : current column
     * @param string table Main or Sub
     */
    const handleSubmitType = (idx, table) => {
        eval(`setExtra${table}Columns`)(prevState => prevState.map((e, i) => {
            if (i === idx + 1) e.isHidden = false;
            if (i === idx) {

                // THIS LINE MESSES EVRYTHIING UP IN CASE OF DELETING A PREVIOUS COLUMN THEN ADDING IT AGAIN
                const newId = getNewId(counter[`table${table}${e.radioValue}Counter`].indexes);
                e.id = newId;
                e.triState = LABEL;

                // React component does not accept null for its input's value so it is set to "" if it does not exist (temporary : existing ones should already be visible)
                if (field[`indicator${table}TableOptional${e.radioValue}${counter[`table${table}${e.radioValue}Counter`].size + 1}`] === null) {
                    setField({ ...field, [`indicator${table}TableOptional${e.radioValue}${counter[`table${table}${e.radioValue}Counter`].size + 1}`]: "" });
                }

                const newIndexes = counter[`table${table}${e.radioValue}Counter`].indexes.map((e, i) => {
                    if (i === newId - 1 && e === 0) return i + 1;
                    return e;
                })
                setCounter(prevState => ({
                    ...prevState,
                    [`table${table}${e.radioValue}Counter`]: {
                        indexes: newIndexes,
                        size: getSize(newIndexes),
                    }
                }));
            }
            return e;
        }));
    };

    /**
     * Feeds the corresponding key of the state named field to the text input name
     * @param {*} currentTarget event target 
     */
    const handleChange = ({ currentTarget }) => {
        const { value, name } = currentTarget;
        const hexaRegex = /^#([a-fA-F0-9]{6})$/;
        if (COLORS.includes(name)) {
            if (value.match(hexaRegex) === null) {
                setErrorColor({ ...errorColor, [name]: true });
            } else {
                setErrorColor({ ...errorColor, [name]: false });
            }
        }
        setField({ ...field, [name]: value });
    }

    const fileTypes = [
        "image/png",
        "image/jpeg"
    ];

    function validFileType(file) {
        return fileTypes.includes(file.type);
    }

    const [imgError, setImgError] = useState({
        bgImgRef: "",
        homeImgRef: "",
        mainLogoRef: "",
        sndLogoRef: "",
    });

    const [imgSize, setImgSize] = useState({
        bgImgRef: "",
        homeImgRef: "",
        mainLogoRef: "",
        sndLogoRef: "",
    });

    /**
     * Feeds the corresponding key of the state named imgState to the image file name.
     * 
     * Two conditions are set. For a valid extension and for a size limit.
     * @param {*} currentTarget event target 
     */
    const handleFileChange = ({ currentTarget }) => {
        const { name } = currentTarget;
        // eval converts name attribute (string) into its variable
        const currentFiles = eval(name).current.files;

        if (currentFiles.length > 0) {
            // wrong file extension
            if (validFileType(currentFiles[0])) {
                setImgState({ ...imgState, [name]: currentFiles[0] });
                setImgError((prevState) => ({
                    ...prevState,
                    [name]: ""
                }));
            } else {
                setImgState({ ...imgState, [name]: "" });
                setImgError((prevState) => ({
                    ...prevState,
                    [name]: "Seulement les fichiers de types png ou jpeg sont acceptés"
                }));
            }
            // size too big
            if (currentFiles[0].size > 2097152) {
                setImgState({ ...imgState, [name]: "" });
                setImgSize((prevState) => ({
                    ...prevState,
                    [name]: "La taille du fichier dépasse 2MB"
                }));
            } else {
                setImgState({ ...imgState, [name]: currentFiles[0] });
                setImgSize((prevState) => ({
                    ...prevState,
                    [name]: ""
                }));
            }
        }
    };

    /**
     * Allows removing picture from the form only if cursor hovers the badge
     * @param string the reference to work with
     * @param event
     */
    const handleRemoveImage = (ref, event) => {
        const badgeClass = event.target.classList;

        if ([...badgeClass].includes("MuiBadge-badge")) {
            setImgState({ ...imgState, [ref]: "" });
        }
    }

    /**
     * Adds text input values to the FormData.
     * 
     * Sends the three images one-by-one to ThemeController.
     * 
     * Sends the FormData with api-platform.
     * 
     * Logs out so you can witness the new theme.
     * 
     * @param {*} event 
     */
    const handleSubmit = async (event) => {
        event.preventDefault();
        

        try {
            const formObj = {};

            for (const [key, value] of Object.entries(field)) {
                formObj[key] = value;
            }

            formObj.isFlagActivated = isFlagActivated;
            //remove previous img before posting new img
            await themeService.removeImage(imgState)

            await themeService.postImage(imgState)
                .then(response => response.map(e => {
                    formObj[e[0]] = e[1];
                }))
                .then(() => {
                    // POST OR PUT
                    if ("isDefault" in theme) {
                        const wysiwygForm = {};
                        for (const [key, value] of Object.entries(theme.wysiwyg)) {
                            wysiwygForm[key] = value;
                        }

                        themeService.postThemeForm(formObj);
                        // init the wysiwyg to Espelia's colors (default)
                        themeService.postWysiwygForm(wysiwygForm);
                    }
                    else themeService.putThemeForm(formObj);
                })
                .catch((error) => {
                    console.log("error", error);
                });

            AuthAPI.logout();
            setIsAuthenticated(false);
            history.push("/connexion");
        } catch (error) {
            console.log(error)
            setError(true);
        }
    };


    return (
        <>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
                {/* MAIN GRID AND PAPER */}
                <Grid container className={classes.root}>
                    <Grid item xs={12} className={classes.itemSpacing}>
                        {/* IF VISUAL DEMO Paper BELOW WOULD NEED TO BE WRAPPED IN A DOUBLE GRID */}

                        <Paper className={classes.paper}>
                            {/* MAIN COLOR GRID */}
                            <Grid container>
                                <Grid item xs={12} className={classes.mt}>
                                    <Typography
                                        component="h3"
                                        variant="h5"
                                        className={classes.header3}
                                    >
                                        Charte des Couleurs
                                    </Typography>

                                    <Grid container spacing={3}>
                                        {/* NAVBAR COLOR GRID */}
                                        <Grid item xs={4}>
                                            <Typography component="h4" variant="h6" className={classes.header4}>
                                                La barre de navigation
                                            </Typography>
                                            <div className={classes.flexy}>
                                                <Tooltip title="Couleur en hexadecimal (#09afAF)" arrow>
                                                    <TextField
                                                        className={classes.m2}
                                                        variant="outlined"
                                                        margin="normal"
                                                        label="Couleur de fond"
                                                        name="menuNavbarBackgroundColor"
                                                        type="text"
                                                        value={field.menuNavbarBackgroundColor}
                                                        onChange={handleChange}
                                                        error={errorColor.menuNavbarBackgroundColor}
                                                    />
                                                </Tooltip>
                                                <input type="color" name="menuNavbarBackgroundColor" onChange={handleChange} value={field.menuNavbarBackgroundColor} />
                                            </div>
                                            <div className={classes.flexy}>
                                                <Tooltip title="Couleur en hexadecimal (#09afAF)" arrow>
                                                    <TextField
                                                        className={classes.m2}
                                                        variant="outlined"
                                                        margin="normal"
                                                        label="Couleur du texte"
                                                        name="menuNavbarColor"
                                                        type="text"
                                                        value={field.menuNavbarColor}
                                                        onChange={handleChange}
                                                        error={errorColor.menuNavbarColor}
                                                    />
                                                </Tooltip>
                                                <input type="color" name="menuNavbarColor" onChange={handleChange} value={field.menuNavbarColor} />
                                            </div>
                                        </Grid>
                                        {/* LABEL COLOR GRID */}
                                        <Grid item xs={4}>
                                            <Typography component="h4" variant="h6" className={classes.header4}>
                                                La barre des libellés
                                            </Typography>
                                            <div className={classes.flexy}>
                                                <Tooltip title="Couleur en hexadecimal (#09afAF)" arrow>
                                                    <TextField
                                                        className={classes.m2}
                                                        variant="outlined"
                                                        margin="normal"
                                                        label="Couleur de fond"
                                                        name="labelNavbarBackgroundColor"
                                                        type="text"
                                                        value={field.labelNavbarBackgroundColor}
                                                        onChange={handleChange}
                                                        error={errorColor.labelNavbarBackgroundColor}
                                                    />
                                                </Tooltip>
                                                <input type="color" name="labelNavbarBackgroundColor" onChange={handleChange} value={field.labelNavbarBackgroundColor} />
                                            </div>
                                            <div className={classes.flexy}>
                                                <Tooltip title="Couleur en hexadecimal (#09afAF)" arrow>
                                                    <TextField
                                                        className={classes.m2}
                                                        variant="outlined"
                                                        margin="normal"
                                                        label="Couleur du texte"
                                                        name="labelNavbarColor"
                                                        type="text"
                                                        value={field.labelNavbarColor}
                                                        onChange={handleChange}
                                                        error={errorColor.labelNavbarColor}
                                                    />
                                                </Tooltip>
                                                <input type="color" name="labelNavbarColor" onChange={handleChange} value={field.labelNavbarColor} />
                                            </div>
                                        </Grid>
                                        {/* B/W COLOR GRID */}
                                        <Grid item xs={4}>
                                            <Typography component="h4" variant="h6" className={classes.header4}>
                                                Personaliser le noir et le blanc
                                            </Typography>
                                            <div className={classes.flexy}>
                                                <Tooltip title="Couleur en hexadecimal (#09afAF)" arrow>
                                                    <TextField
                                                        className={classes.m2}
                                                        variant="outlined"
                                                        margin="normal"
                                                        label="Noir"
                                                        name="black"
                                                        type="text"
                                                        value={field.black}
                                                        onChange={handleChange}
                                                        error={errorColor.black}
                                                    />
                                                </Tooltip>
                                                <input type="color" name="black" onChange={handleChange} value={field.black} />
                                            </div>
                                            <div className={classes.flexy}>
                                                <Tooltip title="Couleur en hexadecimal (#09afAF)" arrow>
                                                    <TextField
                                                        className={classes.m2}
                                                        variant="outlined"
                                                        margin="normal"
                                                        label="Blanc"
                                                        name="white"
                                                        type="text"
                                                        value={field.white}
                                                        onChange={handleChange}
                                                        error={errorColor.white}
                                                    />
                                                </Tooltip>
                                                <input type="color" name="white" onChange={handleChange} value={field.white} />
                                            </div>
                                        </Grid>
                                        {/* CONNECTION COLOR GRID */}
                                        <Grid item xs={4}>
                                            <Typography component="h4" variant="h6" className={classes.header4}>
                                                La page de connexion
                                            </Typography>
                                            <div className={classes.flexy}>
                                                <Tooltip title="Couleur en hexadecimal (#09afAF)" arrow>
                                                    <TextField
                                                        className={classes.m2}
                                                        variant="outlined"
                                                        margin="normal"
                                                        label="Couleur du logo"
                                                        name="connectionLogo"
                                                        type="text"
                                                        value={field.connectionLogo}
                                                        onChange={handleChange}
                                                        error={errorColor.connectionLogo}
                                                    />
                                                </Tooltip>
                                                <input type="color" name="connectionLogo" onChange={handleChange} value={field.connectionLogo} />
                                            </div>
                                            <div className={classes.flexy}>
                                                <Tooltip title="Couleur en hexadecimal (#09afAF)" arrow>
                                                    <TextField
                                                        className={classes.m2}
                                                        variant="outlined"
                                                        margin="normal"
                                                        label="Couleur de fond du bouton"
                                                        name="connectionButtonBackgroundColor"
                                                        type="text"
                                                        value={field.connectionButtonBackgroundColor}
                                                        onChange={handleChange}
                                                        error={errorColor.connectionButtonBackgroundColor}
                                                    />
                                                </Tooltip>
                                                <input type="color" name="connectionButtonBackgroundColor" onChange={handleChange} value={field.connectionButtonBackgroundColor} />
                                            </div>
                                            <div className={classes.flexy}>
                                                <Tooltip title="Couleur en hexadecimal (#09afAF)" arrow>
                                                    <TextField
                                                        className={classes.m2}
                                                        variant="outlined"
                                                        margin="normal"
                                                        label="Couleur du texte du bouton"
                                                        name="connectionButtonColor"
                                                        type="text"
                                                        value={field.connectionButtonColor}
                                                        onChange={handleChange}
                                                        error={errorColor.connectionButtonColor}
                                                    />
                                                </Tooltip>
                                                <input type="color" name="connectionButtonColor" onChange={handleChange} value={field.connectionButtonColor} />
                                            </div>
                                            <div className={classes.flexy}>
                                                <Tooltip title="Couleur en hexadecimal (#09afAF)" arrow>
                                                    <TextField
                                                        className={classes.m2}
                                                        variant="outlined"
                                                        margin="normal"
                                                        label="Couleur de fond du bouton onHover"
                                                        name="connectionButtonBackgroundColorHover"
                                                        type="text"
                                                        value={field.connectionButtonBackgroundColorHover}
                                                        onChange={handleChange}
                                                        error={errorColor.connectionButtonBackgroundColorHover}
                                                    />
                                                </Tooltip>
                                                <input type="color" name="connectionButtonBackgroundColorHover" onChange={handleChange} value={field.connectionButtonBackgroundColorHover} />
                                            </div>
                                        </Grid>
                                        {/* MISCELLANEOUS COLOR GRID */}
                                        <Grid item xs={4}>
                                            <Typography component="h4" variant="h6" className={classes.header4}>
                                                Divers
                                            </Typography>
                                            <div className={classes.flexy}>
                                                <Tooltip title="Couleur en hexadecimal (#09afAF)" arrow>
                                                    <TextField
                                                        className={classes.m2}
                                                        variant="outlined"
                                                        margin="normal"
                                                        label="Couleur du titre des pages"
                                                        name="pageHeaderColor"
                                                        type="text"
                                                        value={field.pageHeaderColor}
                                                        onChange={handleChange}
                                                        error={errorColor.pageHeaderColor}
                                                    />
                                                </Tooltip>
                                                <input type="color" name="pageHeaderColor" onChange={handleChange} value={field.pageHeaderColor} />
                                            </div>
                                            <div className={classes.flexy}>
                                                <Tooltip title="Couleur en hexadecimal (#09afAF)" arrow>
                                                    <TextField
                                                        className={classes.m2}
                                                        variant="outlined"
                                                        margin="normal"
                                                        label="Couleur du texte des données"
                                                        name="dataColor"
                                                        type="text"
                                                        value={field.dataColor}
                                                        onChange={handleChange}
                                                        error={errorColor.dataColor}
                                                    />
                                                </Tooltip>
                                                <input type="color" name="dataColor" onChange={handleChange} value={field.dataColor} />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* INDICATORS */}
                                <Grid item xs={12} className={classes.mt}>
                                    <Typography component="h3" variant="h5" className={classes.header3}>
                                        Indicateur
                                    </Typography>
                                    <Grid container>
                                        {/* INDICATOR'S DROPDOWN LABELS */}
                                        <Grid item xs={12} className={classes.pb2}>
                                            <Typography component="h4" variant="h6" className={classes.header4}>
                                                Libellés des listes déroulantes de l'indicateur
                                            </Typography>
                                            <Grid container>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Thème"
                                                            name="indicatorTheme"
                                                            type="text"
                                                            value={field.indicatorTheme}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Sous-thème"
                                                            name="indicatorSubTheme"
                                                            type="text"
                                                            value={field.indicatorSubTheme}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Réferent"
                                                            name="indicatorSupervisor"
                                                            type="text"
                                                            value={field.indicatorSupervisor}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {/* INDICATOR'S MAIN TABLE LABELS */}
                                        <Grid item xs={12} className={classes.pb2}>
                                            <Typography component="h4" variant="h6" className={classes.header4}>
                                                Libellés du tableau principal de l'indicateur
                                            </Typography>
                                            <Grid container>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Sous-thème d'indicateur"
                                                            name="indicatorMainTableSubTopic"
                                                            type="text"
                                                            value={field.indicatorMainTableSubTopic}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Sous-libellé"
                                                            name="indicatorMainTableSubLabel"
                                                            type="text"
                                                            value={field.indicatorMainTableSubLabel}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Libellé de l'indicateur"
                                                            name="indicatorMainTableIndicatorLabel"
                                                            type="text"
                                                            value={field.indicatorMainTableIndicatorLabel}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Unité"
                                                            name="indicatorMainTableUnit"
                                                            type="text"
                                                            value={field.indicatorMainTableUnit}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Fréquence de mise à jour"
                                                            name="indicatorMainTableUpdateRate"
                                                            type="text"
                                                            value={field.indicatorMainTableUpdateRate}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Commentaire libre"
                                                            name="indicatorMainTableFreeComment"
                                                            type="text"
                                                            value={field.indicatorMainTableFreeComment}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Objectif"
                                                            name="indicatorMainTableTarget"
                                                            type="text"
                                                            value={field.indicatorMainTableTarget}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Typography component="h4" variant="h6" className={classes.header4}>
                                                Libellés des colonnes optionnelles du tableau principal de l'indicateur
                                            </Typography>
                                            <Grid container>
                                                {displayExtraColumn(extraMainColumns, MAIN)}
                                            </Grid>

                                        </Grid>

                                        {/* INDICATOR'S SUB-TABLE LABELS */}
                                        <Grid item xs={12} className={classes.pb2}>
                                            <Typography component="h4" variant="h6" className={classes.header4}>
                                                Libellés du sous-tableau de l'indicateur
                                            </Typography>
                                            <Grid container>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Date de mise à jour"
                                                            name="indicatorSubTableUpdate"
                                                            type="text"
                                                            value={field.indicatorSubTableUpdate}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Unité"
                                                            name="indicatorSubTableUnit"
                                                            type="text"
                                                            value={field.indicatorSubTableUnit}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Valeur"
                                                            name="indicatorSubTableValue"
                                                            type="text"
                                                            value={field.indicatorSubTableValue}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Catégorie"
                                                            name="indicatorSubTableCategory"
                                                            type="text"
                                                            value={field.indicatorSubTableCategory}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Commentaire à afficher sur la DataViz"
                                                            name="indicatorSubTabledataVizComment"
                                                            type="text"
                                                            value={field.indicatorSubTabledataVizComment}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Commentaire caché (non reporté en dataviz)"
                                                            name="indicatorSubTableHiddenComment"
                                                            type="text"
                                                            value={field.indicatorSubTableHiddenComment}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Typography component="h4" variant="h6" className={classes.header4}>
                                                Libellés des colonnes optionnelles du sous-tableau de l'indicateur
                                            </Typography>
                                            <Grid container>
                                                {displayExtraColumn(extraSubColumns, SUB)}
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* TARGETS */}
                                <Grid item xs={12} className={classes.mt}>
                                    <Typography component="h3" variant="h5" className={classes.header3}>
                                        Objectif
                                    </Typography>
                                    <Grid container>
                                        {/* TARGET'S DROPDOWN LABELS */}
                                        <Grid item xs={12} className={classes.pb2}>
                                            <Typography component="h4" variant="h6" className={classes.header4}>
                                                Libellés des listes déroulantes de l'objectif
                                            </Typography>
                                            <Grid container>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Thème"
                                                            name="targetTheme"
                                                            type="text"
                                                            value={field.targetTheme}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Sous-thème"
                                                            name="targetSubTheme"
                                                            type="text"
                                                            value={field.targetSubTheme}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {/* TARGET'S MAIN TABLE LABELS */}
                                        <Grid item xs={12} className={classes.pb2}>
                                            <Typography component="h4" variant="h6" className={classes.header4}>
                                                Libellés du tableau principal de l'objectif
                                            </Typography>
                                            <Grid container>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Rappel de l'indicateur concerné"
                                                            name="targetMainTableIndicator"
                                                            type="text"
                                                            value={field.targetMainTableIndicator}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Libellé de l'objectif"
                                                            name="targetMainTableLabel"
                                                            type="text"
                                                            value={field.targetMainTableLabel}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Fréquence de mise à jour"
                                                            name="targetMainTableUpdateRate"
                                                            type="text"
                                                            value={field.targetMainTableUpdateRate}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Unité"
                                                            name="targetMainTableUnit"
                                                            type="text"
                                                            value={field.targetMainTableUnit}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Commentaire libre"
                                                            name="targetMainTableFreeComment"
                                                            type="text"
                                                            value={field.targetMainTableFreeComment}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {/* TARGET'S SUB-TABLE LABELS */}
                                        <Grid item xs={12} className={classes.pb2}>
                                            <Typography component="h4" variant="h6" className={classes.header4}>
                                                Libellés du sous-tableau de l'objectif
                                            </Typography>
                                            <Grid container>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Date de mise à jour"
                                                            name="targetSubTableUpdate"
                                                            type="text"
                                                            value={field.targetSubTableUpdate}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Unité"
                                                            name="targetSubTableUnit"
                                                            type="text"
                                                            value={field.targetSubTableUnit}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Valeur"
                                                            name="targetSubTableValue"
                                                            type="text"
                                                            value={field.targetSubTableValue}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Commentaire à afficher sur la DataViz"
                                                            name="targetSubTableDataVizComment"
                                                            type="text"
                                                            value={field.targetSubTableDataVizComment}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Commentaire caché (non reporté en dataviz)"
                                                            name="targetSubTableHiddenComment"
                                                            type="text"
                                                            value={field.targetSubTableHiddenComment}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* IMAGE AND LOGOS UPLOAD */}
                                <Grid item xs={12} className={classes.mt}>
                                    <Typography component="h3" variant="h5" className={classes.header3}>
                                        Image de Fond et Logos
                                    </Typography>
                                    <Grid container>
                                        <Grid item xs={12} className={classes.pb2}>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <Typography component="h4" variant="h6" className={classes.header4}>
                                                        Image de connexion
                                                    </Typography>
                                                    <Grid container>
                                                        <div className={classes.flexy}>
                                                            <Button
                                                                variant="contained"
                                                                component="label"
                                                                className={classes.uploadBtn}
                                                            >
                                                                <RiUploadCloud2Line
                                                                    className={classes.scaleUp}
                                                                />
                                                                Choisir une image
                                                                <input
                                                                    type="file"
                                                                    name="bgImgRef"
                                                                    accept="image/png, image/jpeg"
                                                                    onChange={handleFileChange}
                                                                    ref={bgImgRef}
                                                                    hidden
                                                                />
                                                            </Button>
                                                            {imgState.bgImgRef !== "" ? (
                                                                <IconButton disableRipple className={classes.noCursor} onClick={(event) => handleRemoveImage("bgImgRef", event)}>
                                                                    <Badge className={classes.badge} badgeContent={"X"} color="secondary">
                                                                        <img
                                                                            src={imgState.bgImgRef !== "" ? URL.createObjectURL(imgState.bgImgRef) : ""}
                                                                            className={classes.img}
                                                                            alt=""
                                                                        />
                                                                    </Badge>
                                                                </IconButton>
                                                            ) : ""}
                                                        </div>
                                                        <span className={classes.errorImage}>{imgError.bgImgRef}</span>
                                                        <span className={classes.errorImage}>{imgSize.bgImgRef}</span>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography component="h4" variant="h6" className={classes.header4}>
                                                        Image d'accueil
                                                    </Typography>
                                                    <Grid container>
                                                        <div className={classes.flexy}>
                                                            <Button
                                                                variant="contained"
                                                                component="label"
                                                                className={classes.uploadBtn}
                                                            >
                                                                <RiUploadCloud2Line
                                                                    className={classes.scaleUp}
                                                                />
                                                                Choisir une image
                                                                <input
                                                                    type="file"
                                                                    name="homeImgRef"
                                                                    accept="image/png, image/jpeg"
                                                                    onChange={handleFileChange}
                                                                    ref={homeImgRef}
                                                                    hidden
                                                                />
                                                            </Button>
                                                            {imgState.homeImgRef !== "" ? (
                                                                <IconButton disableRipple className={classes.noCursor} onClick={(event) => handleRemoveImage("homeImgRef", event)}>
                                                                    <Badge className={classes.badge} badgeContent={"X"} color="secondary">
                                                                        <img
                                                                            src={imgState.homeImgRef !== "" ? URL.createObjectURL(imgState.homeImgRef) : ""}
                                                                            className={classes.img}
                                                                            alt=""
                                                                        />
                                                                    </Badge>
                                                                </IconButton>
                                                            ) : ""}
                                                        </div>
                                                        <span className={classes.errorImage}>{imgError.homeImgRef}</span>
                                                        <span className={classes.errorImage}>{imgSize.homeImgRef}</span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} className={classes.pb2}>
                                            {/* <Typography component="h4" variant="h6" className={classes.header4}>
                                                Logos
                                            </Typography> */}
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <Typography component="h4" variant="h6" className={classes.header4}>
                                                        Logo principal
                                                    </Typography>
                                                    <Grid container>
                                                        <div className={classes.flexy}>
                                                            <Button
                                                                variant="contained"
                                                                component="label"
                                                                className={classes.uploadBtn}
                                                            >
                                                                <RiUploadCloud2Line
                                                                    className={classes.scaleUp}
                                                                />
                                                                Choisir une image
                                                                <input
                                                                    type="file"
                                                                    name="mainLogoRef"
                                                                    accept="image/png, image/jpeg"
                                                                    onChange={handleFileChange}
                                                                    ref={mainLogoRef}
                                                                    hidden
                                                                />
                                                            </Button>
                                                            {imgState.mainLogoRef !== "" ? (
                                                                <IconButton disableRipple className={classes.noCursor} onClick={(event) => handleRemoveImage("mainLogoRef", event)}>
                                                                    <Badge className={classes.badge} badgeContent={"X"} color="secondary">
                                                                        <img
                                                                            src={imgState.mainLogoRef !== "" ? URL.createObjectURL(imgState.mainLogoRef) : ""}
                                                                            className={classes.img}
                                                                            alt=""
                                                                        />
                                                                    </Badge>
                                                                </IconButton>
                                                            ) : ""}
                                                        </div>
                                                        <span className={classes.errorImage}>{imgError.mainLogoRef}</span>
                                                        <span className={classes.errorImage}>{imgSize.mainLogoRef}</span>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography component="h4" variant="h6" className={classes.header4}>
                                                        Logo secondaire <span style={{ fontSize: "0.7em" }}>optionnel</span>
                                                    </Typography>
                                                    <Grid container>
                                                        <div className={classes.flexy}>
                                                            <Button
                                                                variant="contained"
                                                                component="label"
                                                                className={classes.uploadBtn}
                                                            >
                                                                <RiUploadCloud2Line
                                                                    className={classes.scaleUp}
                                                                />
                                                                Choisir une image
                                                                <input
                                                                    type="file"
                                                                    name="sndLogoRef"
                                                                    accept="image/png, image/jpeg"
                                                                    onChange={handleFileChange}
                                                                    ref={sndLogoRef}
                                                                    hidden
                                                                />
                                                            </Button>
                                                            {imgState.sndLogoRef !== "" ? (
                                                                <IconButton disableRipple className={classes.noCursor} onClick={(event) => handleRemoveImage("sndLogoRef", event)}>
                                                                    <Badge className={classes.badge} badgeContent={"X"} color="secondary">
                                                                        <img
                                                                            src={imgState.sndLogoRef !== "" ? URL.createObjectURL(imgState.sndLogoRef) : ""}
                                                                            className={classes.img}
                                                                            alt=""
                                                                        />
                                                                    </Badge>
                                                                </IconButton>
                                                            ) : ""}
                                                        </div>
                                                        <span className={classes.errorImage}>{imgError.sndLogoRef}</span>
                                                        <span className={classes.errorImage}>{imgSize.sndLogoRef}</span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} className={classes.pb2}>
                                            <Typography component="h4" variant="h6" className={classes.header4}>
                                                Lien des logos
                                            </Typography>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="URL du logo principal"
                                                            name="urlMainLogo"
                                                            type="text"
                                                            value={field.urlMainLogo}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="URL du logo secondaire"
                                                            name="urlSndLogo"
                                                            type="text"
                                                            value={field.urlSndLogo}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>


                                {/* Power BI */}
                                <Grid item xs={12} className={classes.mt}>
                                    <Typography component="h3" variant="h5" className={classes.header3}>
                                        Lien externe
                                    </Typography>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <Typography component="h4" variant="h6" className={classes.header4}>
                                                        Lien Power BI
                                                    </Typography>
                                                    <Grid container>
                                                        <TextField
                                                            className={classes.labelInput}
                                                            variant="outlined"
                                                            margin="normal"
                                                            label="Power BI"
                                                            name="powerBI"
                                                            type="text"
                                                            value={field.powerBI}
                                                            onChange={handleChange}
                                                            error={error}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* Flag catégorisation */}
                                <Grid item xs={12} className={classes.mt}>
                                    <Typography component="h3" variant="h5" className={classes.header3}>
                                        Activation des options
                                    </Typography>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <Typography component="h4" variant="h6" className={classes.header4}>
                                                        Flags de catégorisation
                                                    </Typography>
                                                    <Grid container>
                                                        <FormLabel>
                                                            <Checkbox
                                                                label="Option flag"
                                                                color="primary"
                                                                checked={isFlagActivated}
                                                                onClick={() => setIsFlagActivated(!isFlagActivated)}
                                                            />
                                                            {isFlagActivated ? "Activé" : "Désactivé"}
                                                        </FormLabel>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* SUBMIT */}
                                <Grid item xs={12} className={classes.mt}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        className={classes.submit}
                                    >
                                        Envoyer
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>

                        {/* IF VISUAL DEMO : HERE WOULD COME ITS DOUBLE GRID AND Paper INSIDE */}
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export default CustomSiteForm;