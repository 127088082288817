import axios from "axios";
import * as All from '../config';

import pbi from "../images/pbi2.png";



const API_URL = All.API_URL;

// Note : Since there is only one theme per client, id parameter is hard coded
const themeId = process.env.REACT_APP_THEME_ID;

/**
 * Loops through the keys of imgState if a file is present, it is submitted.
 * @param object imgState : each key has either an image file or an empty string.
 * @returns string[] — The unique name of each images
 */
async function postImage(imgState) {
    const imageNames = [];
    
    try {
        for (const [key, value] of Object.entries(imgState)) {
            const imgFormData = new FormData();
            imgFormData.append(key, value);

            if (value !== "") {
                await axios
                    .post(`${API_URL}image`, imgFormData)
                    .then(response => {
                        console.log(response);
                        if (response.status === 200) {
                            imageNames.push([key, response.data]);
                        }
                    })
                    .catch(error => { console.log(error); });
            }
        }
    } catch (err) {
        console.log(err);
    }

    return imageNames;
}

/**
 * Remove the previous image in order to replace it 
 * @param {*} 
 */
const removeImage = async (imgState) => {
    try {

        let imgArray = [];
        const theme = await findLastTheme();
        for (const [key, value] of Object.entries(imgState)) {
            if(`${key}` in theme[0] && `${value}` !== "" ){
                imgArray.push(theme[0][key] )
            }
          }

        if(imgArray.length > 0){
            await axios.post(`${API_URL}remove_image`,{images : imgArray}).then(response => response.data);
        }else{
            return
        }
    } catch (error) {
        console.error(error)
    }
}

/**
 *   return last entry of themes table in db
 */
const findLastTheme = async () => {
    return axios        
    .get(`${API_URL}themes`)
    .then(response => response.data['hydra:member'].slice(-1))
    .catch(error => { console.log(error); });
}

/**
 * Sends the complete form of the new theme
 * @param Object formData 
 */
function postThemeForm(formData) {
    axios
        .post(`${API_URL}themes`, formData)
        .then(response => { console.log(response.data['hydra:member']); })
        .catch(error => { console.log(error); });
}

/**
 * Updates the complete form
 * @param Object formData
 */
function putThemeForm(formdata) {
    axios
        .put(`${API_URL}themes/${themeId}`, formdata)
        .then(response => { console.log(response); })
        .catch(error => { console.log(error); });
}

/**
 * Sends the complete form of the new wysiwyg
 * @param Object formData 
 */
function postWysiwygForm(formData) {
    axios
        .post(`${API_URL}wysiwygs`, formData)
        .then(response => { console.log(response.data['hydra:member']); })
        .catch(error => { console.log(error); });
}

/**
 * Updates wysiwyg
 * @param Object formData
 */
function putWysiwygForm(formdata) {
    axios
        .put(`${API_URL}wysiwygs/${themeId}`, formdata)
        .then(response => { console.log(response); })
        .catch(error => { console.log(error); });
}

function getWysiwygById() {
    return axios
        .get(`${API_URL}wysiwygs/${themeId}`)
        .then((response) => response.data)
}

/**
 * Find by id the custom theme
 * @returns Promise : The response is the client's custom theme
 */
async function getThemeById() {
    return axios
        .get(`${API_URL}themes/${themeId}`)
        .then(response => {
            return {
                color: {
                    white: response.data.white,
                    black: response.data.black,
                    menuNavbarBackgroundColor: response.data.menuNavbarBackgroundColor,
                    menuNavbarColor: response.data.menuNavbarColor,
                    labelNavbarBackgroundColor: response.data.labelNavbarBackgroundColor,
                    labelNavbarColor: response.data.labelNavbarColor,
                    pageHeaderColor: response.data.pageHeaderColor,
                    connectionLogo: response.data.connectionLogo,
                    connectionButtonBackgroundColor: response.data.connectionButtonBackgroundColor,
                    connectionButtonColor: response.data.connectionButtonColor,
                    connectionButtonBackgroundColorHover: response.data.connectionButtonBackgroundColorHover,
                    dataColor: response.data.dataColor,
                },
                // Thèmes
                label: {
                    indicator: {
                        // Dropdown labels
                        theme: response.data.indicatorTheme,
                        subTheme: response.data.indicatorSubTheme,
                        supervisor: response.data.indicatorSupervisor,
                        // Table column definition
                        mainTable: {
                            subTopic: response.data.indicatorMainTableSubTopic,
                            subLabel: response.data.indicatorMainTableSubLabel,
                            indicatorLabel: response.data.indicatorMainTableIndicatorLabel,
                            unit: response.data.indicatorMainTableUnit,
                            updateRate: response.data.indicatorMainTableUpdateRate,
                            freeComment: response.data.indicatorMainTableFreeComment,
                            target: response.data.indicatorMainTableTarget,
                            indicatorMainTableOptionalText1: ("indicatorMainTableOptionalText1" in response.data) ? response.data.indicatorMainTableOptionalText1 : null,
                            indicatorMainTableOptionalText2: ("indicatorMainTableOptionalText2" in response.data) ? response.data.indicatorMainTableOptionalText2 : null,
                            indicatorMainTableOptionalText3: ("indicatorMainTableOptionalText3" in response.data) ? response.data.indicatorMainTableOptionalText3 : null,
                            indicatorMainTableOptionalFloat1: ("indicatorMainTableOptionalFloat1" in response.data) ? response.data.indicatorMainTableOptionalFloat1 : null,
                            indicatorMainTableOptionalFloat2: ("indicatorMainTableOptionalFloat2" in response.data) ? response.data.indicatorMainTableOptionalFloat2 : null,
                            indicatorMainTableOptionalFloat3: ("indicatorMainTableOptionalFloat3" in response.data) ? response.data.indicatorMainTableOptionalFloat3 : null,
                        },
                        subTable: {
                            update: response.data.indicatorSubTableUpdate,
                            unit: response.data.indicatorSubTableUnit,
                            value: response.data.indicatorSubTableValue,
                            category: response.data.indicatorSubTableCategory,
                            dataVizComment: response.data.indicatorSubTabledataVizComment,
                            hiddenComment: response.data.indicatorSubTableHiddenComment,
                            indicatorSubTableOptionalText1: ("indicatorSubTableOptionalText1" in response.data) ? response.data.indicatorSubTableOptionalText1 : null,
                            indicatorSubTableOptionalText2: ("indicatorSubTableOptionalText2" in response.data) ? response.data.indicatorSubTableOptionalText2 : null,
                            indicatorSubTableOptionalText3: ("indicatorSubTableOptionalText3" in response.data) ? response.data.indicatorSubTableOptionalText3 : null,
                            indicatorSubTableOptionalFloat1: ("indicatorSubTableOptionalFloat1" in response.data) ? response.data.indicatorSubTableOptionalFloat1 : null,
                            indicatorSubTableOptionalFloat2: ("indicatorSubTableOptionalFloat2" in response.data) ? response.data.indicatorSubTableOptionalFloat2 : null,
                            indicatorSubTableOptionalFloat3: ("indicatorSubTableOptionalFloat3" in response.data) ? response.data.indicatorSubTableOptionalFloat3 : null,
                        },
                    },
                    target: {
                        // Dropdown labels
                        theme: response.data.targetTheme,
                        subTheme: response.data.targetSubTheme,
                        // Table column definition
                        mainTable: {
                            indicator: response.data.targetMainTableIndicator,
                            label: response.data.targetMainTableLabel,
                            updateRate: response.data.targetMainTableUpdateRate,
                            unit: response.data.targetMainTableUnit,
                            freeComment: response.data.targetMainTableFreeComment,
                        },
                        subTable: {
                            update: response.data.targetSubTableUpdate,
                            unit: response.data.targetSubTableUnit,
                            value: response.data.targetSubTableValue,
                            dataVizComment: response.data.targetSubTableDataVizComment,
                            hiddenComment: response.data.targetSubTableHiddenComment,
                        },
                    }
                },
                image: {
                    connectionImage: `url("${API_URL}image?image=${response.data.bgImgRef}")`,
                    homeImage: ("homeImgRef" in response.data) ? `url("${API_URL}image?image=${response.data.homeImgRef}")` : "",
                    mainLogo: response.data.mainLogoRef,
                    secondLogo: ("sndLogoRef" in response.data) ? response.data.sndLogoRef : "",
                    urlMainLogo: response.data.urlMainLogo,
                    urlSndLogo: ("urlSndLogo" in response.data) ? response.data.urlSndLogo : "",
                },
                powerBI: {
                    image: pbi,
                    url: response.data.powerBI,
                },
                isFlagActivated: response.data.isFlagActivated,
            }
        })
        .catch(error => { console.log(error); });
}

/**
 * Returns totalItems of current extra column
 * @param string table : type_indicateurs (main) or indicateurs (sub)
 * @param string columnName : current extra column e.g. indicatorMainTableOptionalText1
 * @returns promise
 */
function getTotalItem(table, columnName) {
    return axios
        .get(`${API_URL}${table}?exists[${columnName}]=true`)
        .then(response => response.data["hydra:totalItems"]);
}

/**
 * 
 */
function patchTheme(data) {
    return axios
        .patch(`${API_URL}themes/${themeId}`, data, {
            headers: {
                'Content-Type': 'application/merge-patch+json',
            },
        });
}

export default {
    postImage,
    removeImage,
    postThemeForm,
    putThemeForm,
    getThemeById,
    getTotalItem,
    patchTheme,
    postWysiwygForm,
    putWysiwygForm,
    getWysiwygById,
}