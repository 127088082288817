import React, { useState, useEffect, useRef, useContext } from 'react';
import {
    BrowserRouter as Router,
    Link,
} from 'react-router-dom';
//material-ui
import { makeStyles } from '@material-ui/core/styles';
//material-table
import MaterialTable, { MTableToolbar } from 'material-table';
import Typography from '@material-ui/core/Typography';
//input select 
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
//tooltip
import Tooltip from '@material-ui/core/Tooltip';
//input text
import TextField from '@material-ui/core/TextField';
//icons
import { FcList } from "react-icons/fc";
import { FcBullish } from "react-icons/fc";
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AddCircleRounded from '@material-ui/icons/AddCircleRounded';
import Edit from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';

// pdfViewer imports
import VisibilityIcon from '@material-ui/icons/Visibility';
import ModalPdf from './pdfViewer/modalPdf';

//query
import serviceApi from '../services/ServiceApi';
import attachPdfApi from '../services/AttachPdfApi';
import filterApi from '../services/FilteredApi';
//modal component
import ModalCategorie from './modalCategorie';
import ModalObjectif from './modalObjectif';
import ModalResponsable from './modalResponsable';
//second table
import IndicateurTable from './indicateursSousTab';
//jwt token
import jwtDecode from "jwt-decode";

//alert 
import { Alert } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';

import { ThemeContext } from '../contexts/ThemeContext';
import { Checkbox, IconButton } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: '30px',
        width: 'fit-content',
        //remplace with breakpoint
    },
    table: {
        marginLeft: '30px',
        marginRight: '30px',
    },
    selectFormControl: {
        margin: theme.spacing(1),
        minWidth: "100%",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,

    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    inputForm: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    title: {
        color: props => props.color.labelNavbarColor,
        backgroundColor: props => props.color.labelNavbarBackgroundColor,
        height: "5vh",
        textTransform: "uppercase",
    },
    subtitle: {
        color: props => props.color.labelNavbarColor,
        backgroundColor: props => props.color.labelNavbarBackgroundColor,
        textTransform: "uppercase",
    },
    toolbarDiv: {
        color: props => props.color.labelNavbarBackgroundColor,
    },
    toolbar: {
        backgroundColor: props => props.color.white,
    },
    titleText: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '25px',
        color: props => props.color.pageHeaderColor,
        textTransform: "uppercase"
    },
    headerBorder: {
        borderColor: props => props.color.pageHeaderColor,
    },
    add: {
        fill: props => props.color.pageHeaderColor,
    },
    edit: {
        fill: "#ff9800",
    },
    delete: {
        fill: "#d50000",
    },
    check: {
        fill: "#2e7d32",
    },
    clear: {
        fill: "#d50000",
    },
    pdfIcon: {
        fill: props => props.color.pageHeaderColor,
    },
}));



const Indicateurs = () => {

    //styling
    const theme = useContext(ThemeContext);
    const classes = useStyles(theme);

    //state sous scat on change
    const [changeSousCat, setChangeSousCat] = useState('');
    //isLoading
    const [chargement, setChargement] = useState({
        isLoading: false
    });

    //table ref
    let tableRef = useRef();

    //error
    const [openAlert, setOpenAlert] = useState(false);
    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    //select catégorie & sous catégorie 
    const [categorie, setCategorie] = useState([]);
    const [sousCategorie, setSousCategorie] = useState([]);
    const [refreshTab, setRefreshTab] = useState(false);
    //data categorie
    useEffect(() => {
        const fetchCategorie = async () => {
            try {
                const dataCategorie = await filterApi.findFilteredCategorie(); // paramIndicId is always null and token is in the header with axios
                setCategorie(dataCategorie);
            }
            catch (err) {
                console.log(err)
            }
        }
        fetchCategorie();
    }, [refreshTab]);
    //data sous categorie
    useEffect(() => {
        const fetchSousCategorie = async () => {
            try {
                const dataSousCategorie = await serviceApi.findAllSousCategorie();
                let SousCategorieList = [];
                dataSousCategorie.forEach(element => {
                    SousCategorieList.push({
                        value: element.id,
                        name: element.libelleSousCategorie,
                        id_categorie: element.idCategorie,
                        id: element.id
                    });
                });
                setSousCategorie(SousCategorieList)
            }
            catch (err) {
                console.log(err)
            }
        };
        fetchSousCategorie();
    }, []);
    //data responsable
    const [responsable, setResponsable] = useState([]);
    useEffect(() => {
        const fetchResponsable = async () => {
            try {
                const dataResponsable = await serviceApi.findAllResponsable();
                let ResponsableList = [];
                dataResponsable.forEach(element => {
                    ResponsableList.push({
                        value: element.idResponsable,
                        name: element.nomResponsable,
                        id: element.idResponsable
                    });
                });
                setResponsable(ResponsableList)
            } catch (error) {
                console.log(error)
            }
        };
        fetchResponsable();
    }, []);

    //onchange input select  catégorie / sousCategorie
    const [selectsValues, setSelectsValues] = useState({
        selectCategorie: null,
        selectCategorieName: null,
        selectSousCategorie: null,
        selectSousCategorieName: null,
        selectResponsable: null,
        selectResponsableName: null,
    })
    useEffect(() => {
    }, [selectsValues])

    //add role

    const getDecodedToken = () => {
        const tokenBrut = window.localStorage.getItem("authToken");
        if (tokenBrut) {
            const decodedToken = jwtDecode(tokenBrut);
            return decodedToken;
        }
    }

    // sort entries by catégorie, sous catégorie and responsable
    const sortEntries = async (param) => {
        try {
            //animation
            setChargement({
                isLoading: true
            });

            const data = await filterApi.filterTypeIndicateur(param);
            setEntries(data);

            //animation
            setChargement({
                isLoading: false
            });
        } catch (error) {
            console.log(error)
        }
    }

    const sortSelectSousCategorie = async (idCategorie) => {
        try {
            const dataNewSousCategorie = await serviceApi.sortNewSousCategorie(idCategorie);
            let sortSousCategorieList = [];
            let newSousCatList = []
            dataNewSousCategorie.forEach(element => {
                sortSousCategorieList.push({
                    value: element.id,
                    name: element.libelleSousCategorie,
                    id_categorie: element.idCategorie,
                    id: element.id
                });
                newSousCatList.push({
                    id: element.id,
                    libelleSousCategorie: element.libelleSousCategorie
                });
            });
            setSousCategorie(sortSousCategorieList);
            reRenderTab(newSousCatList);
        } catch (error) {
            console.log(error);
        }
    };
    const handleChangeCategorie = (event) => {
        event.preventDefault();
        const dataValue = event.currentTarget.getAttribute("data-value");
        const dataName = event.currentTarget.getAttribute("name");
        sortSelectSousCategorie(dataValue);
        setSelectsValues({
            selectCategorie: dataValue,
            selectCategorieName: dataName,
            selectSousCategorie: selectsValues.selectSousCategorie ? selectsValues.selectSousCategorie : null,
            selectSousCategorieName: selectsValues.selectSousCategorieName ? selectsValues.selectSousCategorieName : null,
            selectResponsable: selectsValues.selectResponsable ? selectsValues.selectResponsable : null,
            selectResponsableName: selectsValues.selectResponsableName ? selectsValues.selectResponsableName : null,
        });

        const sortByCat = {
            selectCategorie: dataValue,
            selectCategorieName: dataName,
            selectSousCategorie: selectsValues.selectSousCategorie ? selectsValues.selectSousCategorie : null,
            selectSousCategorieName: selectsValues.selectSousCategorieName ? selectsValues.selectSousCategorieName : null,
            selectResponsable: selectsValues.selectResponsable ? selectsValues.selectResponsable : null,
            selectResponsableName: selectsValues.selectResponsableName ? selectsValues.selectResponsableName : null,
        }
        // sorting
        sortEntries(sortByCat);
    };
    const handleChangeSousCategorie = (event) => {
        const dataValue = event.currentTarget.getAttribute("data-value");
        const dataName = event.currentTarget.getAttribute("name");
        setAddedRowValue(dataValue);
        setSelectsValues({
            selectCategorie: selectsValues.selectCategorie ? selectsValues.selectCategorie : null,
            selectCategorieName: selectsValues.selectCategorieName ? selectsValues.selectCategorieName : null,
            selectSousCategorie: dataValue,
            selectSousCategorieName: dataName,
            selectResponsable: selectsValues.selectResponsable ? selectsValues.selectResponsable : null,
            selectResponsableName: selectsValues.selectResponsableName ? selectsValues.selectResponsableName : null,
        })

        //sorting
        const sortBySousCat = {
            selectCategorie: selectsValues.selectCategorie ? selectsValues.selectCategorie : null,
            selectCategorieName: selectsValues.selectCategorieName ? selectsValues.selectCategorieName : null,
            selectSousCategorie: dataValue,
            selectSousCategorieName: dataName,
            selectResponsable: selectsValues.selectResponsable ? selectsValues.selectResponsable : null,
            selectResponsableName: selectsValues.selectResponsableName ? selectsValues.selectResponsableName : null,
        };
        sortEntries(sortBySousCat);
    };

    //on change Responsable
    const handleChangeResponsable = (event) => {
        const dataValue = event.currentTarget.getAttribute("data-value");
        const dataName = event.currentTarget.getAttribute("name");
        setSelectsValues({
            selectCategorie: selectsValues.selectCategorie ? selectsValues.selectCategorie : null,
            selectCategorieName: selectsValues.selectCategorieName ? selectsValues.selectCategorieName : null,
            selectSousCategorie: selectsValues.selectSousCategorie ? selectsValues.selectSousCategorie : null,
            selectSousCategorieName: selectsValues.selectSousCategorieName ? selectsValues.selectSousCategorieName : null,
            selectResponsable: dataValue,
            selectResponsableName: dataName,
        })

        const sortByRes = {
            selectCategorie: selectsValues.selectCategorie ? selectsValues.selectCategorie : null,
            selectCategorieName: selectsValues.selectCategorieName ? selectsValues.selectCategorieName : null,
            selectSousCategorie: selectsValues.selectSousCategorie ? selectsValues.selectSousCategorie : null,
            selectSousCategorieName: selectsValues.selectSousCategorieName ? selectsValues.selectSousCategorieName : null,
            selectResponsable: dataValue,
            selectResponsableName: dataName,
        };

        //sorting
        sortEntries(sortByRes);
    }
    //data entries
    const [entries, setEntries] = useState([]);
    //title second table
    const [titleSecondTable, setTitleSecondTable] = useState("");
    const [subtitleSecondTable, setSubtitleSecondTable] = useState("");
    //input text error
    const [nameError, setNameError] = useState({
        error: false,
        label: "",
        helperText: "",
        validateInput: false,
    });

    //select frequences
    const FrequenceOptions = {};
    let datesList = [];
    useEffect(() => {
        const fetchDates = async () => {
            try {
                const dataFrequence = await serviceApi.findAllDates(); //query
                dataFrequence.forEach(element => {
                    datesList.push({
                        id: element.id,
                        typeDate: element.libelle,
                    });
                });
                datesList.map(dates => {
                    const { id, typeDate } = dates;
                    FrequenceOptions[typeDate] = typeDate;
                })
            }
            catch (err) {
                console.log(err)
            }
        };
        fetchDates();
    }, []);
    //select Unité
    const UniteOptions = {};
    let uniteList = [];
    useEffect(() => {
        const fetchUnites = async () => {
            try {
                const dataUnite = await serviceApi.findAllUnites(); //query


                dataUnite.forEach(element => {
                    uniteList.push({
                        id: element.id,
                        typeUnite: element.libelleUnite,
                    });
                });

                uniteList.map(unite => {
                    const { id, typeUnite } = unite;
                    UniteOptions[typeUnite] = typeUnite;
                })

            }
            catch (err) {
                console.log(err)
            }
        };
        fetchUnites();
    }, []);

    //Fetch all indicateurs
    useEffect(() => {
        const fetchIndicateurs = async () => {
            //animation            
            setChargement({
                isLoading: true
            });
            try {
                const data = await filterApi.filterTypeIndicateur();
                setEntries(data);
                
            } catch (error) {
                console.error('Une erreur est survenue lors du chargement des données')
            }
            //animation
            setChargement({
                isLoading: false
            });
        }
        fetchIndicateurs();
    }, [refreshTab]);



    //query to get sous categorie list
    const sousCatOptions = {};
    let sousCatList = [];
    useEffect(() => {
        const fetchSousCategorieTable = async (selectsValues) => {
            try {
                const sousCategorieTable = await serviceApi.sortCategorieSousCategorie(selectsValues);
                sousCategorieTable.forEach(element => {
                    sousCatList.push({
                        id: element.id,
                        libelleSousCategorie: element.libelleSousCategorie
                    });
                });
                sousCatList.map(sousCat => {
                    const { id, libelleSousCategorie } = sousCat;
                    sousCatOptions[libelleSousCategorie] = libelleSousCategorie;
                })


            } catch (error) {
                console.log(error)
            }
        };
        fetchSousCategorieTable(selectsValues);

    }, [selectsValues, refreshTab]);

    const [openModalPdf, setOpenModalPdf] = useState(false);

    const setColumn = (catParam, dateParam, unitParam) => {
        const columnObj = {
            columns: [
                {
                    title: theme.label.indicator.mainTable.subTopic,
                    field: 'idSousCategorie.libelleSousCategorie',
                    headerStyle: {
                        textTransform: "uppercase",
                    },
                    width: 150,
                    cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 100 },
                    editComponent: (props) => (
                        <>
                            <FormControl required className={classes.formControl} >
                                <Select
                                    labelId="select-libelleSousCategorie-required-label"
                                    id="select-libelleSousCategorie-required"
                                    value={props.value ? props.value : ""}
                                    defaultValue={props.value}
                                    autoWidth
                                    onChange={(e) => {
                                        setChangeSousCat(e.currentTarget.id)
                                        props.onChange(e.target.value);
                                    }}
                                    className={classes.selectEmpty}
                                >
                                    {catParam.map((sousCat) => (
                                        <MenuItem key={sousCat.id} id={sousCat.id} value={sousCat.libelleSousCategorie} name={sousCat.libelleSousCategorie}>{sousCat.libelleSousCategorie}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>Requis</FormHelperText>

                            </FormControl>
                        </>
                    )
                },
                {
                    title: "Pièce Jointe",
                    field: 'pdf',
                    editable: 'never',
                    headerStyle: {
                        textTransform: "uppercase",
                    },
                    width: 120,
                    cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 100 },
                    render: (props) => {
                        if( props === undefined || props === null ){
                            return ("");
                        }else{
                            return (
                                <>
                                    <span>{props.attachedPdfs.length + props.idIndicateur.attachedPdfs.length}</span>
                                    <IconButton onClick={() => setOpenModalPdf(true)}>
                                        <VisibilityIcon className={classes.pdfIcon} />
                                    </IconButton>
                                </>
                            )

                        }
                    },
                },
                {
                    title: theme.label.indicator.mainTable.indicatorLabel,
                    field: "libelle",
                    headerStyle: {
                        textTransform: "uppercase",
                    },
                    width: 200,
                    editComponent: (props) => (
                        <form className={classes.inputForm} noValidate autoComplete="off">
                            <TextField
                                className={classes.selectFormControl}
                                required
                                type="text"
                                fullWidth={true}
                                error={
                                    !props.value &&
                                        nameError.validateInput &&
                                        props.rowData.submitted
                                        ? nameError.error
                                        : false
                                }
                                helperText={
                                    !props.value &&
                                        nameError.validateInput &&
                                        props.rowData.submitted
                                        ? nameError.helperText
                                        : ""
                                }
                                value={props.value ? props.value : ""}
                                onChange={(e) => {
                                    if (nameError.validateInput) {
                                        setNameError({
                                            ...nameError,
                                            validateInput: false,
                                        });
                                    }

                                    props.onChange(e.target.value);
                                }}
                            />
                            <FormHelperText>Requis</FormHelperText>
                        </form>
                    ),
                    render: rowData => {
                        const data = rowData.idSousCategorie ? rowData.idSousCategorie.idCategorie.libelleCategorie : "";
                        return (
                            <Tooltip title={data}>
                                <Box key={"lib" + (rowData.id)}>{rowData.libelle}</Box>
                            </Tooltip>
                        )
                    }

                },
                {
                    title: theme.label.indicator.mainTable.subLabel,
                    field: "sousLibelle",
                    headerStyle: {
                        textTransform: "uppercase",
                    },
                    width: 200,
                    editComponent: (props) => (
                        <form className={classes.inputForm} noValidate autoComplete="off">
                            <TextField
                                className={classes.selectFormControl}
                                required
                                type="text"
                                fullWidth={true}
                                value={props.value ? props.value : ""}
                                onChange={(e) => {
                                    props.onChange(e.target.value);
                                }}
                            />
                        </form>
                    ),

                },
                {
                    title: theme.label.indicator.mainTable.updateRate,
                    field: "typeDate.libelle",
                    headerStyle: {
                        textTransform: "uppercase",
                    },
                    width: 150,
                    editComponent: (props) => (
                        <>
                            <FormControl required className={classes.formControl}>
                                <Select
                                    labelId="select-frequence-required-label"
                                    id="select-frequence-required"
                                    value={props.value ? props.value : ""}
                                    defaultValue={props.value}
                                    fullWidth={true}
                                    onChange={(e) => {
                                        props.onChange(e.target.value);
                                    }}
                                    className={classes.selectEmpty}
                                >
                                    {dateParam.map((frq) => (
                                        <MenuItem key={frq.typeDate} value={frq.typeDate} name={frq.typeDate}>{frq.typeDate}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>Requis</FormHelperText>
                            </FormControl>
                        </>
                    )
                },
                {
                    title: theme.label.indicator.mainTable.unit,
                    field: 'typeUnite.libelleUnite',
                    headerStyle: {
                        textTransform: "uppercase",
                    },
                    editable: 'onAdd',
                    width: 150,
                    editComponent: (props) => (
                        <>
                            <FormControl required className={classes.formControl}>
                                <Select
                                    labelId="select-unite-required-label"
                                    id="select-unite-required"
                                    value={props.value ? props.value : ""}
                                    defaultValue={props.value}
                                    autoWidth
                                    onChange={(e) => {
                                        props.onChange(e.target.value);
                                    }}
                                    className={classes.selectEmpty}
                                >
                                    {unitParam.map((unite) => (
                                        <MenuItem key={unite.typeUnite} value={unite.typeUnite} name={unite.typeUnite}>{unite.typeUnite}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>Requis / non modifiable</FormHelperText>
                            </FormControl>
                        </>
                    )

                },
                {
                    title: theme.label.indicator.mainTable.freeComment,
                    field: 'commentaire',
                    headerStyle: {
                        textTransform: "uppercase",
                    },
                    width: 150,
                    editComponent: (props) => (
                        <TextField
                            id="outlined-multiline-static"
                            label="Commentaire"
                            multiline
                            className={classes.selectFormControl}
                            rows={4}
                            defaultValue={props.value}
                            variant="outlined"
                            onChange={(e) => {
                                props.onChange(e.target.value);
                            }}
                        />
                    ),
                },
                {
                    title: theme.label.indicator.mainTable.target,
                    field: 'objectifs',
                    editable: 'never',
                    headerStyle: {
                        textTransform: "uppercase",
                    },
                    width: 150,
                    render: rowData => {
                        //to map all the objectives and empty the cell when adding a row 
                        const data = rowData ? rowData.objectifs : "";
                        return (
                            <div>
                                {(data !== "") ? data.map((obj, index) => (
                                    < React.Fragment key={index}>
                                        <Link to={obj.url} style={{ textDecoration: 'none' }}> - {obj.libelle ? obj.libelle : null} </Link >
                                        <br />
                                    </React.Fragment>
                                )) : null}
                            </div>
                        )
                    }
                },
                {
                    title: "Cumul",
                    field: 'cumul',
                    headerStyle: {
                        textTransform: "uppercase",
                    },
                    width: 150,
                    render: rowData => (
                        <Checkbox
                            label="Cumul"
                            color="primary"
                            checked={rowData.cumul}
                            disabled
                            indeterminate={rowData.cumul === undefined ? true : false}
                        />
                    ),
                    editComponent: (props) => (
                        <Checkbox
                            label="Cumul"
                            color="primary"
                            checked={props.value}
                            onChange={(e) => props.onChange(e.target.checked)}
                            indeterminate={props.value === undefined ? true : false}
                        />
                    ),
                },
            ],
        };

        const optionalTextColumns = [
            { indicatorMainTableOptionalText1: theme.label.indicator.mainTable.indicatorMainTableOptionalText1 },
            { indicatorMainTableOptionalText2: theme.label.indicator.mainTable.indicatorMainTableOptionalText2 },
            { indicatorMainTableOptionalText3: theme.label.indicator.mainTable.indicatorMainTableOptionalText3 },
        ];

        const optionalFloatColumns = [
            { indicatorMainTableOptionalFloat1: theme.label.indicator.mainTable.indicatorMainTableOptionalFloat1 },
            { indicatorMainTableOptionalFloat2: theme.label.indicator.mainTable.indicatorMainTableOptionalFloat2 },
            { indicatorMainTableOptionalFloat3: theme.label.indicator.mainTable.indicatorMainTableOptionalFloat3 },
        ];

        optionalTextColumns.map((optionalLabel, idx) => {
            if (optionalLabel[`indicatorMainTableOptionalText${idx + 1}`] !== null) {
                columnObj.columns.push({
                    title: optionalLabel[`indicatorMainTableOptionalText${idx + 1}`],
                    field: `indicatorMainTableOptionalText${idx + 1}`,
                    headerStyle: {
                        textTransform: "uppercase",
                    },
                    width: 150,
                    editComponent: (props) => (
                        <TextField
                            key={idx}
                            id={`multiline-indicatorMainTableOptionalText${idx + 1}`}
                            label={optionalLabel[`indicatorMainTableOptionalText${idx + 1}`]}
                            multiline
                            className={classes.selectFormControl}
                            rows={4}
                            defaultValue={props.value}
                            variant="outlined"
                            onChange={(e) => {
                                props.onChange(e.target.value);
                            }}
                        />
                    ),
                });
            }
        });

        optionalFloatColumns.map((optionalLabel, idx) => {
            if (optionalLabel[`indicatorMainTableOptionalFloat${idx + 1}`] !== null) {
                columnObj.columns.push({
                    title: optionalLabel[`indicatorMainTableOptionalFloat${idx + 1}`],
                    field: `indicatorMainTableOptionalFloat${idx + 1}`,
                    headerStyle: {
                        textTransform: "uppercase",
                    },
                    width: 150,
                    editComponent: (props) => (
                        <TextField
                            key={idx}
                            type="number"
                            id={`multiline-indicatorMainTableOptionalFloat${idx + 1}`}
                            label={optionalLabel[`indicatorMainTableOptionalFloat${idx + 1}`]}
                            className={classes.selectFormControl}
                            defaultValue={props.value}
                            variant="filled"
                            onChange={(e) => {
                                props.onChange(parseFloat(e.target.value));
                            }}
                        />
                    ),
                });
            }
        });

        return columnObj;
    };

    const reRenderTab = async (paramSousCat) => {
        let datesList2 = [];
        let uniteList2 = [];
        try {
            const dataFrequence = await serviceApi.findAllDates(); //query
            dataFrequence.forEach(element => {
                datesList2.push({
                    id: element.id,
                    typeDate: element.libelle,
                });
            });
            const dataUnite = await serviceApi.findAllUnites(); //query
            dataUnite.forEach(element => {
                uniteList2.push({
                    id: element.id,
                    typeUnite: element.libelleUnite,
                });
            });

            setState(setColumn(paramSousCat, datesList2, uniteList2));
        } catch (error) {
            console.log(error)
        }
    }
    //table column definition\\
    const [state, setState] = useState(setColumn(sousCatList, datesList, uniteList));

    const [addedRowValue, setAddedRowValue] = useState("_all");
    // Row add , edit or  delete
    const addNewRow = async (param, userToken, changeSousCat) => {
        try {

            await serviceApi.createTypeIndicateur(param, userToken, changeSousCat);
            //refresh the table with the same sous categorie
            let id = parseInt(param.idSousCategorie.match(/\/(\d+)+[\/]?/g).map(id => id.replace(/\//g, '')));
            setAddedRowValue(id);
            sortEntries({
                selectResponsable: null,
                selectSousCategorie: id,
                selectCategorie: null
            });

        } catch (error) {
            console.log(error);
        }
    }

    const updateRow = async (param, param2) => {
        try {
            await serviceApi.updateRowTypeIndicateur(param, param2);
            setRefreshTab(!refreshTab);
        } catch (error) {
            console.log(error);
        }
    }

    const deleteRow = async (param) => {
        try {
            if(param.hasOwnProperty('attachedPdfs')){
                for (const iterator of param.attachedPdfs) {
                    await attachPdfApi.deletePdf(iterator.id,iterator.uniqueName);
                }
            }

            if(param.hasOwnProperty('idIndicateur') && param.idIndicateur.hasOwnProperty('attachedPdfs')){
                for (const i of param.idIndicateur.attachedPdfs) {
                    await attachPdfApi.deletePdf(i.id,i.uniqueName);
                }
            }

            await serviceApi.deleteTypeIndicateur(param.id);

            setRefreshTab(!refreshTab);
            setDisplaySecondTable('hidden');
        } catch (error) {
            console.error(error)
        }

    }

    const [displaySecondTable, setDisplaySecondTable] = useState("hidden");
    const hideSecondTable = () => {
        setDisplaySecondTable('hidden');
    }
    //scroll
    const scroll = () => {
        const section = document.querySelector('#anchorSousTab');
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const [selectedRow, setSelectedRow] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);

    //onRow click
    const handleRowClick = (event, rowData) => {
        event.preventDefault();
        setTitleSecondTable(rowData.idSousCategorie.libelleSousCategorie + " - " + rowData.libelle);
        if (rowData.sousLibelle !== undefined && rowData.sousLibelle !== "") {
            setSubtitleSecondTable(" - " + rowData.sousLibelle + " - ");
        }
        setSendselectedRowTable(rowData);
        if (displaySecondTable === "hidden") {
            setDisplaySecondTable('visible');
            scroll();
        } else if (displaySecondTable === "visible") {
            scroll();
        } else {
            setDisplaySecondTable('hidden');
        }
        setSelectedRow(rowData.tableData.id);
        setCurrentRow(rowData);
    }

    // open modal
    const [openObjectif, setOpenObjectif] = useState(false);

    //row data modal
    const [sendselectedRow, setSendselectedRow] = useState([]);
    const ShowCategorieModal = (param) => {
        setSendselectedRow([param]);
    }
    const [sendselectedRowObjectif, setSendselectedRowObjectif] = useState([]);
    const ShowObjectifModal = (param) => {
        setSendselectedRowObjectif([param]);
    }
    const [sendselectedRowResponsable, setSendselectedRowResponsable] = useState([]);
    const ShowResponsableModal = (param) => {
        setSendselectedRowResponsable([param]);
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialogName, setOpenDialog] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openCategorie = () => {
        setOpenDialog('openCategorie');
        handleClose();
    };

    const openModalObjectif = () => {
        setOpenDialog('openModalObjectif');
        handleClose();
    };

    const openModalResponsable = () => {
        setOpenDialog('openModalResponsable');
        handleClose();
    };

    const closeDialog = () => {
        setOpenDialog(null);
    };

    const refreshParent = () => {
        setRefreshTab(!refreshTab);
    };

    const [sendselectedRowTable, setSendselectedRowTable] = useState([]);
    const ShowSecondTable = (param) => {
        setSendselectedRowTable([param]);
    }

    const mtOption = {
        rowStyle: rowData => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : theme.color.white,
        }),
        headerStyle: {
            backgroundColor: theme.color.labelNavbarBackgroundColor,
            color: theme.color.labelNavbarColor
        },

        pageSize: 10,
        pageSizeOptions: [10, 20, 30, { label: 'Tout', value: entries.length }],
        tableLayout: "fixed", //columns dimensions
        //actionsColumnIndex: -1, //action column o right side
        addRowPosition: 'first', //add row on top
        padding: 'dense',
    };


    //display error message
    return (
        <>
            <div className="corners">
                <div className={`top left ${classes.headerBorder}`}></div>
                <div className={`top right ${classes.headerBorder}`}></div>
                <div className={`bottom right ${classes.headerBorder}`}></div>
                <div className={`bottom left ${classes.headerBorder}`}></div>
                <Typography variant="h4" align='center' className={classes.titleText}>Indicateurs</Typography>
            </div>
            <Grid container alignItems="center" className={classes.root}>
                <FormControl className={classes.formControl}>
                    <InputLabel id="categorie-simple-select-autowidth-label">{theme.label.indicator.theme}</InputLabel>
                    <Select
                        labelId="categorie-simple-select-autowidth-label"
                        id="categorie-simple-select-autowidth"
                        value={categorie ? categorie.value : "" }
                        // value={categorie.value ? categorie.value : ""}
                        defaultValue="_all"
                        onChange={handleChangeCategorie}
                        autoWidth
                    >
                        <MenuItem value="_all" ><em>Tout</em></MenuItem>
                        {categorie.map((cat) => (
                            <MenuItem key={cat.id} value={cat.value} name={cat.name}>{cat.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Divider
                    orientation="vertical"
                    flexItem
                    light
                />
                <FormControl className={classes.formControl}>
                    <InputLabel id="ssCategorie-simple-select-autowidth-label">{theme.label.indicator.subTheme}</InputLabel>
                    <Select
                        labelId="ssCategorie-simple-select-autowidth-label"
                        id="ssCategorie-simple-select-autowidth"
                        value={addedRowValue}
                        defaultValue="_all"
                        onChange={handleChangeSousCategorie}
                        autoWidth
                    >
                        <MenuItem value="_all"><em>Tout</em></MenuItem>
                        {sousCategorie.map((sscat) => (
                            <MenuItem key={sscat.id} value={sscat.value} name={sscat.name}>{sscat.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Divider
                    orientation="vertical"
                    flexItem
                    light
                />
                <FormControl className={classes.formControl}>
                    <InputLabel id="responsable-simple-select-autowidth-label">{theme.label.indicator.supervisor}</InputLabel>
                    <Select
                        labelId="responsable-simple-select-autowidth-label"
                        id="responsable-simple-select-autowidth"
                        value={responsable.value}
                        defaultValue="_all"
                        onChange={handleChangeResponsable}
                        autoWidth
                    >
                        <MenuItem value="_all"><em>Tous</em></MenuItem>
                        {responsable.map((res) => (
                            <MenuItem key={res.id} value={res.value} name={res.name}>{res.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <div className={classes.table} >
                <MaterialTable
                    title={'Définition d\'un type d\'indicateur'.toUpperCase()}
                    columns={state.columns}
                    data={entries}
                    tableRef={tableRef}
                    onRowClick={(event, rowData) => handleRowClick(event, rowData)}
                    isLoading={chargement.isLoading}
                    components={{
                        Toolbar: props => (
                            <div className={classes.toolbarDiv}>
                                <MTableToolbar {...props} className={classes.toolbar} />
                            </div>
                        ),
                    }}
                    options={mtOption}
                    style={{
                        color: theme.color.dataColor,
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: "{from} à {to} sur {count}",
                            labelRowsSelect: "résultats par page",
                            labelRowsPerPage: "résultats par page",
                            firstAriaLabel: "Première page",
                            firstTooltip: "Première page",
                            previousAriaLabel: "Page précédente",
                            previousTooltip: "Page précédente",
                            nextAriaLabel: "Page suivante",
                            nextTooltip: "Page suivante",
                            lastAriaLabel: "Dernière page",
                            lastTooltip: "Dernière page"
                        },
                        toolbar: {
                            addRemoveColumns: "Ajouter ou supprimer des colonnes",
                            nRowsSelected: "{0} ligne(s) sélectionnée(s)",
                            showColumnsTitle: "Afficher les colonnes",
                            showColumnsAriaLabel: "Afficher les colonnes",
                            exportTitle: "Exporter",
                            exportAriaLabel: "Exporter",
                            exportName: "Exporter en CSV",
                            searchTooltip: "Recherche",
                            searchPlaceholder: "Recherche"
                        },
                        header: {
                            actions: ''
                        },
                        body: {
                            emptyDataSourceMessage:
                                "Désolé, aucun enregistrement correspondant n'a été trouvé",
                            addTooltip: "Ajouter une ligne",
                            deleteTooltip: "Supprimer l'indicateur",
                            editTooltip: "Modifier l'indicateur",
                            filterRow: {
                                filterTooltip: "Filtrer"
                            },
                            editRow: {
                                deleteText: "Êtes-vous sûr de vouloir supprimer cet élément ? Vous allez perdre TOUTES les données associées",
                                cancelTooltip: "Annuler",
                                saveTooltip: "Sauvegarder"
                            }
                        },
                    }}
                    icons={{
                        Add: props => <AddCircleRounded {...props} className={classes.add} />,
                        Edit: props => <Edit  {...props} className={classes.edit} />,
                        Delete: props => <DeleteForeverIcon   {...props} className={classes.delete} />,
                        Check: props => <Check   {...props} className={classes.check} />,
                        Clear: props => <Clear    {...props} className={classes.clear} />,
                    }}
                    actions={[
                        rowData => ({
                            icon: () => {
                                return (
                                    <FcList style={{ fontSize: 20 }} />
                                )
                            },
                            tooltip: 'Catégoriser l\'indicateur',
                            onClick: (event, rowData) => {
                                setSendselectedRow(rowData);
                                openCategorie();
                            },
                            //display buttons according to role                            

                            hidden: (theme.isFlagActivated === true) ? !rowData.access.ecriture : true,
                        }),
                        rowData => ({
                            icon: () => <FcBullish style={{ fontSize: 20 }} />,
                            tooltip: 'Définition d\'un type d\'objectif',
                            onClick: (event, rowData) => {
                                event.preventDefault();
                                setSendselectedRowObjectif(rowData);
                                openModalObjectif();
                            },
                            //display buttons according to role
                            disabled: !rowData.access.ecriture,
                            hidden: !rowData.access.ecriture 
                        }),
                        rowData => ({
                            icon: () => <PeopleAltIcon style={{ fill: "#1c60a3", fontSize: 20 }} />,
                            tooltip: 'Définition d\'un référent',
                            onClick: (event, rowData) => {
                                event.preventDefault();
                                setSendselectedRowResponsable(rowData);
                                openModalResponsable();
                            },
                            //display buttons according to role
                            disabled: !rowData.access.ecriture,
                            hidden: !rowData.access.ecriture 
                        }),
                    ]}
                    editable={{
                        //display buttons according to role
                        //isEditable: rowData => roleIndicateur.canEdit(rowData), // only name(a) rows would be editable
                        isEditHidden: rowData => !rowData.access.ecriture, //only if rowData.id match
                        //isDeletable: rowData => rowData.sousCategorie === 'Communication', // only name(b) rows would be deletable,
                        isDeleteHidden: rowData => !rowData.access.suppression, //only if rowData.id match

                        onRowAdd: (getDecodedToken().roles[0] !== "USER") ? (newData) =>
                            new Promise((resolve, reject) => {
                                newData.submitted = true;
                                if (!newData.libelle || !newData.typeDate || !newData.typeUnite) {
                                    setNameError({
                                        error: true,
                                        label: "champs requis",
                                        helperText: "erreur",
                                        validateInput: true,
                                    });
                                    setOpenAlert(true);
                                    reject();
                                    return;
                                }

                                //setEntries([...entries, newData]);
                                addNewRow(newData, getDecodedToken(), changeSousCat);
                                resolve();

                            }) : null,

                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    newData.submitted = true;
                                    if (!newData.libelle || !newData.typeDate) {
                                        setNameError({
                                            error: true,
                                            label: "champs requis",
                                            helperText: "erreur",
                                            validateInput: true,
                                        });
                                        setOpenAlert(true);
                                        reject();
                                        return;
                                    }

                                    const dataUpdate = [...entries];
                                    const index = oldData.tableData.id;
                                    dataUpdate[index] = newData;
                                    //setEntries([...dataUpdate]);
                                    updateRow(newData, changeSousCat);
                                    resolve();
                                }, 1000);
                            }),
                        onRowDelete: (oldData) =>
                            new Promise((resolve) => {
                                const dataDelete = [...entries];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                //setEntries([...dataDelete]);
                                //  deleteRow(oldData.id);
                                deleteRow(oldData);
                                resolve();
                            }),
                    }}

                />
                <div>
                    <ModalCategorie
                        open={openDialogName === 'openCategorie'}
                        onClose={closeDialog}
                        ShowCategorieModal={sendselectedRow}
                    />
                </div>
                <div>
                    <ModalObjectif
                        open={openDialogName === 'openModalObjectif'}
                        onClose={closeDialog}
                        ShowObjectifModal={sendselectedRowObjectif}
                        refreshParent={refreshParent}
                    />
                </div>
                <div>
                    <ModalResponsable
                        open={openDialogName === 'openModalResponsable'}
                        onClose={closeDialog}
                        ShowResponsableModal={sendselectedRowResponsable}
                        refreshParent={refreshParent}
                    />
                </div>
                <div>
                    <ModalPdf
                        open={openModalPdf}
                        onClose={setOpenModalPdf}
                        rowData={currentRow}
                        source="TYPE_INDICATEUR"
                        refreshParent={refreshParent}
                        hideSecondTable={hideSecondTable}
                    />
                </div>
                <div id="anchorSousTab">{'\u00A0'}</div>
                <div style={{ visibility: displaySecondTable }} >
                    <Typography
                        className={classes.title}
                        variant="h6"
                        component="h3"
                        align="center"

                    >
                        {titleSecondTable}
                    </Typography>
                    {subtitleSecondTable !== "" ?
                        <Typography
                            className={classes.subtitle}
                            variant="subtitle1"
                            component="h4"
                            align="center"

                        >
                            {subtitleSecondTable}
                        </Typography> : null
                    }

                </div>
            </div>
            {/* second table */}
            <div style={{ visibility: displaySecondTable }} >
                <IndicateurTable ShowSecondTable={sendselectedRowTable} refreshParent={refreshParent} hideSecondTable={hideSecondTable} />
            </div>
            <Snackbar
                open={openAlert}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert variant="filled" severity="error">
                    Veuillez vérifier votre saisie.
                </Alert>
            </Snackbar>
        </>
    )
}

export default Indicateurs;
