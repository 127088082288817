import axios from "axios";
import {TYPE_CATEGORIE_API} from "../config.js";

function findAllCategorie(){
    return axios
        .get(TYPE_CATEGORIE_API)
        .then(response => response.data['hydra:member']);
}

export default {
    findAllCategorie
}