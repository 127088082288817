import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable, { MTableToolbar } from 'material-table';
//query
import userApi from '../services/UserApi';
import serviceApi from '../services/ServiceApi';
import rightApi from '../services/rightApi';
//icons
import AddCircleRounded from '@material-ui/icons/AddCircleRounded';
import Edit from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import RemoveIcon from '@material-ui/icons/Remove';
//material-ui
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Divider from '@material-ui/core/Divider';
//checkbox 
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import { ThemeContext } from '../contexts/ThemeContext';

const useStyles = makeStyles(theme => ({
    root: {
        root: {
            //marginTop: '15vh',
            marginLeft: '30px',
            width: 'fit-content',
            //remplace with breakpoint
        },
    },
    page: {
        marginLeft: '30px',
        marginRight: '30px',
    },
    table: {

        maxWidth: "100%",

    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,

    },
    selectColor: {
        color: props => props.color.black,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        color: props => props.color.black,
    },
    toolbarDiv: {
        color: props => props.color.labelNavbarBackgroundColor,
        backgroundColor: props => props.color.white,
    },
    toolbar: {
        backgroundColor: props => props.color.white,
    },
    add: {
        fill: props => props.color.pageHeaderColor,
    },
    edit: {
        fill: "#175Be5",
    },
    delete: {
        fill: "#d50000",
    },
    check: {
        fill: "#2e7d32",
    },
    clear: {
        fill: "#d50000",
    },
    thirdStateCheck: {
        fill: "#d50000",
    },
}));

const RightsManagement = () => {
    //styling
    const theme = useContext(ThemeContext);
    const classes = useStyles(theme);


    //isLoading
    const [chargement, setChargement] = useState({
        isLoading: false
    });

    //data entries
    const [entries, setEntries] = useState([]);
    const [saveEntries, setSaveEntries] = useState(new Map());

    //onchange input select  catégorie / sousCategorie

    const [selectsValues, setSelectsValues] = useState(new Map());

    //user
    const [userSelect, setUserSelect] = useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {

                const userData = await userApi.getFilteredUsers(); 
                setUserSelect(userData)
            }
            catch (err) {
                console.log(err)
            }
        };
        fetchUsers();
    }, []);

    //checkbox column values

    const checkAll = (data) => {

        let countLecture = 0;
        let countEcriture = 0;
        let countSuppression = 0;
        data.forEach(element => {
            if (element.lecture) {
                countLecture++;
            }
            if (element.ecriture) {
                countEcriture++;
            }
            if (element.suppression) {
                countSuppression++
            }
        })

        data.length === countLecture ? setLectureAllChecked(prevState => prevState.set("lecture", true)) : setLectureAllChecked(prevState => prevState.set("lecture", false));
        data.length === countEcriture ? setEcritureAllChecked((prevState => prevState.set("ecriture", true))) : setEcritureAllChecked((prevState => prevState.set("ecriture", false)));
        data.length === countSuppression ? setSuppressionAllChecked((prevState => prevState.set("suppression", true))) : setSuppressionAllChecked((prevState => prevState.set("suppression", false)));


        //set the columns
        setState({
            columns: [
                {
                    title: 'Utilisateurs', field: 'email', editable: 'never',
                    headerStyle: {
                        textTransform: "uppercase",
                        textAlign: 'left',
                    },
                },
                {
                    title: 'Type indicateurs', field: 'libelle', editable: 'never',
                    headerStyle: {
                        textTransform: "uppercase",
                        textAlign: 'left',
                    },
                },
                {
                    title:
                        <Box >
                            <Box component="div" display="inline">Lecture</Box>
                            <Checkbox
                                display="inline"
                                checked={lectureAllChecked.get("lecture") || false}
                                onChange={toggleCheckboxes}
                                name="lecture"

                            />
                        </Box>,
                    field: 'lecture', type: "boolean", sorting: false, tooltip: "Tout cocher",
                    headerStyle: {
                        textTransform: "uppercase",

                    },
                    cellStyle: {
                        textAlign: 'center',

                    },
                    render: rowData => <Checkbox
                        checked={rowData.lecture ? rowData.lecture : false}
                        onChange={(event) => handleChangeLecture(event, rowData)}
                    />
                },
                {
                    title:
                        <Box >
                            <Box component="div" display="inline">Ecriture</Box>
                            <Checkbox
                                display="inline"
                                checked={ecritureAllChecked.get("ecriture") || false}
                                onChange={toggleCheckboxes}
                                name="ecriture"
                            />
                        </Box>,
                    field: 'ecriture', type: "boolean", sorting: false, tooltip: "Tout cocher",
                    headerStyle: {
                        textTransform: "uppercase",

                    },
                    cellStyle: {
                        textAlign: 'center',

                    },
                    render: rowData => <Checkbox
                        checked={rowData.ecriture ? rowData.ecriture : false}
                        onChange={(event) => handleChangeEcriture(event, rowData)}
                    />
                },
                {
                    title:
                        <Box >
                            <Box component="div" display="inline">Suppression</Box>
                            <Checkbox
                                display="inline"
                                checked={suppressionAllChecked.get("suppression") || false}
                                onChange={toggleCheckboxes}
                                name="suppression"
                            />
                        </Box>,
                    field: 'suppression', type: "boolean", sorting: false, tooltip: "Tout cocher",
                    headerStyle: {
                        textTransform: "uppercase",

                    },
                    cellStyle: {
                        textAlign: 'center',

                    },
                    render: rowData => <Checkbox
                        checked={rowData.suppression ? rowData.suppression : false}
                        onChange={(event) => handleChangeSuppression(event, rowData)}
                    />
                },
                {
                    title: 'Thème', field: 'libelleCategorie', editable: 'never',
                    headerStyle: {
                        textTransform: "uppercase",
                        textAlign: 'left',
                    },
                },
                {
                    title: 'Sous-thème', field: 'libelleSousCategorie', editable: 'never',
                    headerStyle: {
                        textTransform: "uppercase",
                        textAlign: 'left',
                    },
                },
            ],
        })
        setEntries(data);
    }

    // sort entries by catégorie, sous catégorie and responsable
    const sortEntries = async (param) => {
        const selectsValues = {
            selectCategorie: param.get("selectCategorie") ? param.get("selectCategorie") : null,
            selectCategorieName: param.get("selectCategorieName") ? param.get("selectCategorieName") : null,
            selectSousCategorie: param.get("selectSousCategorie") ? param.get("selectSousCategorie") : null,
            selectSousCategorieName: param.get("selectSousCategorieName") ? param.get("selectSousCategorieName") : null,
            selectResponsable: param.get("selectResponsable") ? param.get("selectResponsable") : null,
            selectResponsableName: param.get("selectResponsableName") ? param.get("selectResponsableName") : null,
            userSelect: param.get("userSelect") ? param.get("userSelect") : null
        };


        try {
            //animation
            setChargement({
                isLoading: true
            });

            const response = await serviceApi.filterAssocUserTypeIndicteurByUser(selectsValues); 

            setSaveEntries(prevState => prevState.set("saveEntries", response));

            checkAll(response);

            //animation
            setChargement({
                isLoading: false
            });
        } catch (error) {
            console.log(error)
        }
    }

    const sortSelectSousCategorie = async (idCategorie) => {
        try {
            const dataNewSousCategorie = await serviceApi.sortNewSousCategorie(idCategorie);
            let sortSousCategorieList = [];
            dataNewSousCategorie.forEach(element => {
                sortSousCategorieList.push({
                    value: element.id,
                    name: element.libelleSousCategorie,
                    id_categorie: element.idCategorie,
                    id: element.id
                });
            });
            setSousCategorie(sortSousCategorieList)

        } catch (error) {
            console.log(error);
        }
    };

    // select visibility
    const [selectVisibility, setSelectVisibility] = useState('hidden');
    // on change User select    
    const handleChangeResponsable = event => {
        event.preventDefault();
        const dataValue = event.currentTarget.getAttribute("data-value");
        const dataName = event.currentTarget.getAttribute("name");
        const userObj = {
            name: dataName,
            value: dataValue
        }

        setSelectsValues(prevState => prevState.set("userSelect", userObj))

        //visibility
        setSelectVisibility('visible');

        //sorting
        sortEntries(selectsValues);

    }


    //data categorie
    const [categorie, setCategorie] = useState([]);
    useEffect(() => {
        const fetchCategorie = async () => {
            try {
                const dataCategorie = await serviceApi.findAllCategorie();
                let CategorieList = [];
                dataCategorie.forEach(element => {
                    CategorieList.push({
                        value: element.id,
                        name: element.libelleCategorie,
                        id: element.id
                    });
                });
                setCategorie(CategorieList)
            }
            catch (err) {
                console.log(err)
            }
        }
        fetchCategorie();
    }, []);
    // on change categorie
    const handleChangeCategorie = (event) => {
        event.preventDefault();
        const dataValue = event.currentTarget.getAttribute("data-value");
        const dataName = event.currentTarget.getAttribute("name");

        sortSelectSousCategorie(dataValue);

        setSelectsValues(prevState => prevState.set("selectCategorie", dataValue))
        setSelectsValues(prevState => prevState.set("selectCategorieName", dataName))

        // sorting
        sortEntries(selectsValues);
    };

    //data sous categorie
    const [sousCategorie, setSousCategorie] = useState([]);
    useEffect(() => {
        const fetchSousCategorie = async () => {
            try {
                const dataSousCategorie = await serviceApi.findAllSousCategorie();
                let SousCategorieList = [];
                dataSousCategorie.forEach(element => {
                    SousCategorieList.push({
                        value: element.id,
                        name: element.libelleSousCategorie,
                        id_categorie: element.idCategorie,
                        id: element.id
                    });
                });
                setSousCategorie(SousCategorieList)
            }
            catch (err) {
                console.log(err)
            }
        };
        fetchSousCategorie();
    }, []);
    //on change sousCategorie
    const handleChangeSousCategorie = (event) => {
        const dataValue = event.currentTarget.getAttribute("data-value");
        const dataName = event.currentTarget.getAttribute("name");

        setSelectsValues(prevState => prevState.set("selectSousCategorie", dataValue))
        setSelectsValues(prevState => prevState.set("selectSousCategorieName", dataName))
        sortEntries(selectsValues);

    };

    //checkboxes

    const [lectureAllChecked, setLectureAllChecked] = useState(new Map());
    const [ecritureAllChecked, setEcritureAllChecked] = useState(new Map());
    const [suppressionAllChecked, setSuppressionAllChecked] = useState(new Map());

    const toggleCheckboxes = async (event) => {
        event.stopPropagation()
        const data = saveEntries.get("saveEntries")
        try {
            //animation
            setChargement({
                isLoading: true
            });

            await rightApi.toggleRight(data, event.target.checked,event.target.name);

            setChargement({
                isLoading: false
            });

            //reload         
            sortEntries(selectsValues);

        } catch (err) {
            console.log(err);
        }
    }

    // by cell
    const handleChangeLecture = async (event, rowdata) => {
        event.stopPropagation()
        try {
            //animation
            setChargement({
                isLoading: true
            });

            await rightApi.toggleLecture(rowdata, event.target.checked);

            setChargement({
                isLoading: false
            });

            //reload         
            sortEntries(selectsValues);

        } catch (err) {
            console.log(err)
        }
    };

    const handleChangeEcriture = async (event, rowdata) => {
        event.stopPropagation()
        try {
            //animation
            setChargement({
                isLoading: true
            });

            const response = await rightApi.toggleEcriture(rowdata, event.target.checked);
            
            setChargement({
                isLoading: false
            });

            //reload          
            sortEntries(selectsValues);

        } catch (err) {
            console.log(err)
        }
    };

    const handleChangeSuppression = async (event, rowdata) => {
        event.stopPropagation()
        try {
            //animation
            setChargement({
                isLoading: true
            });

            await rightApi.toggleSuppression(rowdata, event.target.checked);

            setChargement({
                isLoading: false
            });

            //reload        
            sortEntries(selectsValues);

        } catch (err) {
            console.log(err)
        }
    };

    //columns definition
    const [state, setState] = useState({});

    return (
        <div className={classes.page}>
            <Grid container alignItems="center" className={classes.root}>
                <FormControl className={classes.formControl}>
                    <InputLabel id="user-simple-select-autowidth-label">Utilisateur</InputLabel>
                    <Select
                        labelId="select-users-required-label"
                        id="select-users-required"
                        value={userSelect.id}
                        defaultValue={userSelect.id ? userSelect.id : ""}
                        autoWidth
                        onChange={handleChangeResponsable}
                        className={classes.selectEmpty}
                    >
                        {userSelect.map((user, index) => (
                            <MenuItem key={index} value={user.id} name={user.email} className={classes.selectColor}>{user.email}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Divider
                    orientation="vertical"
                    flexItem
                    light
                />
                <FormControl className={classes.formControl}>
                    <InputLabel id="categorie-simple-select-autowidth-label" style={{ visibility: selectVisibility }} >Thème</InputLabel>
                    <Select
                        labelId="categorie-simple-select-autowidth-label"
                        id="categorie-simple-select-autowidth"
                        value={categorie.value}
                        defaultValue="_all"
                        onChange={handleChangeCategorie}
                        autoWidth
                        style={{ visibility: selectVisibility }}
                    >
                        <MenuItem value="_all" ><em>Toutes</em></MenuItem>
                        {categorie.map((cat) => (
                            <MenuItem key={cat.id} value={cat.value} name={cat.name}>{cat.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel id="ssCategorie-simple-select-autowidth-label" style={{ visibility: selectVisibility }}>Sous-thème </InputLabel>
                    <Select
                        labelId="ssCategorie-simple-select-autowidth-label"
                        id="ssCategorie-simple-select-autowidth"
                        value={sousCategorie.value}
                        defaultValue="_all"
                        onChange={handleChangeSousCategorie}
                        autoWidth
                        style={{ visibility: selectVisibility }}
                    >
                        <MenuItem value="_all"><em>Toutes</em></MenuItem>
                        {sousCategorie.map((sscat) => (
                            <MenuItem key={sscat.id} value={sscat.value} name={sscat.name}>{sscat.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <div className={classes.table}>
                <MaterialTable
                    title={'Gestion des droits d\'accès utilisateur/type indicateurs'.toUpperCase()}
                    columns={state.columns}
                    data={entries}
                    isLoading={chargement.isLoading}
                    components={{
                        Toolbar: props => (
                            <div className={classes.toolbarDiv}>
                                <MTableToolbar {...props} className={classes.toolbar} />
                            </div>
                        ),
                    }}
                    icons={{
                        Add: props => <AddCircleRounded {...props} className={classes.add} />,
                        Edit: props => <Edit  {...props} className={classes.edit} />,
                        Delete: props => <DeleteForeverIcon   {...props} className={classes.delete} />,
                        Check: props => <Check   {...props} className={classes.check} />,
                        Clear: props => <Clear    {...props} className={classes.clear} />,
                        ThirdStateCheck: props => <RemoveIcon  {...props} className={classes.thirdStateCheck} />,
                    }}
                    options={{
                        grouping: true,
                        rowStyle: {
                            backgroundColor: theme.color.white,
                        },
                        headerStyle: {
                            backgroundColor: theme.color.labelNavbarBackgroundColor,
                            color: theme.color.labelNavbarColor,
                            textAlign: 'center',
                        },
                        paging: false, // disable pagination !
                        tableLayout: "fixed", //columns dimensions
                        //actionsColumnIndex: -1, //action column o right side
                        addRowPosition: 'first', //add row on top
                    }}
                    style={{
                        color: theme.color.dataColor,
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: "{from} à {to} sur {count}",
                            labelRowsSelect: "résultats par page",
                            labelRowsPerPage: "résultats par page",
                            firstAriaLabel: "Première page",
                            firstTooltip: "Première page",
                            previousAriaLabel: "Page précédente",
                            previousTooltip: "Page précédente",
                            nextAriaLabel: "Page suivante",
                            nextTooltip: "Page suivante",
                            lastAriaLabel: "Dernière page",
                            lastTooltip: "Dernière page"
                        },
                        toolbar: {
                            addRemoveColumns: "Ajouter ou supprimer des colonnes",
                            nRowsSelected: "{0} ligne(s) sélectionnée(s)",
                            showColumnsTitle: "Afficher les colonnes",
                            showColumnsAriaLabel: "Afficher les colonnes",
                            exportTitle: "Exporter",
                            exportAriaLabel: "Exporter",
                            exportName: "Exporter en CSV",
                            searchTooltip: "Recherche",
                            searchPlaceholder: "Recherche"
                        },
                        header: {
                            actions: ''
                        },
                        body: {
                            emptyDataSourceMessage:
                                "Désolé, aucun enregistrement correspondant n'a été trouvé",
                            addTooltip: "Ajouter une ligne",
                            deleteTooltip: "Supprimer l'indicateur",
                            editTooltip: "Modifier l'indicateur",
                            filterRow: {
                                filterTooltip: "Filtrer"
                            },
                            editRow: {
                                deleteText: "Êtes-vous sûr de vouloir supprimer cet élément ? Vous allez perdre TOUTES les données associées",
                                cancelTooltip: "Annuler",
                                saveTooltip: "Sauvegarder"
                            }
                        },
                    }}
                />
            </div>
        </div>


    )
}

export default RightsManagement;