import axios from "axios";
import {USERS_API,DELETE_USERS_API,USERS_FILTERED_API} from "../config.js";

function findAllUsers(){
    return axios
        .get(USERS_API)
        .then(response => response.data['hydra:member']);
}

function getFilteredUsers(){
    //return users who are not ROLE_Admin
    return axios
    .get(USERS_FILTERED_API)
    .then(response => response.data);
    
}
function findById(id){
    return axios
    .get(USERS_API + "/" + id)
    .then(response => response.data);
}

function postUser(param){
    return axios
        .post(USERS_API, param)
}

function changeOneUser(param1, param2){
    return axios
        .put(USERS_API + "/" + param1, param2)
}

function deleteUser(param) {
    return axios
        .delete(USERS_API + "/" + param)
}

function deleteUserFromController(param) { 
    return axios
        .delete(DELETE_USERS_API + param)
}

export default {
    findAllUsers,
    getFilteredUsers,
    findById,
    changeOneUser,
    postUser,
    deleteUser,
    deleteUserFromController
}