import React, { useState, useEffect, useContext } from 'react';
//material-ui
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
//swal
import Swal from 'sweetalert2';
//query
import serviceApi from '../services/ServiceApi';
import { ThemeContext } from '../contexts/ThemeContext';

//style
const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    loader:{
        marginTop: theme.spacing(3),
        width: '80%',
        margin: 'auto',
        textAlign:'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: props => props.color.labelNavbarBackgroundColor,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: props => props.color.connectionButtonBackgroundColor,
        color: props => props.color.connectionButtonColor,
        "&:hover": {
            backgroundColor: props => props.color.connectionButtonBackgroundColorHover
        }
    },
    selectEmpty: {
        marginTop: theme.spacing(2),

    },
    close:{
        color: '#f44336',
    }

}));

const ModalResponsable = ({ open, onClose, ShowResponsableModal, refreshParent }) => {


    //styling
    const theme = useContext(ThemeContext);

    const classes = useStyles(theme);

    //show/hide
    const [showResponsable, setShowResponsable] = useState({});
    const [showSelect, setShowSelect] = useState({})
    //responsable stored
    const [responsableStored, setResponsableStored] = useState({});
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        const fetchResponsable = async (param) => {
            try {
                setLoader(true);
                const dataTypeIndicateur = await serviceApi.findTypeIndicateurById(param);
                
                if (dataTypeIndicateur.idResponsable !== undefined) {
                    setResponsableStored({
                        name: dataTypeIndicateur.idResponsable.nomResponsable,
                        value: dataTypeIndicateur.idResponsable.nomResponsable,
                        id: dataTypeIndicateur.idResponsable.idResponsable
                    });
                    setShowResponsable({ 'visibility': 'visible' });
                    setShowSelect({ 'display': 'none' })
                }else{
                    setResponsableStored({});
                    setShowResponsable({ 'visibility': 'hidden' });
                    setShowSelect({ 'display': 'initial' });
                }

            } catch (err) {
                console.log(err)
            }
            setLoader(false);
        };

        if (undefined !== ShowResponsableModal.id) {
            fetchResponsable(ShowResponsableModal.id);
        }

    }, [open]);

    //Responsables list
    const [responsable, setResponsable] = useState([]);

    useEffect(() => {
        const fetchResponsables = async () => {
            try {
                const dataResponsable = await serviceApi.findAllResponsable();
                let responsableSelectList = [];
                dataResponsable.forEach(element => {
                    responsableSelectList.push({
                        value: element.idResponsable,
                        name: element.nomResponsable,
                        id: element.nomResponsable + element.idResponsable
                    });
                });
                setResponsable(responsableSelectList);

            } catch (err) {
                console.log(err);
            }
        }
        fetchResponsables(ShowResponsableModal)
    }, [open]);



    //delete
    const unsetResponsable = async () => {
        try {
            await serviceApi.removeTypeIndicateurResponsable(ShowResponsableModal.id);
            refreshParent();
            Swal.fire(
                'Supprimé !',
                '',
                'success'
            )
        } catch (err) {
            console.log(err);
        }
    }
    const deleteResponsable = () => {

        onClose(); //close previous modal
        Swal.fire({
            title: 'Êtes-vous sûr ?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1c60A3',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprimer !',
            cancelButtonText: 'Non  '
        }).then((result) => {
            if (result.value) {
                unsetResponsable();
            }
        })
    }
    //submit
    const [responsableSelect, setResponsableSelect] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await serviceApi.addTypeIndicateurResponsable(responsableSelect, ShowResponsableModal.id);
            refreshParent();
            onClose();
        } catch (error) {
            console.log(error);
        }
    }



    return (
        <div>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-modalResponsable"
                aria-describedby="alert-dialog-slide-modalResponsable"
                maxWidth="sm"
                fullWidth
                id="modalResponsable"

            >
                <Paper elevation={0} >
                {loader === true ? 
                    <DialogContent className={classes.loader}>
                        <CircularProgress />
                        <Typography component="p" variant="subtitle1" align="center" color="inherit">
                            Chargement
                        </Typography>
                    </DialogContent> 
                    : 
                    <DialogContent>
                        <Container component="main" maxWidth="sm">
                            <CssBaseline />
                            <div className={classes.paper}>
                                <Avatar className={classes.avatar}>
                                    <PeopleAltIcon />
                                </Avatar>
                                <Typography component="h1" variant="h5">
                                    {"Associer un référent".toUpperCase()}
                                </Typography >
                                <form className={classes.form}
                                    noValidate
                                    style={{ minWidth: 120 }}
                                    onSubmit={event => handleSubmit(event)}
                                    autoComplete="off"
                                >
                                    {responsableStored.name !== undefined ? 
                                    <List component="div" aria-label="liste responsable" >
                                        <Tooltip title="Cliquez pour supprimer l'association">
                                            <ListItem
                                                button
                                                onClick={() => { deleteResponsable() }}
                                                style={showResponsable}
                                            >
                                                    <ListItemIcon>
                                                        <DeleteIcon className={classes.close}/>
                                                    </ListItemIcon>
                                                <ListItemText primary={responsableStored.name.toUpperCase()} />
                                            </ListItem>
                                        </Tooltip>
                                    </List>
                                    : null }
                                    {responsable.length > 0 ? null :
                                        <Typography component="p" variant="subtitle1" color="error" align="center">
                                            Aucun référent
                                        </Typography>
                                    }
                                    <FormControl className={classes.form} >
                                        <InputLabel style={showSelect} shrink={true} id="select-responsable-required-label">Selectionner</InputLabel>
                                        <Select
                                            style={showSelect}
                                            variant="outlined"
                                            labelId="select-responsable-required-label"
                                            id="select-responsable-required"
                                            value={responsable.name}
                                            onChange={(e) => {
                                                setResponsableSelect(e.currentTarget.getAttribute('id'));
                                            }}
                                            className={classes.selectEmpty}
                                            disabled={responsable.length > 0 ? false : true }
                                        >
                                            {responsable.map((resp) => (
                                                <MenuItem disableGutters={false} key={resp.id} value={resp.name} id={resp.value} name={resp.name}>{resp.name.toUpperCase()}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        style={showSelect}
                                        disabled= {responsable.length > 0 ? false : true }
                                    >
                                        Valider
                                    </Button>
                                </form>
                            </div>
                        </Container>
                    </DialogContent>

                }    
                    <DialogActions disableSpacing >
                        <Button onClick={onClose} className={classes.close} autoFocus>
                            Fermer
                        </Button>
                    </DialogActions>
                </Paper>
            </Dialog>
        </div>
    )
}
export default ModalResponsable;
