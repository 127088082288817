import React, { useState, useEffect } from 'react';
import { HashRouter, Route, Switch, withRouter, Redirect } from 'react-router-dom';

import './App.css';

import Accueil from './templates/accueil';
import Objectifs from './templates/objectifs';
import Indicateurs from './templates/indicateurs';
import Administration from './templates/administration';
import TableauDeBord from './templates/tableaudebord';
import Connexion from './templates/connexion';
import OublieMdp from './templates/oublieMdp';
import PrivateRoute from '../src/components/principal/privateRoute';
import AuthAPI from './services/AuthAPI';
import AuthContext from './contexts/AuthContext';

import { themes, ThemeContext } from './contexts/ThemeContext';
import themeService from "./services/ThemeApi";

import Navbar from './components/principal/navbar';
//to Fix scroll top
import ScrollIntoView from "./components/principal/ScrollIntoView";

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    height: "100vh",
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));


AuthAPI.setup();


function App() {
  const classes = useStyles();

  const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#757ce8',
        main: '#3f50b5',
        dark: '#002884',
        contrastText: '#fff',
      },
    },
  });

  const NavbarWithRouter = withRouter(Navbar);
  const [isAuthenticated, setIsAuthenticated] = useState(AuthAPI.isAuthenticated());
  const [access, setAccess] = useState();
  const contextValue = { isAuthenticated, setIsAuthenticated };

  const [customTheme, setCustomTheme] = useState();


  useEffect(() => {
    
    setAccess(AuthAPI.getDecodedToken());

    let completeTheme;
    async function callTheme() {
      await themeService.getThemeById()
        .then(response => {
          if (response !== undefined) {
            completeTheme = response;
            themeService.getWysiwygById()
              .then(resp => {
                completeTheme.wysiwyg = resp;
                setCustomTheme(completeTheme);
              })
          } else {
            setCustomTheme(themes.default);
          }
        });
    }
    callTheme();
  }, [isAuthenticated]);


  return (customTheme !== undefined) ? (
    <AuthContext.Provider value={contextValue}>
      <ThemeContext.Provider value={customTheme}>
        <HashRouter>
          {isAuthenticated ? <NavbarWithRouter /> : null}
          <Box>
            <MuiThemeProvider theme={theme}>
              <ScrollIntoView>
                <Switch>
                  <Route exact path="/connexion" component={Connexion} />
                  <Route exact path="/motdepasseoublie" component={OublieMdp} />
                  <PrivateRoute exact path="/accueil" component={Accueil} />
                  <PrivateRoute exact path="/objectifs" component={Objectifs} />
                  <PrivateRoute exact path="/objectifs/:idCategorie/:idSousCategorie" component={Objectifs} />
                  <PrivateRoute exact path="/indicateurs" component={Indicateurs} />
                  <PrivateRoute exact path="/tableaudebord" component={TableauDeBord} />
                  {access ?
                    <PrivateRoute exact path="/administration" component={Administration} />
                    :
                    <Redirect to="/accueil" />
                  }
                  <Redirect to="/accueil" />
                </Switch>
              </ScrollIntoView>
            </MuiThemeProvider>
          </Box>
        </HashRouter>
      </ThemeContext.Provider>
    </AuthContext.Provider>
  ) : (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  )
}

export default App;
