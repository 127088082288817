import React, { useState, useEffect, useContext } from 'react';
//material-ui
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
//query
import serviceApi from '../services/ServiceApi';
import { ThemeContext } from '../contexts/ThemeContext';



//style
const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: props => props.color.connectionButtonBackgroundColor,
        color: props => props.color.connectionButtonColor,
        "&:hover": {
            backgroundColor: props => props.color.connectionButtonBackgroundColorHover
        }
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        minWidth: 100,
    },

}));


const ModalObjectif = ({ open, onClose, ShowObjectifModal, refreshParent }) => {

    //styling
    const theme = useContext(ThemeContext);
    const classes = useStyles(theme);
    //select frequences
    const [frequence, setFrequence] = useState([]);
    const FrequenceOptions = {};
    let datesList = [];
    useEffect(() => {
        const fetchDates = async () => {
            try {
                const dataFrequence = await serviceApi.findAllDates(); //query
                dataFrequence.forEach(element => {
                    datesList.push({
                        id: element.id,
                        typeDate: element.libelle,
                    });
                });
                datesList.map(dates => {
                    const { id, typeDate } = dates;
                    FrequenceOptions[typeDate] = typeDate;
                });
                setFrequence(datesList);
            }
            catch (err) {
                console.log(err)
            }
        };
        fetchDates();
    }, [open]);
    //select Unité
    const [unite, setUnite] = useState([]);
    const UniteOptions = {};
    let uniteList = [];
    useEffect(() => {
        const fetchUnites = async () => {
            try {
                const dataUnite = await serviceApi.findAllUnites(); //query

                dataUnite.forEach(element => {
                    uniteList.push({
                        id: element.id,
                        typeUnite: element.libelleUnite,
                    });
                });

                uniteList.map(unite => {
                    const { id, typeUnite } = unite;
                    UniteOptions[typeUnite] = typeUnite;
                })
                setUnite(uniteList);

            }
            catch (err) {
                console.log(err)
            }
        };
        fetchUnites();
    }, [open]);

    //form
    const [errorLibelleObj, setErrorLibelleObj] = useState(false);
    const [errorFrequenceValue, setErrorFrequenceValue] = useState(false);
    const [errorUniteValue, setErrorUniteValue] = useState(false);
    const [libelleObj, setLibelleObj] = useState('');
    const [frequenceValueId, setFrequenceValue] = useState('');
    const [uniteValueID, setUniteValue] = useState('');
    const [commentaireValue, setCommentaireValue] = useState('');

    useEffect(() => {
        //reset error
        setErrorLibelleObj(false);
        setErrorFrequenceValue(false);
        setErrorUniteValue(false);
        //reset value
        setLibelleObj('');
        setFrequenceValue('');
        setCommentaireValue('');
    }, [open]);


    // add type objectif query 
    const addTypeObjectif = async (param) => {
        try {
            await serviceApi.createTypeObjectif(param);
            refreshParent();
            onClose(); //close modal 
        } catch (error) {
            console.log(error);
        }
    }
    //submit
    const handleSubmitObjectif = (event) => {
        event.preventDefault();
        libelleObj === "" ? setErrorLibelleObj(true) : setErrorLibelleObj(false);
        frequenceValueId === "" ? setErrorFrequenceValue(true) : setErrorFrequenceValue(false);
        uniteValueID === "" ? setErrorUniteValue(true) : setErrorUniteValue(false);

        if (libelleObj !== "" && frequenceValueId !== "" && uniteValueID !== "") {
            const UriIndicateur = "/api/type_indicateurs/" + ShowObjectifModal.id;
            const UriTypeDate = "/api/type_dates/" + frequenceValueId;
            const UriUnite = "/api/type_unites/" + uniteValueID;
            const data = {
                idTypeIndicateur: UriIndicateur,
                libelle: libelleObj,
                typeDate: UriTypeDate,
                typeUnite: UriUnite,
                commentaire: commentaireValue
            }
            addTypeObjectif(data);
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="terms-dialog-modalObjectifs"
            aria-describedby="terms-dialog-objectifs"
            id="modalObjectifs"
        >
            <DialogContent>
                <Container component="main" maxWidth="sm">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Typography component="h1" variant="h5" >
                            DÉFINITION D'UN TYPE D'OBJECTIF
                        </Typography>
                        <form className={classes.form}
                            noValidate
                            style={{ minWidth: 120 }}
                            onSubmit={event => handleSubmitObjectif(event)}
                            autoComplete="off"
                        >
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="libelleObj"
                                label="Libellé de l'objectif"
                                name="libelle"
                                autoFocus
                                value={libelleObj}
                                onInput={e => {
                                    setLibelleObj(e.target.value);
                                }}
                                error={errorLibelleObj}

                            />
                            <FormControl className={classes.form}>
                                <Select
                                    variant="outlined"
                                    labelId="select-frequence-required-label"
                                    id="select-frequence-required"
                                    value={frequence.typeDate}
                                    defaultValue={""}
                                    onChange={(e) => {
                                        setFrequenceValue(e.currentTarget.getAttribute('id'));
                                    }}
                                    className={classes.selectEmpty}

                                >
                                    {frequence.map((frq) => (
                                        <MenuItem key={frq.id} value={frq.typeDate} id={frq.id} name={frq.typeDate}>{frq.typeDate}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText error={errorFrequenceValue} >Fréquence de mise à jour*</FormHelperText>
                            </FormControl>
                            <FormControl className={classes.form}>
                                <Select
                                    variant="outlined"
                                    labelId="select-unite-required-label"
                                    id="select-unite-required"
                                    value={unite.typeUnite}
                                    onChange={(e) => {
                                        setUniteValue(e.currentTarget.getAttribute('id'));
                                    }}
                                    className={classes.selectEmpty}
                                    defaultValue={""}

                                >
                                    {unite.map((u) => (
                                        <MenuItem key={u.id} value={u.typeUnite} id={u.id} name={u.typeUnite}>{u.typeUnite}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText error={errorUniteValue} >Unité*</FormHelperText>
                            </FormControl>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="commentaireObj"
                                label="Commentaire"
                                name="commentaire"
                                autoFocus
                                value={commentaireValue}
                                onInput={e => setCommentaireValue(e.target.value)}

                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                className={classes.submit}
                            >
                                Valider
                            </Button>
                        </form>
                    </div>
                </Container>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" autoFocus>
                    Annuler
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default ModalObjectif;