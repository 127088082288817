import React, { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Typography,
    Paper,
    Tabs,
    Tab
} from '@material-ui/core';

import jwtDecode from "jwt-decode";

import Account from './account.js';
import RightsManagement from './rightsmanagement.js';
import ManageableLists from './manageablelists.js';
import CustomSiteForm from './personalize/customSiteForm.js';

import { ThemeContext } from '../contexts/ThemeContext.js';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    titleText: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '25px',
        color: props => props.color.pageHeaderColor,
        textTransform: "uppercase",
    },
    headerBorder: {
        borderColor: props => props.color.pageHeaderColor,
    },
    page: {
        marginTop: '50px',
    }
}));

const Administration = () => {
    const theme = useContext(ThemeContext);
    const classes = useStyles(theme);

    const [value, setValue] = useState(0);
    const [roleSuperAdmin, setRoleSuperAdmin] = useState(false);
    const [roleDesigner, setRoleDesigner] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        const getDecodedToken = () => {
            const tokenBrut = window.localStorage.getItem("authToken");
            if (tokenBrut) {
                const decodedToken = jwtDecode(tokenBrut);
                if (decodedToken) {
                    (decodedToken.roles[0] === "ROLE_SUPER_ADMIN" || decodedToken.roles[0] === "ROLE_ADMIN") ? setRoleSuperAdmin(true) : setRoleSuperAdmin(false);
                    (decodedToken.roles[0] === 'ROLE_DESIGNER') ? setRoleDesigner(true) : setRoleDesigner(false);
                }
            }
        }
        getDecodedToken();
    }, []);



    return (
        <>
            <div className="corners">
                <div className={`top left ${classes.headerBorder}`}></div>
                <div className={`top right ${classes.headerBorder}`}></div>
                <div className={`bottom right ${classes.headerBorder}`}></div>
                <div className={`bottom left ${classes.headerBorder}`}></div>
                <Typography variant="h4" align='center' className={classes.titleText}>Administration</Typography >
            </div>
            <div className={classes.page}>
                <Grid container spacing={2} justify="flex-start">
                    <Grid item xs={12} lg={12}>
                        <Paper className={classes.root}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                            >
                                <Tab label="Gestion des droits" />
                                <Tab label="Gestion des comptes" />
                                {roleSuperAdmin === true || roleDesigner === true ? <Tab label="Listes administrables" /> : null}
                                {roleDesigner === true ? <Tab label="Personnalisez le site" /> : null}
                            </Tabs>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        {roleDesigner ? (
                            <>
                                {value === 1 && <Account />}
                                {value === 2 && <ManageableLists />}
                                {value === 0 && <RightsManagement />}
                                {value === 3 && <CustomSiteForm />}
                            </>
                        ) : (
                            <>
                                {value === 1 && <Account />}
                                {value === 2 && <ManageableLists />}
                                {value === 0 && <RightsManagement />}
                            </>
                        )}
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default Administration;
