import React, { useState, useEffect } from 'react';
//material-ui
import { makeStyles } from '@material-ui/core/styles';
//modal alert
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { TextField } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import SaveIcon from '@material-ui/icons/Save';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
//autocomplete
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
//query
import serviceApi from '../services/ServiceApi';



//style
const useStyles = makeStyles(theme => ({
    formControl: {
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    container: {
        marginLeft: "1vw",
        marginRight: "1vw",
        padding:"10px",
    },
    margin: {
        margin: theme.spacing(1),
    },
}));


const ModalIndicateurs = ({ open, onClose, ShowIndicateurModal }) => {
  

    //styling
    const classes = useStyles();   
    const [saveColor,setSaveColor] = useState({fill: "#1976d2",fontSize: 30})

    const [flagIndicateurValuesList, setFlagIndicateurValuesList] = useState([]);
    useEffect(() => {
        const fetchFlagIndicateurValues = async () => {
            try { 
                const flagIndicValues = await serviceApi.findAllFlagIndicateurValues();
                let list = [];
                let listFiltered = [];
                let allValues = [];

                flagIndicValues.forEach(element => {
                    allValues.push(element.valeurFlag.trim().toLowerCase());
                });
                //to keep only unique values 
                listFiltered = Array.from(new Set(allValues));

                listFiltered.forEach(element => {
                    list.push({
                        value: null,
                        name: element,
                        id: null
                    });
                });
                setFlagIndicateurValuesList(list);
            }catch(err) { 
                console.log(err) 
            }
        }
        fetchFlagIndicateurValues();
    },[])
    //modal
    const [libelleFlag, setLibelleFlag] = useState([]);
    const [refreshLibelle, setRefreshLibelle] = useState(false);

    const [typeFlagSelect, setTypeFlagSelect] = useState([]); 

    const [displayForm, setDisplayForm] = useState("initial");

    useEffect(() => {
        const fetchAssocFlagIndicateur = async (param) => {
            setTypeFlagSelect([]);
            try {
                const dataAssocFlagIndicateur = await serviceApi.sortAssocFlagIndicateurByIdIndic(param);
                let AssocFlagIndicateurListB = [];

                //object used by default in the select
                let AssocFlagIndicateurListTypeflag = [];                
                dataAssocFlagIndicateur.forEach(element =>{
                    AssocFlagIndicateurListTypeflag.push({
                        value: element.idtypeFlag.libelle.toUpperCase(),
                        id : element.idtypeFlag.idtypeFlag,
                        key:element.idtypeFlag.libelle + element.idtypeFlag.idtypeFlag,
                        idAssocFlagIndicateur: element.idassocFlagIndicateur,
                    })
                });
                //flag values
                const dataFlagIndicateurValue = await serviceApi.sortflagIndicValueByIdIndic(ShowIndicateurModal.id);
                
                // display libellé
                AssocFlagIndicateurListTypeflag.forEach(element => {
                    dataFlagIndicateurValue.forEach(val => {

                        if (element.idAssocFlagIndicateur === val.idassocFlagIndicateur ) {
                            AssocFlagIndicateurListB.push({
                                value : element.value,
                                idtypeFlag : element.id,
                                key : element.key,
                                idAssocFlagIndicateur: element.idAssocFlagIndicateur,        
                                idIndicateur: val.idIndicateur.id,
                                idflagIndicateurValue : val.idflagIndicateurValue,
                                valeurFlag: val.valeurFlag,
                                
                            })
                        }
                    });                    
                })

                setLibelleFlag(AssocFlagIndicateurListB);

                // set the select 
                const dataTypeFlagSelect = await serviceApi.sortAssocFlagIndicateurByIdIndic(param)
                let TypeFlagSelectList = [];
                let TypeFlagSelectListB = [];
                dataTypeFlagSelect.forEach(element => {
                    TypeFlagSelectList.push({
                        value: element.idtypeFlag.idtypeFlag,
                        name: element.idtypeFlag.libelle,
                        id: element.idtypeFlag.idtypeFlag,
                        key: "flag"+element.idtypeFlag.idtypeFlag
                    });
                });

                for (let i = 0; i < TypeFlagSelectList.length; i++) {
                    TypeFlagSelectListB.push(TypeFlagSelectList[i]);
                    for (let j = 0; j < AssocFlagIndicateurListB.length; j++) {
                        if (TypeFlagSelectList[i]['id'] === AssocFlagIndicateurListB[j]['idtypeFlag']) {
                            TypeFlagSelectListB.pop(TypeFlagSelectList[i]);
                        }
                    }
                }
                
                setTypeFlagSelect(TypeFlagSelectListB);
                TypeFlagSelectListB.length === 0 ? setDisplayForm("none") : setDisplayForm("initial") ;

            }catch(error){
                console.log(error)
            }
            
        };
        
        if (ShowIndicateurModal.typeIndicateurId !== undefined) {
            
            fetchAssocFlagIndicateur(ShowIndicateurModal.typeIndicateurId);
        }
    },[ open,ShowIndicateurModal,refreshLibelle]);
    
    //indicateur
    const [indicateur, setIndicateur] = useState([]);
    useEffect(() => {
        const fetchTitles = async (param) => {
            try{
                const dataIndicateur = await serviceApi.findTypeIndicateurById(param)
                setIndicateur(dataIndicateur);
            }catch(err){
                console.log(err)
            }
        }
        if (ShowIndicateurModal.typeIndicateurId !== undefined) {
            fetchTitles(ShowIndicateurModal.typeIndicateurId);
        }
    },[ShowIndicateurModal.typeIndicateurId])


    //autocomplete
    const filter = createFilterOptions();
    const [value, setValue] = React.useState(null);
    //on change flag
    const [flagValue, setFlagValue] = useState('');
    const handleChangeFlagIndicValue = async (event) => {
        event.preventDefault();        
        const typeIndicId = ShowIndicateurModal.typeIndicateurId;
        const indicId = ShowIndicateurModal.id;    
        setSaveColor({fill: "#4caf50",fontSize: 30})    

        try {
            await serviceApi.createTypeFlagIndicValue(flagValue,typeIndicId,value,indicId);
            
            setRefreshLibelle(!refreshLibelle);
            setSaveColor({fill: "#1976d2",fontSize: 30})
        }catch(error){
            console.log(error);
        }
    }
    //delete
    const deleteFlag = async (param) => {
        try {
            await serviceApi.deleteFlagIndicateurValue(param.idflagIndicateurValue);
            setRefreshLibelle(!refreshLibelle);

        }catch(error){
            console.log(error)
        }
    }

    //render
    return (
        <div>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-modalCategorie"
                aria-describedby="alert-dialog-slide-modalCategorie"
                maxWidth="md"
                fullWidth
                id="modalCategorie"

            >
                <Paper elevation={0} >
                    <DialogTitle style={{"textAlign":"center"}} id="alert-dialog-title">{"Catégoriser".toUpperCase()}</DialogTitle>
                    <DialogContent>
                        <Typography component="div" align={'center'} id="alert-dialog-description">
                        {indicateur.libelle ? <Typography variant="subtitle1" component="p">Indicateur concerné : {indicateur.libelle}</Typography>  : ""}
                        </Typography>
                    </DialogContent>
                        <Box
                            className={classes.container}
                        >
                            <Grid 
                            container
                            direction="row"
                            alignItems="baseline"
                            justify="center"                    
                            spacing={3}
                            >  
                                    {libelleFlag.map((flag,i) => (
                                        <React.Fragment key={i}>
                                        <Grid item xs={2} sm={2} >
                                            <Box  >
                                            <InputLabel >Libellé :</InputLabel>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={3} sm={3}  >
                                            <Box key={i} >
                                                    <Typography
                                                        display="inline"
                                                        variant="body1" 
                                                        component="p"
                                                        gutterBottom
                                                        
                                                    >        
                                                        {flag.value}
                                                    </Typography  >    
                                            </Box>
                                        </Grid>
                                        <Grid item xs={3} sm={3}  >
                                            <Box >
                                                    <Typography
                                                        display="inline"
                                                        variant="body1" 
                                                        component="p"
                                                        gutterBottom
                                                        
                                                    >        
                                                        {flag.valeurFlag}
                                                    </Typography  >    
                                            </Box>
                                        </Grid>
                                        <Grid item xs={3} sm={3}  >
                                            <Tooltip title="delete" aria-label="del-indicateur">
                                                <IconButton  
                                                    color="secondary" 
                                                    size="medium"
                                                    edge="end"
                                                    value={flag.id}
                                                    onClick={() => { deleteFlag(flag); }} 
                                                    key={i}
                                                >
                                                    <DeleteOutlineIcon />
                                                </IconButton >
                                            </Tooltip>
                                        </Grid> 
                                        </React.Fragment>
                                    ))}
                            </Grid>  
                            {typeFlagSelect.length > 0 ? 
                            <form  id="flag" className={classes.formControl} onSubmit={event => handleChangeFlagIndicValue(event)} style={{display : displayForm}}>
                                <Grid 
                                    container
                                    direction="row"
                                    justify="space-around"
                                    alignItems="center"
                                    spacing={1}
                                >

                                    <Grid item xs  >
                                        <Select
                                            labelId="select-flag-required-label"
                                            id="select-flag-required"
                                            defaultValue="_all"
                                            value={typeFlagSelect.value}
                                            autoWidth
                                            className={classes.selectEmpty}   
                                            onChange={(e) => setFlagValue(e.target.value)}
                                            variant="outlined"
                                        >
                                            <MenuItem value="_all" ><em>Ajouter une catégorie</em></MenuItem>
                                            {typeFlagSelect.map((flag) => (
                                                <MenuItem key={flag.key} value={flag.value} name={flag.name}>{flag.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid item xs  >
                                        <Autocomplete
                                            className={classes.selectEmpty} 
                                            value={value}
                                            onChange={(event, newValue) => {
                                                if (typeof newValue === 'string') {
                                                    setValue({
                                                        name: newValue,
                                                    });
                                                } else if (newValue && newValue.inputValue) {
                                                    // Create a new value from the user input
                                                    setValue({
                                                        name: newValue.inputValue,
                                                    });
                                                } else {
                                                    setValue(newValue);
                                                }
                                            }}
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);

                                                // Suggest the creation of a new value
                                                if (params.inputValue !== '') {
                                                    filtered.push({
                                                        inputValue: params.inputValue,
                                                        name: `Ajouter "${params.inputValue}"`,
                                                    });
                                                }

                                                return filtered;
                                            }}
                                            selectOnFocus
                                            clearOnBlur
                                            handleHomeEndKeys
                                            id="free-solo-with-text"
                                            options={flagIndicateurValuesList}
                                            getOptionLabel={(option) => {
                                                // Value selected with enter, right from the input
                                                if (typeof option === 'string') {
                                                    return option;
                                                }
                                                // Add "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.inputValue;
                                                }
                                                // Regular option
                                                return option.name;
                                            }}
                                            renderOption={(option) => option.name}
                                            /* style={{ width: 300 }} */
                                            freeSolo
                                            renderInput={(params) => (
                                                <TextField {...params} label="flag" variant="outlined" />
                                            )}
                                        />                                                 
                                    </Grid>
                                    <Grid item xs   >
                                        <Tooltip title="valider" aria-label="add-cat">
                                            <IconButton  
                                                type="submit" 
                                                color="primary" 
                                                size="medium"
                                                edge="end"
                                            >
                                                <SaveIcon style={saveColor} /> 
                                            </IconButton >
                                        </Tooltip>
                                    </Grid>   
                                </Grid>
                       
                            </form>   
                            : null}  
                        </Box>

                    <DialogActions disableSpacing >
                        <Button onClick={onClose} color="secondary" autoFocus>
                            Fermer
                        </Button>
                    </DialogActions>
                </Paper>
            </Dialog>
        </div>
    )
}

export default ModalIndicateurs;