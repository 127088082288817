import axios from "axios";
import * as All from '../config';

const BASE_URL = All.CONTROLLER_URL;
const API_URL = All.API_URL; // Here api is not a reference to api-platform but to the token secured path. These are actually symfony paths.

function findFilteredCategorie() {
    return axios
        .get(`${API_URL}filtercategorie`)
        .then(response => response.data)
        .catch(error => {
            console.log("api err", error);
        });
}

function filterTypeIndicateur(param = { selectResponsable: null, selectSousCategorie: null, selectCategorie: null }) {
    let idResponsable = param.selectResponsable !== "_all" ? param.selectResponsable : null;
    let idSousCategorie = param.selectSousCategorie !== "_all" ? param.selectSousCategorie : null;
    let idCategorie = param.selectCategorie !== "_all" ? param.selectCategorie : null;

    return axios
        .get(`${API_URL}filterTypeIndicateur?idResponsable=${idResponsable}&idSousCategorie=${idSousCategorie}&idCategorie=${idCategorie}`)
        .then(response => response.data);
}

function filterTypeObjectif(param = { selectSousCategorie: null, selectCategorie: null }) {
    let idSousCategorie = param.selectSousCategorie !== "_all" ? param.selectSousCategorie : null;
    let idCategorie = param.selectCategorie !== "_all" ? param.selectCategorie : null;

    return axios
        .get(`${API_URL}filterTypeObjectif?&idSousCategorie=${idSousCategorie}&idCategorie=${idCategorie}`)
        .then(response => response.data);
}

export default {
    findFilteredCategorie,
    filterTypeIndicateur,
    filterTypeObjectif
};