import axios from "axios";
import {ASSOC_USER_TYPE_INDICATEUR,USERS_TOGGLE_RIGHT_API} from "../config.js";
import userData from "./UserApi";

// give access to type user creator
const setAccessUserTypeIndic = async (idTypeIndicateur,userName) => {
    const uriTypeIndicateur = "/api/type_indicateurs/" + idTypeIndicateur;
    const uriUser = "/api/users/";
    let idUser;
    let data = {};
    try {
        const allUser = await userData.findAllUsers();
        //boucle sur le nom user  et  avoir l'id
        allUser.forEach((user) => {            
            if(user.email === userName){
                idUser = user.id;
            }
        })
        data = {
            "iduser": uriUser + idUser,
            "idtypeindicateur": uriTypeIndicateur,
            "lecture": true,
            "ecriture": true,
            "suppression": true,
        };
        //create
        axios
            .post(ASSOC_USER_TYPE_INDICATEUR,data)
            .then(response => response.data);

    }catch(err){
        console.log(err);
    }


}
// one by one
const toggleLecture = (rowdata, value) => {
    const uriTypeIndicateur = "/api/type_indicateurs/" + rowdata.idtypeindicateur;
    const uriUser = "/api/users/" + rowdata.iduser
    let data = {};

    if (null !== rowdata.assocUserTypeIndicateursId) {
        data = {
            "id": rowdata.assocUserTypeIndicateursId,
            "iduser": uriUser,
            "idtypeindicateur": uriTypeIndicateur,
            "lecture": value,
        }

        //update 
        return axios
            .put(ASSOC_USER_TYPE_INDICATEUR + "/" + rowdata.assocUserTypeIndicateursId, data)
            .then(response => response.data);

    } else if (null === rowdata.assocUserTypeIndicateursId) {
        data = {
            "iduser": uriUser,
            "idtypeindicateur": uriTypeIndicateur,
            "lecture": value,
        }
        //create
        return axios
            .post(ASSOC_USER_TYPE_INDICATEUR,data)
            .then(response => response.data);
    }
}

const toggleEcriture = (rowdata, value) =>  {
    const uriTypeIndicateur = "/api/type_indicateurs/" + rowdata.idtypeindicateur;
    const uriUser = "/api/users/" + rowdata.iduser
    let data = {};

    if (null !== rowdata.assocUserTypeIndicateursId) {
        data = {
            "id": rowdata.assocUserTypeIndicateursId,
            "iduser": uriUser,
            "idtypeindicateur": uriTypeIndicateur,
            "ecriture": value,
        }
        //update 
        return axios
            .put(ASSOC_USER_TYPE_INDICATEUR + "/" + rowdata.assocUserTypeIndicateursId, data)
            .then(response => response.data);

    } else if (null === rowdata.assocUserTypeIndicateursId) {
        data = {
            "iduser": uriUser,
            "idtypeindicateur": uriTypeIndicateur,
            "ecriture": value,
        }
        //create
        return axios
            .post(ASSOC_USER_TYPE_INDICATEUR,data)
            .then(response => response.data);
    }
}

const toggleSuppression =  (rowdata, value) => {
    const uriTypeIndicateur = "/api/type_indicateurs/" + rowdata.idtypeindicateur;
    const uriUser = "/api/users/" + rowdata.iduser
    let data = {};

    if (null !== rowdata.assocUserTypeIndicateursId) {
        data = {
            "id": rowdata.assocUserTypeIndicateursId,
            "iduser": uriUser,
            "idtypeindicateur": uriTypeIndicateur,
            "suppression": value,
        }
        //update 
        return axios
            .put(ASSOC_USER_TYPE_INDICATEUR + "/" + rowdata.assocUserTypeIndicateursId, data)
            .then(response => response.data);

    } else if (null === rowdata.assocUserTypeIndicateursId) {
        data = {
            "iduser": uriUser,
            "idtypeindicateur": uriTypeIndicateur,
            "suppression": value,
        }
        //create
        return axios
            .post(ASSOC_USER_TYPE_INDICATEUR,data)
            .then(response => response.data);
    }
}

function toggleRight(entries,value,right){
    let data = [];        
    entries.forEach(element => {
        data.push({
            assocUserTypeIndicateursId:element.assocUserTypeIndicateursId,
            iduser:element.iduser,
            idtypeindicateur:element.idtypeindicateur,
            value:value,
            property : right
        })
    });

    return axios
    .post(USERS_TOGGLE_RIGHT_API,data)
    .then(response => response.data);
}
// for all users
function toggleLectureAll(entries,value) {    
    entries.forEach(element => {
        toggleLecture(element,value);
    })
    return
}

function toggleEcritureAll(entries,param) {
    entries.forEach(element => {
        toggleEcriture(element,param);
    })
    return
}

function toggleSuppressionAll(entries,param) {
    entries.forEach(element => {
        toggleSuppression(element,param);
    })
    return
}

export default {
    setAccessUserTypeIndic,
    toggleLecture,
    toggleEcriture,
    toggleSuppression,
    toggleLectureAll,
    toggleEcritureAll,
    toggleSuppressionAll,
    toggleRight
}