import React, { useContext, useState, useEffect, useRef } from 'react';

import { RiLogoutBoxRLine } from "react-icons/ri";

import {
  CssBaseline,
  Toolbar,
  AppBar,
  Typography,
  Tabs,
  Tab,
  Link,
  Button,
  Grid,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import jwtDecode from "jwt-decode";
import Swal from 'sweetalert2';

import AuthAPI from "../../services/AuthAPI";
import AuthContext from "../../contexts/AuthContext"

//styling
import { ThemeContext } from '../../contexts/ThemeContext';

import * as All from '../../config';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '112px'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: props => props.color.menuNavbarBackgroundColor,
  },
  title: {
    flexGrow: 1,
  },
  containerLogo: {
    marginTop: 10,
    height: '50px',
  },
  demo2: {
    backgroundColor: props => props.color.menuNavbarBackgroundColor,
  },
  logo: {
    width: '100%',
    height: "100%",
    margin: 0,
    padding: 0,
  },
}))


const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);


const Navbar = ({ history }) => {
  const theme = useContext(ThemeContext);
  const classes = useStyles(theme);

  const [value, setValue] = useState(0);
  const [token, setToken] = useState();
  const [roleSuperAdmin, setRoleSuperAdmin] = useState(false);

  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

  const mainLogoRef = useRef(null);
  const sndLogoRef = useRef(null);

  const API_URL = All.API_URL;


  // HOW BAD IS IT TO LEAVE StyledTabs INSIDE THE COMPONENT ?!?
  const StyledTabs = withStyles({
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      color: theme.color.menuNavbarColor,
      '& > span': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: theme.color.menuNavbarColor,
      },
    },
  })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

  const handleLogoError = (ref) => {
    ref.current.style.display = "none";
  };


  const handleLogout = () => {
    AuthAPI.logout();
    setIsAuthenticated(false);
    history.replace('/connexion');
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getDecodedToken = () => {
    const tokenBrut = window.localStorage.getItem("authToken");
    if (tokenBrut) {
      const decodedToken = jwtDecode(tokenBrut);
      setToken(decodedToken);
    }
  }

  const logOutExpiredToken = () => {
    if (AuthAPI.isAuthenticated() === false) {
      Swal.fire({
        icon: 'error',
        title: 'La session a expiré.',
        text: 'Veuillez-vous reconnecter.',
      });
      handleLogout();
    }
  }

  useEffect(() => {
    // Though the DESIGNER role is higher up the hierarchy, here it suffices to store it in roleSuperAdmin
    if (token) {
      token.roles[0] === "ROLE_SUPER_ADMIN" || token.roles[0] === "ROLE_ADMIN" || token.roles[0] === All.DESIGNER ? setRoleSuperAdmin(true) : setRoleSuperAdmin(false);
    }
  }, [token]);

  useEffect(() => {
    getDecodedToken();
  }, []);

  useEffect(() => {
    logOutExpiredToken();
  });


  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <div style={{ display: "flex" }}>
              <a href={theme.image.urlMainLogo} target="_blank" rel="noopener noreferrer">
                <div className={classes.containerLogo} style={{ marginRight: "1em" }}>
                  {
                    theme.isDefault ? (
                      <img className={classes.logo} src={`${theme.image.mainLogo}`} alt="logo" />
                    ) : (
                      <img className={classes.logo} src={`${API_URL}image?image=${theme.image.mainLogo}`} alt="logo" onError={() => handleLogoError(mainLogoRef)} ref={mainLogoRef} />
                    )
                  }
                </div>
              </a>
              {theme.image.secondLogo !== "" && (
                <a href={theme.image.urlSndLogo} target="_blank" rel="noopener noreferrer">
                  <div className={classes.containerLogo}>
                    {
                      theme.isDefault ? (
                        <img className={classes.logo} src={`${theme.image.secondLogo}`} alt="logo" />
                      ) : (
                        <img className={classes.logo} src={`${API_URL}image?image=${theme.image.secondLogo}`} alt="logo" onError={() => handleLogoError(sndLogoRef)} ref={sndLogoRef} />
                      )
                    }
                  </div>
                </a>
              )}
            </div>
            {!isAuthenticated ?
              <>
                <Link to="/connexion" >
                  <Button color="white">Login</Button>
                </Link>
              </>
              :
              <div className={classes.login}>                
                <Tooltip title={ token ? token.username : "déconnexion"}>
                  <IconButton className={classes.login} onClick={handleLogout} color="inherit" aria-label="déconnexion">
                    <RiLogoutBoxRLine style={{ fill: theme.color.menuNavbarColor }} />
                  </IconButton >
                </Tooltip>             
              </div>
            }
          </Grid>
        </Toolbar>
        <div className={classes.demo2}>
          <StyledTabs value={value} onChange={handleChange} aria-label="styled tabs " theme={theme}>
            <StyledTab label="Accueil" href="/#/accueil" style={{ color: theme.color.menuNavbarColor }} />
            {roleSuperAdmin === true ?
              <StyledTab label="Administration" href="/#/administration" style={{ color: theme.color.menuNavbarColor }} />
              :
              null
            }
            <StyledTab label="Mes indicateurs" href="/#/indicateurs" style={{ color: theme.color.menuNavbarColor }} />
            <StyledTab label="Mes objectifs" href="/#/objectifs" style={{ color: theme.color.menuNavbarColor }} />
            <StyledTab label="Tableau de bord" href="/#/tableaudebord" style={{ color: theme.color.menuNavbarColor }} />
          </StyledTabs>        
          <Typography className={classes.padding} />
        </div>
      </AppBar>
    </div>
  )
}

export default Navbar;