import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { ThemeContext } from '../contexts/ThemeContext';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        // marginTop: '30px',
        width: '100vw',
        // height: '80vh',
        textAlign: 'center',
        height: 'calc(100vh - 112px)',
        padding: "10px",
    },
    section: {
        margin: '0 auto',
        paddingTop: 150

    },
    image: {
        height: 300,
        width: 300,
        "&:hover": {
            opacity: 0.75
        }
    },
}));


const TableauDeBord = () => {
    const classes = useStyles();
    const theme = useContext(ThemeContext);

    return (
        <div className={classes.root}>
            <section className={classes.section}>
                <a href={theme.powerBI.url} target="_blank" rel="noopener noreferrer">
                    <img className={classes.image} src={theme.powerBI.image} title="rapport PBI" alt="rapport PBI"  ></img>
                </a>
            </section>
        </div>
    )
}

export default TableauDeBord;