import React, { useState, useEffect, useContext } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';

import jwtDecode from "jwt-decode";
import { SENDEMAIL} from "../config.js";
import axios from "axios";

import UserAPI from '../services/UserApi';

import { BsFillInfoCircleFill } from "react-icons/bs";
import { FcOk } from "react-icons/fc";

//icons
import AddCircleRounded from '@material-ui/icons/AddCircleRounded';
import Edit from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import RemoveIcon from '@material-ui/icons/Remove';
//mui
import { makeStyles } from '@material-ui/core/styles';
import {
    FormControl,
    FormHelperText,
    Select,
    MenuItem,
} from '@material-ui/core';

//error message
import ErrorMessage from '../components/errorMessage'
//password generator
import passwordGenerator from '../services/passwordGenerator'

import { ThemeContext } from '../contexts/ThemeContext.js';


const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '15vh',
        marginLeft: '30px',
        width: 'fit-content',
        //remplace with breakpoint
    },
    table: {
        marginLeft: '30px',
        marginRight: '30px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,

    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    inputForm: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    title: {
        backgroundColor: '#1c60A3',
        color: '#FFF',
        height: "5vh",
        textTransform: "uppercase",
    },
    toolbarDiv: {
        color: props => props.color.labelNavbarBackgroundColor,
    },
    toolbar: {
        backgroundColor: props => props.color.white,
    },
    add: {
        fill: props => props.color.pageHeaderColor,
    },
    edit: {
        fill: "#175Be5",
    },
    delete: {
        fill: "#d50000",
    },
    check: {
        fill: "#2e7d32",
    },
    clear: {
        fill: "#d50000",
    },
    thirdStateCheck: {
        fill: "#d50000",
    },
    orange: {
        fill: 'orange',
    },
    green: {
        fill: 'green',
    },
    page: {
        marginLeft: '30px',
        marginRight: '30px',
    },
}));

const Account = () => {
    const theme = useContext(ThemeContext);
    const classes = useStyles(theme);

    const [users, setUsers] = useState([]);

    const [refreshTab, setRefreshTab] = useState(false);

    //isLoading
    const [chargement, setChargement] = useState({
        isLoading: false
    });

    const [state, setState] = useState({
        columns: [
            {
                title: 'Email', field: 'email',

                headerStyle: {
                    textTransform: "uppercase",
                    textAlign: 'left',
                },
            },
            {
                title: 'Rôle', field: 'roles[0]',
                headerStyle: {
                    textTransform: "uppercase",
                    textAlign: 'left',
                },
                render: rowData => {
                    switch (rowData.roles[0]) {
                        case 'ROLE_USER':
                            return 'UTILISATEUR';                           
                        case 'ROLE_SUPER_USER':
                            return 'SUPER UTILISATEUR';                            
                        case 'ROLE_ADMIN':
                            return 'ADMINISTRATEUR';                            
                        case 'ROLE_SUPER_ADMIN':
                            return 'SUPER ADMINISTRATEUR';                            
                        case 'ROLE_DESIGNER':
                            return 'DESIGNER';
                        default:
                            return 'Non défini';
                            
                    }
                },
                editComponent: (props) => (
                    <>
                        <FormControl required >
                            <Select
                                labelId="select-roleUtilisateur-required-label"
                                id="select-roleUtilisateur-required"
                                value={props.value ? props.value : ""}
                                defaultValue={props.value}
                                autoWidth
                                onChange={(e) => {
                                    props.onChange(e.target.value);
                                }}
                            >
                                <MenuItem value="ROLE_USER" name="ROLE_USER">UTILISATEUR</MenuItem>
                                <MenuItem value="ROLE_SUPER_USER" name="ROLE_SUPER_USER">SUPER UTILISATEUR</MenuItem>
                                {role === "ROLE_SUPER_ADMIN" || role === 'ROLE_DESIGNER' ? <MenuItem value="ROLE_ADMIN" name="ROLE_ADMIN">ADMINISTRATEUR</MenuItem> : null}
                                {role === "ROLE_SUPER_ADMIN" || role === 'ROLE_DESIGNER' ? <MenuItem value="ROLE_SUPER_ADMIN" name="ROLE_SUPER_ADMIN">SUPER ADMINISTRATEUR</MenuItem> : null}
                                {role === 'ROLE_DESIGNER' ? <MenuItem value='ROLE_DESIGNER' name="ROLE_DESIGNER">DESIGNER</MenuItem> : null}
                            </Select>
                            <FormHelperText>Requis</FormHelperText>
                        </FormControl>
                    </>
                )
            },
            {
                title: 'Indicateur(s) attribué(s)', field: 'assocUserTypeIndicateurs.idtypeindicateur.libelle', editable: 'never',
                headerStyle: {
                    textTransform: "uppercase",
                    textAlign: 'left',
                },
                render: rowData => {
                    const data = rowData ? rowData.assocUserTypeIndicateurs : "";
                    let count = 0;
                    if (data) {
                        data.forEach((obj) => {
                            if (obj.lecture === true) {
                                count++
                            }
                        })
                    }
                    return (
                        <div>{count}</div>
                    )
                },
            },
            {
                title: 'Statut de validation', field: 'resetToken', editable: 'never',
                headerStyle: {
                    textAlign: 'center',
                    textTransform: "uppercase",
                },
                cellStyle: {
                    textAlign: 'center'
                },
                render: rowData => {
                    let val;

                    if (rowData === undefined) {
                        val = <BsFillInfoCircleFill size={20} className={classes.orange} />
                    } else {
                        if (rowData.resetToken) {
                            val = <BsFillInfoCircleFill size={20} className={classes.orange} />
                        } else {
                            val = <FcOk size={20} className={classes.green} />
                        }
                    }
                    return val;
                }
            },
        ],
    });
    let role = "";
    useEffect(() => {
        let filtreredUsers = [];

        const fetchUsers = async () => {
            try {
                //animation
                setChargement({
                    isLoading: true
                });
                const tokenBrut = window.localStorage.getItem("authToken");
                if (tokenBrut) {
                    const decodedToken = jwtDecode(tokenBrut);

                    if (decodedToken) {
                        role = decodedToken.roles[0];
                    }
                }
                const responseUsers = await UserAPI.findAllUsers();
                if (role === "ROLE_SUPER_ADMIN" || role === 'ROLE_DESIGNER') {
                    setUsers(responseUsers)
                } else {
                    responseUsers.forEach(user => {
                        if (user.roles[0] === 'ROLE_USER') {
                            filtreredUsers.push(user);
                        }
                        if (user.roles[0] === 'ROLE_SUPER_USER') {
                            filtreredUsers.push(user);
                        }
                    });
                    setUsers(filtreredUsers);
                }
                setChargement({
                    isLoading: false
                });
            } catch (error) {
                console.log(error);
            }
        }

        fetchUsers();
    }, [refreshTab]);

    const handleRowClick = (event, rowData) => {
        event.preventDefault();
    }

    const addNewRow = async (param) => {
        if (param !== null) {
            try {
                param.password = passwordGenerator.getPassword();
                await UserAPI.postUser(param);
                await axios.post(SENDEMAIL, { email: param.email });
                setRefreshTab(!refreshTab);
            } catch (error) {
                console.log(error);
                ErrorMessage.ErrorMessage();

            }
        }
    }
    const [currentUser, setCurrentUser] = useState(''); 
    useEffect(() => {
        const tokenBrut = window.localStorage.getItem("authToken");
        if (tokenBrut) {
            const decodedToken = jwtDecode(tokenBrut);

            if (decodedToken) {
                setCurrentUser(decodedToken.username);                
            }
        }
    },[])
    const updateRow = async (param) => {
        let tabOfUriTypeIndicateur = [];
        let tab = param.assocUserTypeIndicateurs;

        tab.forEach((item) => {
            tabOfUriTypeIndicateur.push("/api/assoc_user_type_indicateurs/" + item.id);
        })

        param.assocUserTypeIndicateurs = tabOfUriTypeIndicateur;

        try {
            await UserAPI.changeOneUser(param.id, param);
            setRefreshTab(!refreshTab);
        } catch (error) {
            console.log(error);
        }
    }

    const deleteRow = async (param) => {
        try {
            await UserAPI.deleteUserFromController(param);
            setRefreshTab(!refreshTab);
        } catch (error) {
            console.log(error)
        }
    }

    const canDeleteUser = (rowData) => {
        
        if(rowData.email === currentUser){
            return !false;
        }        
        return !true
    }
    return (
        <>
            <div className={classes.page}>
                <div>
                    <p>Afin de pouvoir ajouter ou modifier les indicateurs associés aux utilisateurs, vous devez vous rendre sur l'onglet "Gestion des droits"</p>
                </div>
                <MaterialTable
                    title={"Création des utilisateurs".toUpperCase()}
                    columns={state.columns}
                    isLoading={chargement.isLoading}
                    data={users}
                    onRowClick={(event, rowData) => handleRowClick(event, rowData)}
                    components={{
                        Toolbar: props => (
                            <div className={classes.toolbarDiv}>
                                <MTableToolbar {...props} className={classes.toolbar} />
                            </div>
                        ),
                    }}
                    options={{
                        rowStyle: {
                            backgroundColor: theme.color.white,
                        },
                        headerStyle: {
                            backgroundColor: theme.color.labelNavbarBackgroundColor,
                            color: theme.color.labelNavbarColor
                        },
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 30],
                        tableLayout: "fixed", //columns dimensions
                        //actionsColumnIndex: -1, //action column o right side
                        addRowPosition: 'first' //add row on top
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: "{from} à {to} sur {count}",
                            labelRowsSelect: "résultats par page",
                            labelRowsPerPage: "résultats par page",
                            firstAriaLabel: "Première page",
                            firstTooltip: "Première page",
                            previousAriaLabel: "Page précédente",
                            previousTooltip: "Page précédente",
                            nextAriaLabel: "Page suivante",
                            nextTooltip: "Page suivante",
                            lastAriaLabel: "Dernière page",
                            lastTooltip: "Dernière page"
                        },
                        toolbar: {
                            addRemoveColumns: "Ajouter ou supprimer des colonnes",
                            nRowsSelected: "{0} ligne(s) sélectionnée(s)",
                            showColumnsTitle: "Afficher les colonnes",
                            showColumnsAriaLabel: "Afficher les colonnes",
                            searchTooltip: "Recherche",
                            searchPlaceholder: "Recherche"
                        },
                        header: {
                            actions: ''
                        },
                        body: {
                            emptyDataSourceMessage:
                                "Désolé, aucun enregistrement correspondant n'a été trouvé",
                            addTooltip: "Ajouter une ligne",
                            deleteTooltip: "Supprimer l'utilisateur",
                            editTooltip: "Modifier l'utilisateur",
                            filterRow: {
                                filterTooltip: "Filtrer"
                            },
                            editRow: {
                                deleteText: "Êtes-vous sûr de vouloir supprimer cet élément ? Vous allez perdre TOUTES les données associées",
                                cancelTooltip: "Annuler",
                                saveTooltip: "Sauvegarder"
                            }
                        },
                    }}
                    style={{
                        color: theme.color.dataColor,
                    }}
                    icons={{
                        Add: props => <AddCircleRounded {...props} className={classes.add} />,
                        Edit: props => <Edit  {...props} className={classes.edit} />,
                        Delete: props => <DeleteForeverIcon   {...props} className={classes.delete} />,
                        Check: props => <Check   {...props} className={classes.check} />,
                        Clear: props => <Clear    {...props} className={classes.clear} />,
                        ThirdStateCheck: props => <RemoveIcon  {...props} className={classes.thirdStateCheck} />,
                    }}
                    editable={{
                        isDeleteHidden: rowData => canDeleteUser(rowData), // an user can't delete himself
                        onRowAdd: (newData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    //console.log(newData);
                                    addNewRow(newData);
                                    resolve();
                                }, 600);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    const dataUpdate = [...users]; // data déja présente dans le tableau avant édition
                                    const index = oldData.tableData.id;
                                    dataUpdate[index] = newData; // remplacement des nouvelles données 
                                    updateRow(newData);
                                    resolve();
                                }, 600);
                            }),
                        onRowDelete: (oldData) =>
                            new Promise((resolve) => {
                                setTimeout(() => {
                                    const dataDelete = [...users];
                                    const index = oldData.tableData.id;
                                    dataDelete.splice(index, 1);
                                    deleteRow(oldData.id);
                                    resolve();
                                }, 600);
                            }),
                    }}
                />
            </div>
        </>
    );
}

export default Account;