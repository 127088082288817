import React, { useContext, useState, useRef, useEffect } from 'react';
import Swal from 'sweetalert2';

import { makeStyles } from '@material-ui/core/styles';
import {
    Dialog,
    Grid,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Slide,
    List,
    ListItem,
    ListItemText,
    Divider,
    Button,
    TextField,
    darken,
    ListItemSecondaryAction,
} from '@material-ui/core';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';
import NoteAddIcon from '@material-ui/icons/NoteAdd';

import { ThemeContext } from '../../contexts/ThemeContext';

import PdfViewer from './pdfViewer';

import attachPdfApi from '../../services/AttachPdfApi';

import * as All from '../../config';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        backgroundColor: props => props.color.menuNavbarBackgroundColor,
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        color: props => props.color.menuNavbarColor,
    },
    closeIcon: {
        fill: props => props.color.menuNavbarColor,
    },
    gridHeight: {
        marginTop: '4px',
        height: 'calc(100vh - 72px)',
    },
    pdfContainer: {
        margin: "auto",
        width: "100%",
        height: "100%",
    },
    formGrid: {
        margin: "3em 1em 2em",
    },
    showFormBtn: {
        borderColor: props => props.color.connectionButtonBackgroundColor,
        color: props => props.color.connectionButtonBackgroundColor,
        "&:hover": {
            backgroundColor: props => props.color.connectionButtonBackgroundColor,
            color: props => props.color.connectionButtonColor,
        }
    },
    leftMenu: {
        height: 'calc(100vh - 72px)',
        overflowY: "scroll",
    },
    paddingList: {
        paddingLeft: "1em",
    },
    listHeader: {
        color: props => props.color.labelNavbarBackgroundColor,
    },
    formHeader: {
        marginBottom: "1em",
    },
    textareaLabel: {
        marginTop: "1em",

    },
    form: {
        display: "none",
        padding: "1em 1em 0",
        border: "1px solid",
        borderColor: props => props.color.connectionButtonBackgroundColor,
        borderRadius: "8px",
    },
    flexy: {
        // display: "flex",
        // alignItems: "center",
    },
    uploadBtn: {
        backgroundColor: props => props.color.labelNavbarBackgroundColor,
        color: props => props.color.labelNavbarColor,
        marginRight: "1em",
        "&:hover": {
            backgroundColor: props => darken(props.color.labelNavbarBackgroundColor, 0.1),
        },
    },
    scaleUp: {
        marginRight: "1em",
        fill: props => props.color.labelNavbarColor,
    },
    wrongDoc: {
        color: theme.palette.error.dark,
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: props => props.color.connectionButtonBackgroundColor,
        color: props => props.color.connectionButtonColor,
        "&:hover": {
            backgroundColor: props => props.color.connectionButtonBackgroundColorHover
        }
    },
    deleteIcon: {
        color: '#c62828',
    },
    downloadIcon: {
        color: '#1b5e20',
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ModalPdf = (props) => {
    const BASE_URL = All.CONTROLLER_URL + 'api/';

    const initialDocumentState = {
        name: "",
        description: "",
    };

    const theme = useContext(ThemeContext);
    const classes = useStyles(theme);

    const [pdfUrl, setPdfUrl] = useState();
    const [pdfs, setPdfs] = useState();
    const [subPdfs, setSubPdfs] = useState();
    const [wrongDoc, setWrongDoc] = useState("");
    const [document, setDocument] = useState("");
    const [documentData, setDocumentData] = useState(initialDocumentState);

    const formRef = useRef(null);
    const documentRef = useRef(null);
    const addDocRef = useRef(null);

    useEffect(() => {
        if (props.source === "INDICATEUR") {
            props.rowData && setPdfs(props.rowData.attachedPdfs);
        }
        if (props.source === "TYPE_INDICATEUR") {
            if (props.rowData) {
                setPdfs(props.rowData.attachedPdfs);
                setSubPdfs(props.rowData.idIndicateur.attachedPdfs);
            }
        }
    }, [props.rowData,props.source]);

    const handleFileChange = () => {
        const currentFiles = documentRef.current.files[0];

        if (currentFiles.type === "application/pdf") {
            setDocument(currentFiles);
            setDocumentData({ ...documentData, name: currentFiles.name });
        } else {
            setWrongDoc("Ce formulaire n'accepte que les fichiers de type PDF");
        }
    };

    const handleChange = ({ currentTarget }) => {
        const { value, name } = currentTarget;

        setDocumentData({ ...documentData, [name]: value });
    };

    const handleShowForm = () => {
        formRef.current.style.display = "block";
        addDocRef.current.style.display = "none";
    };

    const handleClose = () => {
        props.onClose(false);
        // setPdfs(null);
        setPdfUrl(null);
        setDocument(null);
        setDocumentData(initialDocumentState);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        try {
            let idTypeIndicateur = null;
            let idIndicateur = null;

            if (props.source === "TYPE_INDICATEUR") idTypeIndicateur = props.rowData.id; // Main table
            else if (props.source === "INDICATEUR") {
                idTypeIndicateur = props.rowData.typeIndicateurId; // Main table
                idIndicateur = props.rowData.id; // Subtable
            }

            const formData = {};
            if (idTypeIndicateur !== null) formData.idTypeIndicateur = `/api/type_indicateurs/${idTypeIndicateur}`;
            if (idIndicateur !== null) formData.idIndicateur = `/api/indicateurs/${idIndicateur}`;
            formData.name = documentData.name;
            formData.description = documentData.description;

            if( ! document ) {
                setWrongDoc('Veuillez selectionner un fichier pdf')
                return
            }

            // FIRST post the pdf file THEN post the values to be saved in data base
            attachPdfApi
                .postPdfFile(document)
                .then(response => {
                    formData.uniqueName = response;

                    (async function () {
                        await attachPdfApi.postPdfInfo(formData);
                        props.refreshParent();
                        handleClose();
                    })();
                })
                .catch((error) => {
                    console.log("attachPdfApi.postPdfFile error", error);
                });

        } catch (error) {
            console.log(error)
        }
    };


    const handleDelete = async(id,uniqueName) => {
        Swal.fire({
            title: 'Etes-vous sûr de vouloir supprimer ce fichier ?',
            text: "Cette action est irréversible",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Annuler'
          }).then(async(result) => {
            if (result.isConfirmed) {
                await attachPdfApi.deletePdf(id,uniqueName);
                props.refreshParent();
                props.hideSecondTable();
                handleClose();  
              Swal.fire(
                'Supprimé !',
                'Le fichier a bien été supprimé.',
                'success'
              );

            }
          })
    }
    return (
        <div>
            <Dialog fullScreen open={props.open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            Visionneuse PDF
                        </Typography>
                        <IconButton onClick={handleClose} aria-label="close">
                            <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Grid container>
                    <Grid item xs={4} className={classes.leftMenu}>
                        <Grid container>
                            {/* button to add a new document (strictly PDF) */}
                            <Grid item xs={12} className={classes.formGrid}>
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    ref={addDocRef}
                                    onClick={handleShowForm}
                                    className={classes.showFormBtn}
                                >
                                    Ajouter un nouveau document
                                </Button>
                                <form onSubmit={handleSubmit} ref={formRef} className={classes.form}>
                                    <Grid container>
                                        <Typography
                                            component="h4"
                                            variant="h6"
                                            className={classes.formHeader}
                                        >
                                            L'ajout un nouveau document
                                        </Typography>
                                        <Grid item xs={12}>
                                            <div className={classes.flexy}>
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    className={classes.uploadBtn}
                                                >
                                                    <NoteAddIcon
                                                        className={classes.scaleUp}
                                                    />
                                                    Choisir un PDF
                                                    <input
                                                        type="file"
                                                        accept="application/pdf"
                                                        onChange={handleFileChange}
                                                        ref={documentRef}
                                                        hidden
                                                    />
                                                </Button>
                                                {document && (
                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        label="Nom du fichier"
                                                        name="name"
                                                        type="text"
                                                        value={documentData.name}
                                                        onChange={handleChange}
                                                        required
                                                        style={{ width: "100%" }}
                                                    />
                                                )}
                                            </div>
                                            {wrongDoc && <p className={classes.wrongDoc}>{wrongDoc}</p>}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                component="h6"
                                                variant="h6"
                                                className={classes.textareaLabel}
                                            >
                                                La description du document
                                            </Typography>
                                            <textarea
                                                onChange={handleChange}
                                                name="description"
                                                placeholder="description"
                                                rows={6}
                                                style={{ width: "100%", marginTop: "1em" }}
                                                defaultValue={documentData.description}
                                            >
                                            </textarea>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                className={classes.submit}
                                            >
                                                Sauvegarder
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                            <Grid item xs={12}>
                                <List className={classes.paddingList}>
                                    <Typography className={classes.listHeader} component="h3">{props.source === "TYPE_INDICATEUR" ? "Définition d'indicateur" : "Valeur d'indicateur"}</Typography>
                                    {
                                        pdfs && (pdfs.length > 0 ? pdfs.map((e, i) => {
                                            return (
                                                <ListItem key={i} button onClick={() => setPdfUrl(`${BASE_URL}pdf?pdf=${e.uniqueName}`)}>
                                                    <ListItemText primary={e.name} secondary={e.description} />
                                                    <ListItemSecondaryAction>
                                                        <IconButton edge="end" aria-label="download"  title="télécharger le fichier">
                                                            <a href={`${BASE_URL}pdf?pdf=${e.uniqueName}`} target="_blank" rel="noopener noreferrer" >
                                                                <GetAppIcon className={classes.downloadIcon}/>
                                                            </a>
                                                        </IconButton>
                                                        <IconButton edge="end" aria-label="delete"  className={classes.deleteIcon} title="Supprimer" onClick={() =>{handleDelete(e.id,e.uniqueName)}}>
                                                            <DeleteForeverIcon />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            )
                                        }) :
                                            <p>Pas de pièce jointe à ce jour.</p>
                                        )
                                    }
                                    {
                                        props.source === "TYPE_INDICATEUR" && (
                                            <>
                                                <Typography className={classes.listHeader} component="h2">Valeur d'indicateur</Typography>
                                                {
                                                    subPdfs && (subPdfs.length > 0 ? subPdfs.map((e, i) => {
                                                        return (
                                                            <ListItem key={i} button onClick={() => setPdfUrl(`${BASE_URL}pdf?pdf=${e.uniqueName}`)}>
                                                                <ListItemText primary={e.name} secondary={e.description} />
                                                                <ListItemSecondaryAction>
                                                                <IconButton edge="end" aria-label="download" title="télécharger le fichier">
                                                                <a href={`${BASE_URL}pdf?pdf=${e.uniqueName}`} target="_blank" rel="noopener noreferrer">
                                                                    <GetAppIcon className={classes.downloadIcon}/>
                                                                </a>
                                                                </IconButton>                                                                    
                                                                <IconButton edge="end" aria-label="delete" className={classes.deleteIcon}  title="Supprimer" onClick={() =>{handleDelete(e.id,e.uniqueName)}}>
                                                                    <DeleteForeverIcon />
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                            </ListItem>
                                                        )
                                                    }) :
                                                        <p>Pas de pièce jointe à ce jour.</p>
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                    <Divider />
                                </List>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={8} className={classes.gridHeight}>
                        <div className={classes.pdfContainer}>
                            {pdfUrl && <PdfViewer pdfUrl={pdfUrl} />}
                        </div>
                    </Grid>
                </Grid>
            </Dialog>
        </div>
    );
};

export default ModalPdf;