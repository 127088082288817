var generator = require('generate-password');
 
var password = generator.generate({
    length: 10,
    numbers: true
});
 


function getPassword() {    
    return password
}


export default {
    getPassword
};