
import React from 'react';

import { Worker, Viewer } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';


function pdfViewer({ pdfUrl }) {
    const zoomPluginInstance = zoomPlugin();
    const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;


    return (
        /*
         It's very important to note that the worker version and the `pdfjs` package (mentioned in the Install pdfjs library section of Getting started) have to be the same.
         */
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            <div
                className="rpv-core__viewer"
                style={{
                    border: '1px solid rgba(0, 0, 0, 0.3)',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <div
                    style={{
                        alignItems: 'center',
                        backgroundColor: '#eeeeee',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '4px',
                    }}
                >
                    <div style={{ padding: '0px 2px' }}>
                        <ZoomOutButton />
                    </div>
                    <div style={{ padding: '0px 2px' }}>
                        <ZoomPopover />
                    </div>
                    <div style={{ padding: '0px 2px' }}>
                        <ZoomInButton />
                    </div>
                </div>
                <div
                    style={{
                        flex: 1,
                        overflow: 'hidden',
                    }}
                >
                    <Viewer fileUrl={pdfUrl} plugins={[zoomPluginInstance]} />
                </div>
            </div>
        </Worker>
    );
}

export default pdfViewer;
