import Swal from 'sweetalert2';



function ErrorMessage(){
    const message = Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Une erreur est survenue',
    });

    return message;
}




export default {
    ErrorMessage
}